import React, {Suspense, lazy, useEffect} from "react";
import {Redirect, Switch, useHistory} from "react-router-dom";
import { useSelector } from "react-redux";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {DashboardPage} from "./pages/DashboardPage";
import About from "./pages/About";
import UserProfile from "./pages/UserProfile";

import AppContext from "./AppContext";
import ModulesMap from "./ModulesMap";
import { withModal } from "framework/withModal";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );

function moduleRoutes(user) {
    const routes = [];

    if(user) {
        if(user.roles && user.roles.length > 0) {
            // console.log(user.roles);
            // Dynamic Routing
            if(!user.roles[0].modules || user.roles[0].modules.length === 0)
                return;

            const modules = JSON.parse(user.roles[0].modules);

            for (const i in modules) {
                const module = ModulesMap[modules[i].name];
                if(module && module.Info) { // && module.navMenuItem.show
                    // console.log(module, modules[i].name, module.Info.path)
                    if(module.route)
                        routes.push(module.route);

                    if(modules[i].subMenus) {
                        for (const j in modules[i].subMenus) {
                            const subModule = ModulesMap[modules[i].subMenus[j].name];

                            if(subModule && subModule.route !== null)
                                routes.push(subModule.route);
                        }
                    }
                }
            }
        }
    }
    
    return routes;
}

const BasePage = (props) => {
    const {user} = useSelector(state => state.auth);
    
    AppContext.history = useHistory();

    const routes = moduleRoutes(user);

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <ContentRoute path="/dashboard" component={DashboardPage}/>
                {/* <ContentRoute path="/builder" component={BuilderPage}/> */}
                <ContentRoute path="/about" component={About}/>

                <ContentRoute path="/user/profile" component={UserProfile}/>
                
                {routes}

                {/* <Route path="/google-material" component={GoogleMaterialPage}/>
                <Route path="/react-bootstrap" component={ReactBootstrapPage}/>
                <Route path="/e-commerce" component={ECommercePage}/> */}
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}

export default withModal(BasePage);