import React from 'react';
import { Col, Form } from 'react-bootstrap';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";

let yup = require('yup');

export default class OrderStatus extends Module {
    info = OrderStatus.Info;
    
    enableDelete = false;
    
    static Info = {
        nameResource: "order-statuses",
        icon: "fas fa-asterisk",
        path: "/orderstatuses",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <OrderStatus match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["order-status"];
    }

    get apiPath() {
      return '/api/v1/orders/statuses';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none d-sm-table-cell",
            },
            {
                "Title": AppContext.r["title"],
                "Field": "title"
            },
            {
                "Title": AppContext.r["description"],
                "Field": "description"
            }
        ];
    }

    get initialValues() {
        return {
            title: "",
            descriptions: "",
        };
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Form.Group as={Col} xs="10">
                        <Form.Label>{AppContext.r["title"]}*</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                        value={values.title} onChange={handleChange}
                                        isValid={touched.title && !errors.title} isInvalid={!!errors.title} />
                    </Form.Group>
                </Form.Row>        
                
                <Form.Row>
                    <Form.Group as={Col} xs="9">
                        <Form.Label>{AppContext.r["description"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["description"]} name="description"
                                        value={values.description} onChange={handleChange} />
                    </Form.Group>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}