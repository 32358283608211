import React from 'react';
import { Col, Form } from 'react-bootstrap';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import Module from "app/modules/Module";
import RemoteSelect from 'framework/RemoteSelect';
import ToggleButton from 'framework/ToggleButton';

let yup = require('yup');

export default class Category extends Module {
    info = Category.Info;
    
    static Info = {
        nameResource: "categories",
        icon: "fas fa-utensils",
        path: "/categories",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Category match={props.match} location={props.location} history={props.history} />} />;
    }

    constructor(props) {
        super(props);

        this.state.filters = {
            language_id: 2 // Ita
        }
    }

    get title () {
        return AppContext.r["category"];
    }

    get apiPath() {
      return '/api/v1/categories';
    }
    
    get schema () {
        return yup.object({
            category: yup.string().required()
        });
    }

    get tableHead () {
        return [
        {
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none d-sm-table-cell",
        },
        {
            "Title": AppContext.r["category"],
            "Field": "category",
            "Adapter": function(o) {
                const style = {
                    backgroundColor: o.color
                };
                
                // const image = (o.image_legacy || o.image) ? (<i className="far fa-image"></i>) : "";
                return (<div><div className="category-dot" style={style}>&nbsp;</div>{o.category}</div>);
            }
          },{
            "Title": "",
            "Field": "published",
            "ClassName": "item-icons",
            "Adapter": function(o) {
                const icons = [];

                if(o.image_legacy || o.image) icons.push(<i className="far fa-image"></i>);
                icons.push( (o.public === 1) ? <i className="fas fa-toggle-on"></i> : <i className="fas fa-toggle-off"></i>);

                return icons;
            }
          }
        ];
    }

    get initialValues() {
        return {
            category: "",
        };
    }
    
    async beforeComponentDidMount() {
        this.languageOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/languages");
    }

    handleChange = (e) => {
        console.log(this.filters);

        const filters = {...this.filters};

        this.jsonTableRef.current.setState({
            filterObj: filters
        })

        this.setState({
            filters: filters
        })
    }

    onChangeFilters = (values) => {
    }

    // onResetFilters = () => {
    //     this.filters = {
    //         language_id: null,
    //     }
    //     this.handleChange(null);
    // }

    filters = {
        language_id: "2",
    }

    filtersForm() {
        const { language_id } = this.state.filters;

        return (<Form onChange={this.handleChange} className="filters-form">
            <Form.Row>
                <Col xs="6" md="4" lg="3">
                    <RemoteSelect name="categoryId" className="form-control-sm" options={this.languageOptions} nullOption={false} key={language_id} value={language_id}
                            onChange={(e, value) => { if(value === "") value = null; this.filters["language_id"] = value ? parseInt(value, 10) : null; }} />
                </Col>
            </Form.Row>
        </Form>);
    }

    get mask () {
        this.formImageRatio = 1;

        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form className="category-form" onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} xs="10">
                                <Form.Label>{AppContext.r["category"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["category"]} name="category"
                                                value={values.category} onChange={handleChange}
                                                isValid={touched.category && !errors.category} isInvalid={!!errors.category} />
                            </Form.Group>

                            <Form.Group as={Col} xs="2">
                                <Form.Label>{AppContext.r["published"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.public} on={AppContext.r["yes"]} off={AppContext.r["no"]}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...this.state.item}
                                                        item.public = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>
                        </Form.Row>        
                        
                        <Form.Row>
                            <Form.Group as={Col} xs="9">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} xs="3">
                                <Form.Label>{AppContext.r["color"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["color"]} name="color" maxLength="7"
                                                value={values.color} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} md="4">
                                <Form.Label>{AppContext.r["language"]}</Form.Label>
                                <RemoteSelect name="language_id" key={values.language_id} value={values.language_id} onChange={handleChange}
                                              options={this.languageOptions} fieldToMap="name" />
                            </Form.Group>

                            <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["size"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["size"]} name="size"
                                                value={values.size} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}