export default function loadClientResources (resources) {
    resources["product-code"] = "Codice Prodotto";
    resources["package-quantity"] = "Quantità Package";
    resources["package-size"] = "Package";
    resources["packaging"] = "Entità Package";
    resources["placed-at"] = "Piazzato Il";
    resources["requested-date"] = "Data Richiesta";
    resources["note"] = "Note";
    resources["status"] = "Stato";
    resources["order-id-is"] = "ID ordine";
    resources["user"] = "Utente";
    resources["shop-item-enoteca-category"] = "Categoria Enoteca";
    resources["shop-item-enoteca-categories"] = "Categorie Enoteca";
    resources["shop-item-enoteca-brand"] = "Marca Enoteca";
    resources["shop-item-enoteca-brands"] = "Marche Enoteca";
    resources["filter-not-available"] = "Filtra Non Disponibili";
    resources["clear-filter-not-available"] = "Resetta Filtro Non Disponibili";
    resources["warehouse"] = "Magazzino";
    resources["warehouses"] = "Magazzini";
    //resources["order-type"] = [];
    //resources["order-type"][1] = "Comanda";
    resources["internal-order"] = "Ordini Interni";
    resources["internal-orders"] = "Ordine Interno";
    resources["placed-by"] = "Creato Da";
    resources["percent-volume"] = "% Alcol";
}