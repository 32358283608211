/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, {useEffect} from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";

import AppContext from './AppContext';
import ProjectIdsEnum from './settings/ProjectIdsEnum';

export default function App({ store, persistor, basename }) {
    const scriptSources = [
        "/scripts/jquery-3.4.1.min.js",
        "/scripts/jquery.signalR.min.js",
        "/scripts/hub.js",
        "/scripts/SquizzerHub.js",
    ]

    const scripts = [];

    useEffect(() => {
        if(AppContext.s["project-id"] == ProjectIdsEnum.UderlyPro)
            for(let src of scriptSources) {
                const script = document.createElement('script');
                script.src = src;
                script.async = false;
                document.body.appendChild(script);

                scripts.push(script);
            }

        return () => {
            for(let script of scripts) {
                document.body.removeChild(script);
            }
        }
    }, []);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
