import React from 'react'
import { Row, Col, Button, Form, Modal } from 'react-bootstrap'
import axios from 'axios';
import { Formik, Field, ErrorMessage, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import JsonToTable from 'framework/JsonToTable';

let yup = require('yup');

export default class ShopItemCategory extends Module {
    enableInsert = true;
    enableDelete = true;
    
    info = ShopItemCategory.Info;

    static Info = {
        "nameResource": "menu-categories",
        "icon": "fas fa-folder",
        "path": "/shop/categories",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopItemCategory match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["menu-categories"];
    }

    get apiPath() {
        return '/api/v1/shop/categories';
    }
    
    get recordsApiPath() {
        return '/api/v1/shop/null/categories';
    }
    
    get createApiPath() {
        return '/api/v1/shop/null/categories';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required(),
            description: yup.string()
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
            },{
                "Title": AppContext.r["name"],
                "Field": "name"
            },{
                "Title": AppContext.r["description"],
                "ClassName": "d-none d-sm-table-cell",
                "Adapter": function(o) {
                    const maxLength = 60;

                    if(o.description && o.description.length > maxLength)
                        return o.description.substr(0, maxLength) + "...";
                    return o.description;
                }
            },{
                "Title": "",
                "ClassName": "item-icons",
                "Adapter": function(o) {
                    const icons = [];

                    if(o.image) icons.push(<i className="far fa-image"></i>);

                    return icons;
                }
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
            description: "",
        };
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    updateDataAdapter = (values) => {
        delete values["created_at"];
        delete values["updated_at"];
        return values;
    }

    onDeleteExtra = async (extra) => {
        const url = AppContext.s["api-url"] + "/shop/categories/" + this.state.item.id + "/extras/" + extra.id;
        console.log(extra, url);
        const res = await axios.delete(url);
        console.log(res);

        await this.fetchExtras(this.state.item);
    }

    insertExtra = async (fields) => {
        this.setState({
            modalBusy: true
        })

        const url = AppContext.s["api-url"] + "/shop/categories/" + this.state.item.id + "/extras";
        const res = await axios.post(url, 
            { name: fields.name, price: fields.price});
            
        console.log(res);

        await this.fetchExtras(this.state.item);

        this.setState({
            modalBusy: false,
            showModal: false
        })
    }

    onInsertExtra = () => {
        const validationSchema = Yup.object().shape({
            name: Yup.string().required(AppContext.r["required-field"]),
            price: Yup.number().required(AppContext.r["required-field"]),
        });

        const modalView =
            <Modal.Body>
                <Formik
                    initialValues={{
                        name: '',
                        price: 1.0,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={fields => {
                        this.insertExtra(fields)
                    }}
                    render={({ errors, status, touched }) => (
                        <FormikForm>
                            <div className="form-group">
                                <label htmlFor="name">{AppContext.r['name']}*</label>
                                <Field name="name" type="input" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="price">{AppContext.r["price"]}*</label>
                                <Field name="price" type="input" className={'form-control' + (errors.price && touched.price ? ' is-invalid' : '')} />
                                <ErrorMessage name="price" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary mr-2">{AppContext.r["insert-extra"]}</button>
                            </div>
                        </FormikForm>
                    )}
                />
            </Modal.Body>

        this.setModal(AppContext.r["confirm-delete-heading"], modalView);
            
        this.setState({
                showModal: true,
            }
        );
    }

    get mask () {
        const extrasTableHead = [
            {
                "Title": AppContext.r["name"],
                "Field": "name"
            },{
                "Title": AppContext.r["price"],
                "Adapter": (o) => {
                    return o.price.toFixed(2);
                }
            },{
                "Adapter": (o) => {
                    return <Button variant="link" onClick={() => {this.onDeleteExtra(o)}}>{AppContext.r["delete"]}</Button>;
                }
            }
        ]

        const extras =
            <div>
                <h2>{AppContext.r["allowed-extras"]}</h2>

                <div style={{display: !this.state.extras ? "block" : "none"}}>
                    {AppContext.r["preloader"]}
                </div>

                <div className="extra-items" style={{display: this.state.extras && this.state.extras.length > 0 ? "block" : "none"}}>
                    <JsonToTable head={extrasTableHead} body={this.state.extras} />
                </div>

                <p style={{display: this.state.extras && this.state.extras.length === 0 ? "block" : "none"}}>
                    {AppContext.r["no-extras"]}
                </p>

                <Button variant="link" className="insert-extra-button" onClick={this.onInsertExtra}>
                    <i className="fas fa-plus"></i> {AppContext.r['insert-extra']}
                </Button>
            </div>;

        const subCategories = !this.state.item || !this.state.subCategories ? "" :
            <div>
                <h2>Sub-Categories</h2>
                <JsonToTable head={this.tableHead} body={this.state.subCategories} />
            </div>;

        return ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Row>
                            <Form.Group as={Col} md="10">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control as="textarea" rows="5" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange}
                                                isValid={touched.description && !errors.description} isInvalid={!!errors.description} />
                            </Form.Group>
                        </Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>
                
                <div style={this.displayOnEditStyle}>
                    { extras }

                    { subCategories }
                </div>

                {this.formFooter}
            </Form>);
    }

    fetchExtras = async (item) => {
        this.setState({
            extras: null
        });

        const url = AppContext.s["api-url"] + "/shop/categories/" + item.id + "/extras";
        const res = await axios.get(url);
        const data = (res.data.data !== undefined) ? res.data.data : res.data;

        console.log(data);

        this.setState({
            extras: data
        });
    }

    fetchSubCategories = async (item) => {
        this.setState({
            subCategories: []
        });

        const url = AppContext.s["host-url"] + this.recordsApiPath + "?parentId=" + item.id;
        // const url = AppContext.s["api-url"] + "/shop/categories?parentId=" + item.id;
        const res = await axios.get(url);
        const data = (res.data.data !== undefined) ? res.data.data : res.data;

        console.log(url, res);

        this.setState({
            subCategories: data
        });
    }

    async onFetchedItem (item) {
        await this.fetchExtras(item);
        await this.fetchSubCategories(item);
    }
}