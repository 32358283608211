import React from 'react';

export default function loadClientResources (resources) {
    resources["keep-up-to-date"] = "Tieni aggiornato il tuo profilo pubblico";
    resources["upcoming-events"] = "I tuoi eventi in programma";
    resources["no-upcoming-events"] = "Nessun evento in programma";

    resources["events"] = "Eventi";
    resources["event"] = "Evento";
    resources["title"] = "Titolo";
    resources["published"] = "Pubblico";
    resources["youtube-url"] = "Link YouTube";
    resources["facebook-url"] = "Link Facebook";
    resources["instagram-url"] = "Link Instagram";
    resources["twitter-url"] = "Link Twitter";
    resources["event-link"] = "Link Evento";
    
    resources["packages"] = "Album Domande";
    resources["package"] = "Album Domande";
    resources["playlists"] = "Playlist";
    resources["playlist"] = "Playlist";
    resources["add-to"] = "Aggiungi a";
    resources["add-to-new-playlist"] = "Aggiungi a nuova playlist";
    resources["new-playlist"] = "Nuova Playlist";
    resources["add-to-playlist"] = "Aggiungi alla playlist";
    resources["remove-from-playlist"] = "Rimuovi dalla playlist";
    resources["remove-question"] = "Rimuovi Domanda";
    resources["remove-question-confirm"] = "Sei sicuro di voler rimuovere la domanda selezionata dalla playlist corrente?";
    resources["drag-and-drop"] = "Trascina le domande per riordinare la lista";
    resources["quiz"] = "Quiz";
    resources["video-url"] = "URL Video";
    resources["video-support"] = "Supporta YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, and DailyMotion.";
    resources["video-url-disabled-desc"] = <p><small>Il video non può essere mostrato se una immagine è stata specificata.</small><br/><small>Scegli se visualizzare un'immagine o un video.</small></p>;
    resources["fixed-score"] = "Punteggio Fisso";
    resources["fixed-score-default"] = <small>Algoritmo di default</small>;
    resources["fixed-score-desc"] = <small>Scegli se applicare un punteggio fisso o utilizzare l'algoritmo prestabilito per l'assegnazione di un punteggio progressivo.</small>;

    resources["games"] = "Giochi";
    resources["game"] = "Gioco";
    resources["event-id"] = "Id Evento";
    resources["zoom-meeting-id"] = "Zoom Meeting Id";
    resources["zoom-meeting-password"] = "Zoom Meeting Password";
    resources["zoom-meeting-desc"] = "Consente ai giocatori di accedere a un meeting Zoom. Funziona solo se hai una licenza di Zoom a pagamento.";
    resources["started"] = "Iniziato";
    resources["stopped"] = "Fermato";
    resources["duration"] = "Durata";
    resources["max-players-count"] = "Max Num. Giocatori";
    resources["paused"] = "In Pausa";
    resources["status"] = "Stato";

    resources["categories"] = "Categorie";
    resources["category"] = "Categoria";
    resources["color"] = "Colore";

    resources["questions"] = "Domande";
    resources["question"] = "Domanda";
    resources["text"] = "Testo";
    resources["note"] = "Note";
    resources["answers"] = "Risposte";
    resources["answer"] = "Risposta";
    resources["image-duration"] = "Durata Immagine";
    resources["collection-not-editable"] = "Questa collezione non è modificabile.";
    resources["no-events-today"] = <p>Non ci sono eventi per oggi.<br/> Crea un nuovo evento di gioco tramite la scheda <b>eventi</b>.</p>;
    
    // Squizzer Real Time Server Messages
    resources["rts-connecting"] = "Connessione...";
    resources["rts-connected"] = "Connesso. :) Registrazione al server...";
    resources["rts-disconnected"] = "Disconnesso. :( Riconnessione in corso...";
    resources["rts-couldnt-connect"] = "Il gioco non è disponibile al momento per via di un problema di connessione al server. Assicurati di avere una connessione a internet affidabile e prova a ricaricare la pagina tra un po'.";
    resources["rts-subscription-failed"] = "Registrazione al server fallita. Errore: ";
    
    resources["cancel-by"] = "Elimina entro";
    resources["partial-results"] = <span>Classifica<br/>Parziale</span>;
    resources["invite-players"] = <span>Invita<br/>Giocatori</span>;
    resources["cancel-game"] = "Annulla Gioco";
    resources["cancel-game-confirm"] = (
        <div>
            <p>Vuoi annullare questo gioco?</p>
            <p>Tutta le informazioni di questo gioco, come i punteggi dei giocatori, saranno <strong>distrutte</strong> e non saranno ripristinabili.</p>
            <p>La tua connessione con il gioco sarà interrotta e avrai bisogno pertanto di ricaricare le tue pagine di gioco.</p>
        </div>);
    resources["last-answer"] = "Ultima Risposta";
    resources["last-time"] = "Ultimo Tempo";
    resources["last-score"] = "Ultimo Punteggio";
    resources["score"] = "Punteggio";
    resources["delete-player"] = "Elimina Giocatore";
    resources["delete-player-confirm"] = (
        <div>
            <p>Vuoi eliminare questo giocatore?</p>
            <p>Il suo punteggio verrà <strong>resettato</strong> e non sarà ripristinabile.</p>
            <p>Il giocatore verrà rimosso dal gioco e per accedere dovrà re-iscriversi.</p>
        </div>);
    resources["reset-scores"] = "Resetta Punteggi";
    resources["reset-scores-confirm"] = (
        <div>
            <p>Vuoi resettare <b>permanentemente</b> i punteggi di tutti i giocatori?</p>
        </div>);
    resources["wave"] = "Saluta";
    resources["players"] = "Giocatori";
    resources["viewers"] = "Visualizzatori";

    resources["pause-tooltip"] = "Intervallo";
    resources["stop-tooltip"] = "Ferma Gioco";
    resources["play-tooltip"] = "Inizia Gioco";
    resources["forward-tooltip"] = "Avanti";
    resources["skip-tooltip"] = "Salta Avanti";
    resources["show-invite-players-tooltip"] = "Mostra Invita Giocatori";
    resources["hide-invite-players-tooltip"] = "Nascondi Invita Giocatori";
    resources["show-teams-tooltip"] = "Mostra Squadre";
    resources["hide-teams-tooltip"] = "Nascondi Squadre";
    resources["show-leaderboard-tooltip"] = "Mostra Classifica Generale";
    resources["hide-leaderboard-tooltip"] = "Nascondi Classifica Generale";
    resources["show-final-leaderboard-tooltip"] = "Mostra Classifica Finale";
    resources["hide-final-leaderboard-tooltip"] = "Nascondi Classifica Finale";
    resources["enable-partial-leaderboard-tooltip"] = "Abilita Classifica Parziale";
    resources["disable-partial-leaderboard-tooltip"] = "Disabilita Classifica Parziale";
    resources["partial-leaderboard-on-off"] = <span>Classifica<br/>Parz. On/Off</span>;
    resources["play-question-tooltip"] = "Riproduci questa Domanda";
}