import React from 'react';
import { Col, Form } from 'react-bootstrap';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";

let yup = require('yup');

export default class Category extends Module {
    info = Category.Info;
    
    static Info = {
        nameResource: "categories",
        icon: "fas fa-user-md",
        path: "/categories",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Category match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["category"];
    }

    get apiPath() {
      return '/api/v1/categories';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none d-sm-table-cell",
            },
            {
                "Title": AppContext.r["name"],
                "Field": "name",
            },{
                "ClassName": "item-icons",
                "Adapter": (o) => {
                    // const icons = [];
                    // if(o.image) icons.push(<i key="image" className="far fa-image"></i>);
                    // icons.push( (o.highlighted === 1) ? <i key="star" className="fas fa-star"></i> : "");
                    // return icons;
                    return (
                        <div className="row-thumb">
                            {o.image && <img src={AppContext.s["host-url"] + this.apiPath + "/" + o.id + "/thumb"} alt="Thumb" /> }
                        </div>
                    );
                }
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
        };
    }

    get mask () {
        this.formImageRatio = 1;

        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} xs="10">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} autoFocus />
                            </Form.Group>
                        </Form.Row>        
                        
                        <Form.Row>
                            <Form.Group as={Col} xs="9">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}