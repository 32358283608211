import React from "react";
import { Row, Col, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import Axios from 'axios';

import {SubheaderContext} from "../../../_metronic/layout";
import {ContentRoute} from "../../../_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from '../../../api/Core';
import Module, { ModuleViewModeEnum} from "../../modules/Module";
import FormImage from "../../../framework/FormImage";
import RemoteSelect from '../../../framework/RemoteSelect';
import ToggleButton from '../../../framework/ToggleButton';

import 'react-quill/dist/quill.snow.css';

let yup = require('yup');
var path = require('path');

export default class Shop extends Module {
    static contextType = SubheaderContext;

    mode = ModuleViewModeEnum.List;
    enableInsert = true;
    enableDelete = false;

    info = Shop.Info;

    static Info = {
        nameResource: "shops",
        icon: "fas fa-store-alt",
        itemsTitle: AppContext.r["shops"], 
        itemTitle: AppContext.r["shop"], 
        path: "/shops",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Shop match={props.match} location={props.location} history={props.history} />} />;
    }
    
    get apiPath() {
        return '/api/v1/shops';
    }

    state = {
        ...this.state,
        password: "",
        newPassword: "",
        newPassword2: "",
    };
    
    get schema () {
        return yup.object({
            name: yup.string().required(),
            description: yup.string(),
            email: yup.string().email(),
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
            },{
                "Title": AppContext.r["name"],
                "Field": "name"
            },{
                "Title": AppContext.r["subdomain"],
                "Field": "subdomain"
            },{
                "Title": AppContext.r["description"],
                "ClassName": "d-none d-sm-table-cell",
                "Adapter": function(o) {
                    const maxLength = 60;

                    if(o.description && o.description.length > maxLength)
                        return o.description.substr(0, maxLength) + "...";
                    return o.description;
                }
            },{
                "Title": "",
                "ClassName": "item-icons",
                "Adapter": function(o) {
                    const icons = [];

                    if(o.image) icons.push(<i className="far fa-image"></i>);

                    return icons;
                }
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
            description: "",
            address1: "",
            address2: "",
            post_code: "",
            city: "",
            country_id: 102,
            locale_id: 1,
            timezone: 1,
            phone: "",
            facebook_url: "",
            instagram_url: "",
            pinterest_url: "",
            email: "",
        };
    }

    async beforeComponentDidMount() {
        // Fetch timezones
        const res = await Axios.get(AppContext.s["api-url"] + "/timezones");
        if(res && res.data)
            this.timezoneOptions = res.data.map(x => ({ "id": x, "name": ((x > 0) ? "+" : "") + x }))
        // Fetch countries
        this.countryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/countries", "id");
        // Fetch locales
        this.localeOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/locales", "id");

        this.coverFormImageRef = React.createRef();
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    updateDataAdapter = (values) => {
        // console.log(values);
        delete values["created_at"];
        delete values["updated_at"];
        // console.log(values);
        return values;
    }

    handleRichTextChange = (value) => {
        if(this.state.item !== null) {
            this.state.item["description"] = value;
            console.log(this.state.item);
            this.onChange(this.state.item);
        }
    }

    get coverDeliveryApiPath() {
        // http://homestead.test/api/v1/shops/10/cover
        // console.log(path.join(this.apiPath, this.state.item.id.toString(10), "cover"));
      if(this.apiPath !== null && this.state.item !== undefined && this.state.item.id !== undefined)
        return path.join(this.apiPath, this.state.item.id.toString(10), "cover");
      return "";
    }

    get mask () {
        return ({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Row>
                            <Form.Group as={Col} md="8">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                            
                            <Form.Group as={Col} md="4">
                                <Form.Label>{AppContext.r["locale"]}</Form.Label>

                                <RemoteSelect name="locale_id" key={values.locale_id} value={values.locale_id} onChange={handleChange}
                                            options={this.localeOptions} fieldToMap="locale" />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="7">
                                <Form.Label>{AppContext.r["subdomain"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["subdomain"]} name="subdomain" readonly
                                                value={values.subdomain} onChange={handleChange}
                                                isValid={touched.subdomain && !errors.subdomain} isInvalid={!!errors.subdomain} />
                            </Form.Group>
                            
                            <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["timezone"]}</Form.Label>

                                <RemoteSelect name="timezone" key={values.timezone} value={values.timezone} onChange={handleChange}
                                            options={this.timezoneOptions} fieldToMap="name" />
                            </Form.Group>

                            <Form.Group as={Col} md="2">
                                <Form.Label>{AppContext.r["game"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["game"]} name="game_id" readonly
                                                value={values.game_id} onChange={handleChange}
                                                isValid={touched.game_id && !errors.game_id} isInvalid={!!errors.game_id} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>{AppContext.r["address"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["address"]} name="address1"
                                                value={values.address1} onChange={handleChange}
                                                isValid={touched.address1 && !errors.address1} isInvalid={!!errors.address1} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Control type="text" placeholder={AppContext.r["address"]} name="address2"
                                                value={values.address2} onChange={handleChange} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["post-code"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["post-code"]} name="post_code"
                                                value={values.post_code} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="5">
                                <Form.Label>{AppContext.r["city"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["city"]} name="city"
                                                value={values.city} onChange={handleChange}/>
                            </Form.Group>
                            
                            <Form.Group as={Col} md="4">
                                <Form.Label>{AppContext.r["country"]}</Form.Label>

                                <RemoteSelect name="country_id" key={values.country_id} value={values.country_id} onChange={handleChange}
                                            options={this.countryOptions} />
                            </Form.Group>
                        </Row>

                        <Form.Row>
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <ReactQuill placeholder={AppContext.r["description"]} name="description"
                                            value={values.description} onChange={this.handleRichTextChange} />
                            </Form.Group>
                        </Form.Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["email"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["email"]} name="email"
                                            value={values.email} onChange={handleChange}
                                            isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["phone"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["phone"]} name="phone"
                                                value={values.phone} onChange={handleChange}
                                                isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control type="text" placeholder="Facebook" name="facebook_url"
                                                value={values.facebook_url} onChange={handleChange}
                                                isValid={touched.facebook_url && !errors.facebook_url} isInvalid={!!errors.facebook_url} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control type="text" placeholder="Instagram" name="instagram_url"
                                                value={values.instagram_url} onChange={handleChange}
                                                isValid={touched.instagram_url && !errors.instagram_url} isInvalid={!!errors.instagram_url} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Pinterest</Form.Label>
                                <Form.Control type="text" placeholder="Pinterest" name="pinterest_url"
                                                value={values.pinterest_url} onChange={handleChange}
                                                isValid={touched.pinterest_url && !errors.pinterest_url} isInvalid={!!errors.pinterest_url} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Cover</Form.Label>
                                <FormImage ref={this.coverFormImageRef} ratio={3}
                                    disabled={this.state.item === undefined || this.state.item.id === undefined}
                                    disabledMessage={AppContext.r["create-item-first"]}
                                    viewOnly={!this.enableEdit}
                                    imageUrl={AppContext.s["host-url"] + this.coverDeliveryApiPath} 
                                    onDelete={() => {
                                        this.update({ cover: "-1" }, false, true);
                                        this.coverFormImageRef.current.resetImage();
                                    }}
                                    onImageFileChanged={(file) => this.onImageFileChanged(file, 'cover')} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs="4" sm="3">
                                <Form.Label>{AppContext.r["suggest-checkin"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.suggest_checkin} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.suggest_checkin}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.suggest_checkin = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>

                            <Form.Group as={Col} xs="4" sm="3">
                                <Form.Label>{AppContext.r["order-to-table"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.support_order_to_table} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.support_order_to_table}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.support_order_to_table = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>

                            <Form.Group as={Col} sm="3">
                                <Form.Label>{AppContext.r["tables-count"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["tables-count"]} name="tables_count"
                                                value={values.tables_count} onChange={handleChange}
                                                isValid={touched.tables_count && !errors.tables_count} isInvalid={!!errors.tables_count} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs="4" sm="3">
                                <Form.Label>{AppContext.r["takeaway"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.support_takeaway} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.support_takeaway}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        if(value === true) item.vegetarian = true;
                                                        item.support_takeaway = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>

                            <Form.Group as={Col} xs="4" sm="3">
                                <Form.Label>{AppContext.r["delivery"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.support_delivery} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.support_delivery}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.support_delivery = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>

                            <Form.Group as={Col} xs="4" sm="3">
                                <Form.Label>{AppContext.r["big-screen"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.support_big_screen} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.support_big_screen}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.support_big_screen = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>
                        </Row>
                    </Col>
                    
                    <Col md="4">
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Logo</Form.Label>
                                {this.formImage}
                            </Form.Group>
                        </Row>
                    </Col>
                    
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>
                
                {this.formFooter}
            </Form>);
    }
}