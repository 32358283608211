import Axios from 'axios';

import AppContext from 'app/AppContext';
import CoreApi from './Core';
import MenuProApi from './MenuPro';

const qs = require('querystring');

export default class TriviaPro {
    static async Playlists() {
        return await Axios.get(AppContext.s["api-url"] + "/playlists")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async Playlist(id) {
        return await Axios.get(AppContext.s["api-url"] + "/playlists/" + id)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async AddToPlaylist(playlistId, questionId) {
        return await Axios.post(AppContext.s["api-url"] + "/playlists/"+playlistId+"/questions/"+questionId+"/add", { })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async RemoveFromPlaylist(playlistId, questionId) {
        console.log(AppContext.s["api-url"] + "/playlists/"+playlistId+"/questions/"+questionId+"/remove")
        return await Axios.post(AppContext.s["api-url"] + "/playlists/"+playlistId+"/questions/"+questionId+"/remove", { })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async Reorder(playlistId, questionId0, questionId1) {
        const params = {
            questionId1: questionId1
        };

        console.log(AppContext.s["api-url"] + "/playlists/"+playlistId+"/questions/"+questionId0+"/reorder", params);

        return await Axios.post(AppContext.s["api-url"] + "/playlists/"+playlistId+"/questions/"+questionId0+"/reorder", params)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async UsersGraph(shopId) {
        return await Axios.get(AppContext.s["api-url"] + "/shop/" + shopId + "/users/graph")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async PackageQuestions(id, query = "") {
        return await Axios.get(AppContext.s["api-url"] + "/packages/" + id + "/questions" + query)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async Events(daysspan = 0) {
        return await Axios.get(AppContext.s["api-url"] + "/events?daysspan=" + daysspan)
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async StartGame(eventId) {
        const config = { headers: { 'Content-Type': 'application/json' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/start";

        return await Axios.post(url, { }, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async StopGame(eventId) {
        const config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/json' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/stop";

        return await Axios.post(url, { }, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async PauseGame(eventId, paused = true) {
        const config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/x-www-form-urlencoded' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/pause";
        const data = qs.stringify({
            paused: paused
        });

        return await Axios.post(url, data, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async SubmitStatusChange(eventId, gameStatus) {
        const config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/x-www-form-urlencoded' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/submitstatuschange";
        const data = qs.stringify({
            gameStatus: gameStatus
        });

        return await Axios.post(url, data, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async DeleteGame(eventId) {
        const config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/json' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/cancel";

        return await Axios.post(url, { }, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async SetMaxPlayers(eventId, maxPlayers) {
        const config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/x-www-form-urlencoded' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/setmaxplayers";
        const data = qs.stringify({
            maxPlayers: maxPlayers,
        });

        return await Axios.post(url, data, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async DeletePlayer(eventId, playerId, all = false) {
        const config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/x-www-form-urlencoded' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/player/delete";
        const data = qs.stringify({
            playerId: playerId,
            all: all
        });

        console.log(data);

        return await Axios.post(url, data, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async SetPlayerScore(eventId, playerId, score = 0, all = false) {
        const config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/x-www-form-urlencoded' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/player/setscore";
        const data = qs.stringify({
            playerId: playerId,
            score: score,
            all: all
        });

        return await Axios.post(url, data, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async WavePlayer(eventId, playerId, message = null) {
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/player/wave";
        const data = qs.stringify({
            playerId: playerId,
            message: message
        });

        console.log({
            playerId: playerId,
            message: message
        })

        return await Axios.post(url, data, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async Forward(eventId, questionId, playlistId = -1) {
        const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/forward";
        const data = qs.stringify({
            questionId: questionId,
            playlistId: playlistId,
        });

        return await Axios.post(url, data, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async EnablePartialRanking(eventId, enable = true) {
        const config = { headers: { Authorization: AppContext.Auth.bearer, 'Content-Type': 'application/x-www-form-urlencoded' }, crossdomain: true };
        const url = AppContext.s["play-api-url"] + eventId + "/enablepartialranking";
        const data = qs.stringify({
            enable: enable,
        });

        console.log(data);

        return await Axios.post(url, data, config)
            .catch((error) => {
                CoreApi.errorLog(error);
            });
    }

    static async StoreCategories(languageId = 1) {
        let storeId = null;
        const myStores = await MenuProApi.MyShops();

        if(myStores && myStores.data.data && myStores.data.data.length > 0)
            storeId = myStores.data.data[0].id;

        let url = AppContext.s["api-url"] + "/categories?languageId="+languageId;
        if(storeId !== null)
            url += "&storeId=" + storeId;

        return await CoreApi.fetchAsync(url, "id");
    }
}