import React from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { format } from 'date-fns'
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';
import axios from 'axios';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import Module from "app/modules/Module";
import RemoteSelect from 'framework/RemoteSelect';
import ToggleButton from 'framework/ToggleButton';

import 'react-quill/dist/quill.snow.css';
import "react-datepicker/dist/react-datepicker.css";

let yup = require('yup');

export default class Event extends Module {
    info = Event.Info;
    
    static Info = {
        nameResource: "events",
        icon: "fas fa-calendar",
        path: "/events",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Event match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["events"];
    }

    get apiPath() {
      return '/api/v1/events';
    }
    
    get schema () {
        return yup.object({
            title: yup.string().required()
        });
    }

    get tableHead () {
        const t = this;

        return [
            // {
            //     "Title": AppContext.r["id"],
            //     "Field": "id",
            //     "ClassName": "d-none d-sm-table-cell",
            // },
            {
                "Title": AppContext.r["title"],
                "Field": "title"
            },{
                "Title": AppContext.r["date-time"],
                "Field": "date_time"
            },
            // {
            //     "Title": AppContext.r["location"],
            //     "Field": "location_id",
            //     "ClassName": "d-none d-sm-table-cell",
            //     "Adapter": function(o) {
            //         const field = t.locationOptions[o.location_id];
            //         return (field) ? field.name : "";
            // }
            // }
            ,{
                "Title": "",
                "Field": "published",
                "ClassName": "item-icons",
                "Adapter": function(o) {
                    const icons = [];

                    if(o.image_legacy || o.image) icons.push(<i key="image" className="far fa-image"></i>);
                    icons.push( (o.published === 1) ? <i key="published" className="fas fa-toggle-on"></i> : <i key="published" className="fas fa-toggle-off"></i>);

                    return icons;
                }
            }
        ];
    }

    get initialValues() {
        return {
            title: "",
            description: "",
            date_time: format(new Date(), "yyyy-MM-dd 20:30:00"),
            timezone: "",
            youtube_url: "",
            facebook_url: "",
            instagram_url: "",
            twitter_url: "",
            location_id: null,
            published: false,
        };
    }
    
    async beforeComponentDidMount() {
        // Fetch locations
        this.locationOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/locations", "id");
    }

    filtersForm() {
        return "";
    }

    handleRichTextChange = (value) => {
        if(this.state.item !== null) {
            this.state.item["description"] = value;
            this.onChange(this.state.item);
        }
    }

    get mask () {
        this.formImageRatio = 4/3;

        const DatePickerField = ({ name, value, onChange }) => {
            return (
                <DatePicker
                    selected={(value && new Date(value)) || null}
                    onChange={val => {
                        onChange(name, format(val, "yyyy-MM-dd H:mm:ss"));
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption={AppContext.r["time"]}
                    dateFormat="yyyy-MM-d H:mm" />
            );
        };

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} xs="10">
                                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["title"]} name="title"
                                                value={values.title} onChange={handleChange}
                                                isValid={touched.title && !errors.title} isInvalid={!!errors.title} />
                            </Form.Group>

                            <Form.Group as={Col} xs="2">
                                <Form.Label>{AppContext.r["published"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.published} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.published}
                                                onToggle={
                                                    (value) => { 
                                                        // this.state.item["published"] = value;
                                                        // this.onChange(this.state.item);
                                                        var item = {...values}
                                                        item.published = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["date-time"]}</Form.Label>
                                <DatePickerField
                                    name="date_time"
                                    value={values.date_time}
                                    onChange={setFieldValue} />
                            </Form.Group>
{/* 
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["location"]}</Form.Label>

                                <RemoteSelect name="location_id" key={values.location_id} value={values.location_id} onChange={handleChange}
                                              nullOption={true} options={this.locationOptions} />
                            </Form.Group> */}
                        </Form.Row>

                        <Form.Row>
                            <h3>Zoom</h3>
                            <p>{AppContext.r["zoom-meeting-desc"]}</p>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["zoom-meeting-id"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["zoom-meeting-id"]} name="zoom_meeting_id"
                                                value={values.zoom_meeting_id} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["zoom-meeting-password"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["zoom-meeting-password"]} name="zoom_meeting_password"
                                                value={values.zoom_meeting_password} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>
{/* 
                <Form.Row>
                    <Form.Group as={Col} md="12">
                        <Form.Label>{AppContext.r["description"]}</Form.Label>
                        <ReactQuill placeholder={AppContext.r["description"]} name="description"
                                    value={values.description} onChange={this.handleRichTextChange} />
                    </Form.Group>
                </Form.Row> */}
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }

    // render() {
    //     return this.listModeRender(AppContext.r["event"], AppContext.r["events"]);
    // }
}

export class EventWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            events: null
        };
    }

    componentDidMount() {
        const t = this;

        // axios.get(AppContext.s["host-url"] + "/api/v1/eventsbyuserid/" + Auth.user.id)
        axios.get(AppContext.s["host-url"] + "/api/v1/events?upcoming=1")
            .then(response => {
                // console.log(response);
                t.setState({
                    events: response.data.data
                });
            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    get widget() {
        const events = (this.state.events === null) ? AppContext.r["preloader"] : 
            (this.state.events.length === 0) ? (<p className="text-center">{AppContext.r["no-upcoming-events"]}</p>) :
                this.state.events.map((event) => {
                    const imageUrl = AppContext.s["host-url"] + "/api/v1/events/" + event.id + "/image/thumb";

                    return (
                        <div className="event" key={event.id}>
                            <p>{event.title}</p>
                            <Button variant="link" onClick={() => { this.setState({redirectTo: Event.navMenuItem.path + "/" + event.id})}}>{AppContext.r["edit"]}</Button>
                            <img src={imageUrl} alt={event.title} />
                        </div>);
        });

        return (
          <div className="event widget">
              <h1>{AppContext.r["upcoming-events"]}</h1>

              <div className="events">
                {events}
              </div>
          </div>
        );
    }
}
