import React from "react";
import { Row, Col, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import Axios from 'axios';

import {ContentRoute} from "../../../_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from '../../../api/Core';
import QrtServiceApi from '../../../api/QrtService';
import Module from "../../modules/Module";
import RemoteSelect from '../../../framework/RemoteSelect';

import 'react-quill/dist/quill.snow.css';

let yup = require('yup');
var path = require('path');

export default class Store extends Module {
    info = Store.Info;

    static Info = {
        nameResource: "stores",
        icon: "fas fa-store-alt",
        path: "/stores",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Store match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["store"];
    }
    
    get apiPath() {
        return '/api/v1/providers/'+this.provider.id+'/stores';
    }

    get itemApiPath() {
        return '/api/v1/providers/stores';
    }
    
    get updateApiPath() {
        return '/api/v1/providers/stores';
    }
    
    get deleteApiPath() {
        return '/api/v1/providers/stores';
    }

    // get imageDeliveryApiPath() {
    //     return '/api/v1/providers/stores';
    // }

    // get mediaTransferApiPath() {
    //     return '/api/v1/providers/stores/media';
    // }
    
    get schema () {
        return yup.object({
            name: yup.string().required(),
            description: yup.string(),
            email: yup.string().email(),
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
            },{
                "Title": AppContext.r["name"],
                "Field": "name"
            },{
                "Title": AppContext.r["city"],
                "Field": "city"
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
            description: "",
            address1: "",
            address2: "",
            post_code: "",
            city: "",
            country_id: 102,
            locale_id: 1,
            timezone: 1,
            email: "",
            phone: "",
        };
    }

    async beforeComponentDidMount() {
        const response = await QrtServiceApi.MyProviders();
        console.log(response);

        if(response && response.data && response.data.data.length > 0)
            this.provider = response.data.data[0];

        console.log(this.provider);

        // Fetch timezones
        const res = await Axios.get(AppContext.s["api-url"] + "/timezones");
        if(res && res.data)
            this.timezoneOptions = res.data.map(x => ({ "id": x, "name": ((x > 0) ? "+" : "") + x }))
        // Fetch countries
        this.countryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/countries", "id");
        // Fetch locales
        this.localeOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/locales", "id");

        // this.coverFormImageRef = React.createRef();
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    updateDataAdapter = (values) => {
        // console.log(values);
        delete values["created_at"];
        delete values["updated_at"];
        // console.log(values);
        return values;
    }

    handleRichTextChange = (value, setFieldValue) => {
        setFieldValue('description', value);

        if(this.state.item) {
            const item = { ...this.state.item };
            item["description"] = value;

            this.onChange(item);
        }
    }

    get coverDeliveryApiPath() {
        // http://homestead.test/api/v1/shops/10/cover
        // console.log(path.join(this.apiPath, this.state.item.id.toString(10), "cover"));
      if(this.apiPath !== null && this.state.item !== undefined && this.state.item.id !== undefined)
        return path.join(this.apiPath, this.state.item.id.toString(10), "cover");
      return "";
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="12">
                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                            
                            <Form.Group as={Col} md="4" xs="6">
                                <Form.Label>{AppContext.r["locale"]}</Form.Label>

                                <RemoteSelect name="locale_id" key={values.locale_id} value={values.locale_id} onChange={handleChange}
                                            options={this.localeOptions} fieldToMap="locale" />
                            </Form.Group>
 
                            <Form.Group as={Col} md="2" xs="6">
                                <Form.Label>{AppContext.r["timezone"]}</Form.Label>

                                <RemoteSelect name="timezone" key={values.timezone} value={values.timezone} onChange={handleChange}
                                            options={this.timezoneOptions} fieldToMap="name" />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["address"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["address"]} name="address1"
                                                value={values.address1} onChange={handleChange}
                                                isValid={touched.address1 && !errors.address1} isInvalid={!!errors.address1} />
                            </Form.Group>
        
                            <Form.Group as={Col} xs="12">
                                <Form.Control type="text" placeholder={AppContext.r["address"]} name="address2"
                                                value={values.address2} onChange={handleChange} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} md="3">
                                <Form.Label>{AppContext.r["post-code"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["post-code"]} name="post_code"
                                                value={values.post_code} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="5">
                                <Form.Label>{AppContext.r["city"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["city"]} name="city"
                                                value={values.city} onChange={handleChange}/>
                            </Form.Group>
                            
                            <Form.Group as={Col} md="4">
                                <Form.Label>{AppContext.r["country"]}</Form.Label>

                                <RemoteSelect name="country_id" key={values.country_id} value={values.country_id} onChange={handleChange}
                                            options={this.countryOptions} />
                            </Form.Group>
                        </Row>

                        <Form.Row>
                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <ReactQuill placeholder={AppContext.r["description"]} name="description"
                                            value={values.description} 
                                            onChange={e => this.handleRichTextChange(e, setFieldValue)} />
                            </Form.Group>
                        </Form.Row>

                        <Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["email"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["email"]} name="email"
                                            value={values.email} onChange={handleChange}
                                            isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["phone"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["phone"]} name="phone"
                                                value={values.phone} onChange={handleChange}
                                                isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone} />
                            </Form.Group>
                        </Row>
{/* 
                        <Row>
                            <Form.Group as={Col} xs="12">
                                <Form.Label>Facebook</Form.Label>
                                <Form.Control type="text" placeholder="Facebook" name="facebook_url"
                                                value={values.facebook_url} onChange={handleChange}
                                                isValid={touched.facebook_url && !errors.facebook_url} isInvalid={!!errors.facebook_url} />
                            </Form.Group>

                            <Form.Group as={Col} xs="12">
                                <Form.Label>Instagram</Form.Label>
                                <Form.Control type="text" placeholder="Instagram" name="instagram_url"
                                                value={values.instagram_url} onChange={handleChange}
                                                isValid={touched.instagram_url && !errors.instagram_url} isInvalid={!!errors.instagram_url} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Cover</Form.Label>
                                <FormImage ref={this.coverFormImageRef} ratio={3}
                                    disabled={this.state.item === undefined || this.state.item.id === undefined}
                                    disabledMessage={AppContext.r["create-item-first"]}
                                    viewOnly={!this.enableEdit}
                                    imageUrl={AppContext.s["host-url"] + this.coverDeliveryApiPath} 
                                    onDelete={() => {
                                        this.update({ cover: "-1" }, false, true);
                                        this.coverFormImageRef.current.resetImage();
                                    }}
                                    onImageFileChanged={(file) => this.onImageFileChanged(file, 'cover')} />
                            </Form.Group>
                        </Row> */}
                    </Col>
                    
                    {/* <Col md="4">
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Logo</Form.Label>
                                {this.formImage}
                            </Form.Group>
                        </Row>
                    </Col> */}
                    
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>
                
                {this.formFooter}
            </Form>);
    }
}