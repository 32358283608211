import AppContext from './AppContext';
import ProjectIdsEnum from './settings/ProjectIdsEnum';
import Admin from "./pages/Admin";
//import Asset from "./pages/Asset";
//import AssetType from "./pages/AssetType";
import User from "./pages/User";
import Role from "./pages/Role";
import Permission from "./pages/Permission";
import Log from "./pages/Log";

// UderlyPro
import Shop from "./pages/menupro/Shop";
import MenuItem from "./pages/menupro/MenuItem";
import MenuItemCategory from "./pages/menupro/MenuItemCategory";
import MenuItemExtra from "./pages/menupro/MenuItemExtra";
import ShopUser from "./pages/menupro/ShopUser";
import ShopOrder from "./pages/menupro/ShopOrder";

// TriviaPro
// import Location from "./modules/smartgame/Location";
import Event from "./pages/TriviaPro/Event";
import Package from "./pages/TriviaPro/Package";
import Question from "./pages/TriviaPro/Question";
import Category from "./pages/TriviaPro/Category";
import Game from "./pages/TriviaPro/Game";
import Playlist from "./pages/TriviaPro/Playlist";

// QrtService
import QrtTables from "./pages/QrtService/Tables";
// import QrtCategory from "./pages/QrtService/ServiceCategory";
// import QrtRecipientRegistry from "./pages/QrtService/RecipientRegistry";
// import QrtBusinessType from "./pages/QrtService/RegistryType";
// import QrtRegistryType from "./pages/QrtService/BusinessType";
// import QrtAnomaly from "./pages/QrtService/Anomaly";
import QrtCategory from "./pages/QrtService/Category";
import QrtService from "./pages/QrtService/Service";
import QrtProvider from "./pages/QrtService/Provider";
import QrtStore from "./pages/QrtService/Store";
import QrtSocialType from "./pages/QrtService/SocialType";
import QrtOrders from "./pages/QrtService/Order";
import QrtOrderTypes from "./pages/QrtService/OrderType";
import QrtOrderStatuses from "./pages/QrtService/OrderStatus";
import QrtPriceListItem from "./pages/QrtService/PriceListItem";
import QrtProviderStatuses from "./pages/QrtService/ProviderStatus";

const ModulesMap = [];

// Core Modules
ModulesMap["Admin"] = Admin;
// ModulesMap["Assets"] = Asset;
// ModulesMap["AssetTypes"] = AssetType;
ModulesMap["Users"] = User;
ModulesMap["Roles"] = Role;
ModulesMap["Permissions"] = Permission;
ModulesMap["Logs"] = Log;

// Quartarone
if(AppContext.s["project-id"] === ProjectIdsEnum.QrtService) {
    ModulesMap["Tables"] = QrtTables;
    // ModulesMap["ServiceCategories"] = QrtCategory;
    // ModulesMap["Registries"] = QrtRecipientRegistry;
    // ModulesMap["BusinessTypes"] = QrtBusinessType;
    // ModulesMap["RegistryTypes"] = QrtRegistryType;
    // ModulesMap["Anomalies"] = QrtAnomaly;
    
    ModulesMap["Categories"] = QrtCategory;
    ModulesMap["Services"] = QrtService;
    ModulesMap["Providers"] = QrtProvider;
    ModulesMap["Stores"] = QrtStore;
    ModulesMap["SocialTypes"] = QrtSocialType;
    ModulesMap["OrderTypes"] = QrtOrderTypes;
    ModulesMap["OrderStatuses"] = QrtOrderStatuses;
    ModulesMap["Orders"] = QrtOrders;
    ModulesMap["PriceListItems"] = QrtPriceListItem;
    ModulesMap["ProviderStatuses"] = QrtProviderStatuses;
}

if(AppContext.s["project-id"] === ProjectIdsEnum.UderlyPro) {
    // MenuPro
    ModulesMap["Shops"] = Shop;
    ModulesMap["MenuItems"] = MenuItem;
    ModulesMap["ShopCategories"] = MenuItemCategory;
    ModulesMap["Extras"] = MenuItemExtra;
    ModulesMap["ShopOrders"] = ShopOrder;
    ModulesMap["ShopUsers"] = ShopUser;

    // TriviaPro
    ModulesMap["Events"] = Event;
    //ModulesMap["Locations"] = Location;
    ModulesMap["Packages"] = Package;
    ModulesMap["Questions"] = Question;
    ModulesMap["Categories"] = Category;
    ModulesMap["Games"] = Game;
    ModulesMap["Playlists"] = Playlist;
}

export default ModulesMap;