import React, { Component } from 'react';
import { Form, Col, Pagination, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router';
import { Formik } from 'formik';
import Axios from 'axios';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
// import Icon from '@material-ui/core/Icon';
import { GlobalHotKeys } from "react-hotkeys";

// import {SubheaderContext} from "../../_metronic/layout";
import {SubheaderContext} from "../../_metronic/layout/_core/MetronicSubheader";

import AppContext from '../AppContext';
import CoreApi from "../../api/Core";
import FormImage from '../../framework/FormImage';
import JsonToTable from '../../framework/JsonToTable';

var path = require('path');

export class ModuleViewModeEnum {
    static get List() { return 0; }
    static get SingleItem() { return 1; }
    static get Custom() { return 2; }
}

export class ModuleStatusEnum {
    static get rest() { return 0; }
    static get loading() { return 1; }
    static get saving() { return 2; }
    static get overlay() { return 3; }
    static get uploading() { return 4; }
}

const keyMap = {
    NEW_ITEM: "ctrl+shift+n",
    TEST: "n"
};

export default class Module extends Component {
    static contextType = SubheaderContext;

    constructor(props) {
        super(props);
        
        this.state = {
            status: ModuleStatusEnum.rest,
            records: null,
            item: null,
            filters: {},
            currentPage: 1,
            showModal: false,
            modalBusy: false,
            modalMessage: null,
            currentPage: 1,
            lastPage: 0
        };

        this.mode = ModuleViewModeEnum.List;
        this.jsonTableRef = React.createRef();
        this.formImageRef = React.createRef();
    }

    handlers = {
        NEW_ITEM: event => {
            event.preventDefault();
            console.log(event);

            this.onInsert();
        },
        TEST: event => {
            event.preventDefault();
            console.log(event);

            if(!this.state.item)
                this.onInsert();
        }
        
        // this.setState((state) => {
        //     return {
        //         ...state,
        //         preview: !state.preview
        //     }
        // })
    };

    _updateDelayMs = 1000;
    _updateTimeout = null;

    enableView = true;
    enableInsert = true;
    enableEdit = true;
    enableDelete = true;

    _cancelToken = Axios.CancelToken;
    _cancelTokenSource = null;

    _apiPath = null;
    get apiPath() {
        return this._apiPath;
    }
    
    _recordsApiPath = null;
    get recordsApiPath() {
        if(this._recordsApiPath !== null)
            return this._recordsApiPath;

        if(this.apiPath !== null)
            return this.apiPath;

        return null;
    }
    set recordsApiPath(v) {
        this._recordsApiPath = v;
    }
    
    get itemApiPath() {
        if(this.apiPath !== null)
            return this.apiPath;
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get createApiPath() {
        if(this.apiPath !== null)
            return this.apiPath;
        throw new Error(AppContext.r["property-not-implemented"]);
    }
    
    get updateApiPath() {
        if(this.apiPath !== null)
            return this.apiPath;
        throw new Error(AppContext.r["property-not-implemented"]);
    }
    
    get deleteApiPath() {
        if(this.apiPath !== null)
            return this.apiPath;
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get mediaTransferApiPath() {
        if(this.apiPath !== null)
            return path.join(this.apiPath, "media");
        throw new Error(AppContext.r["method-not-implemented"]);
    }

    get initialValues() {
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get schema() {
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get tableHead () {
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get title() {
        return "[ Title ]";
    }

    _info = {}
    get info() {
        return this._info;
    }
    set info(o) {
        this._info = o;
    }

    get mask() {
        throw new Error(AppContext.r["property-not-implemented"]);
    }

    get closeButton() {
        // return (
        //   <Button variant="danger" className="rounded-btn form-close" onClick={this.onClose}
        //     style={this.state.item === null ? {display: "none"} : {display: "block"}}>
        //     <i className="fas fa-times"></i>
        //   </Button>);

        return(this.state.item) ? (
            <Fab size="small" color="danger" aria-label="Add" className="form-close" onClick={this.onClose}>
                <CloseIcon />
            </Fab>) : "";
    }

    get insertButton() {
        // return(this.enableInsert)
        //     ? (
        //     <Button variant="primary" className="rounded-btn form-insert" onClick={this.onInsert}
        //         style={this.state.item !== null ? {display: "none"} : {display: "block"}}>
        //         <i className="fas fa-plus"></i>
        //     </Button>) : "";

        return(this.enableInsert && !this.state.item) ? (
            <Fab size="small" color="primary" aria-label="Add" className="form-insert" onClick={this.onInsert}>
                <AddIcon />
            </Fab>) : "";
    }

    get createButton() {
        return (
            <Button type="submit" variant="contained" color="primary" className="form-create">
                {AppContext.r["create"]} <AddIcon />
            </Button>);

        // return (
        //     <Button type="submit" variant="success" className="form-create">
        //         {AppContext.r["create"]} <i className="fas fa-plus"></i>
        //     </Button>);
    }

    get deleteButton() {
        return (
            <Button variant="contained" className="form-delete danger-button" onClick={this.onDelete}>
                {AppContext.r["delete"]} <DeleteIcon />
            </Button>);

        // return (
        //     <Button variant="danger" className="form-delete" onClick={this.onDelete}>
        //         {AppContext.r["delete"]} <i className="far fa-trash-alt"></i>
        //     </Button>);
    }

    get mediaTransferApiPath() {
      if(this.apiPath !== null)
        return path.join(this.apiPath, "media");
  
      throw new Error(AppContext.r["property-not-implemented"]);
    }
  
    get imageDeliveryApiPath() {
      if(this.apiPath !== null && this.state.item !== undefined && this.state.item.id !== undefined)
        return path.join(this.apiPath, this.state.item.id.toString(10), "image");
      return "";
    }
  
    get imageUrl() {
      return (this.imageDeliveryApiPath !== "") ? AppContext.s["host-url"] + this.imageDeliveryApiPath : "";
    }

    get displayOnEditStyle() {
      return (this.state.item === undefined || this.state.item.id === undefined) ? { display: "none" } : { display: "block"};
    }
  
    get displayOnInsertStyle() {
      return (this.state.item !== undefined && this.state.item.id !== undefined) ? { display: "none" } : { display: "block"};
    }
  
    _formImageRatio = 1;
    get formImageRatio() {
        return this._formImageRatio;
    }
    set formImageRatio(value) {
        this._formImageRatio = value;
    }
  
    get formImage() {
        return (
            <FormImage ref={this.formImageRef} ratio={this.formImageRatio}
                disabled={this.state.item === undefined || this.state.item.id === undefined}
                disabledMessage={AppContext.r["create-item-first"]}
                viewOnly={!this.enableEdit}
                imageUrl={this.imageUrl}
                onDelete={this.onDeleteImage}
                onImageFileChanged={this.onImageFileChanged} />);
    }
  
    get formFooter () {
      return (
        <Form.Row className="form-footer">
            {/* <div style={{display: (this.state.status === ModuleStates.saving) ? "block" : "none"}}>{this.saving}</div> */}
            {this.saving}
            {/* Shown only on editing */}
            <Col style={this.displayOnEditStyle}>
              <div style={this.enableDelete ? {display: "block"} : {display: "none"}}>
                {this.deleteButton}
              </div>
            </Col>
            {/* Shown only on insert */}
            <Col style={this.displayOnInsertStyle}>
                {this.createButton}
            </Col>
        </Form.Row>
      );
    }

    get saving() {
        return (
            <div style={{display: (this.state.status === ModuleStatusEnum.saving) ? "block" : "none"}}>
                <div className="saving-form">
                    {AppContext.r["saving"]} <i className="fas fa-check"></i>
                </div>
            </div>);
    }

    async componentDidMount() {
        if(this.beforeComponentDidMount)
            await this.beforeComponentDidMount();

        if(this.mode === ModuleViewModeEnum.List || this.mode === ModuleViewModeEnum.SingleItem) {
            if (this.props.match) {
                if (this.props.match.url.startsWith(this.info.path + "/insert"))
                    this.resetForm();
                else if(this.props.match.params.id) {
                    this.fetchItem(this.props.match.params.id);
                }
                else if(this.mode === ModuleViewModeEnum.List)
                    this.fetchRecords();
            } else
                this.fetchRecords();
        }

        window.addEventListener("resize", this.onResize);
    }

    resetForm () {
        console.log("Reset Form", this.initialValues)
        
        this.setState({
            item: this.initialValues
        });
    }

    setContextTitle = (item) => {
        console.log(item)
        if(this.context && item) {
            const name = item.name ? item.name : item.title ? item.title : "";
            const displayName = name ? " \"" + name + "\"" : "";

            this.context.setTitle(AppContext.r["edit"] + " " + this.title + displayName);
        }
    }

    fetchItem = (id = null, query = "") => {
        if(!this.itemApiPath)
            return;

        console.log("Fetching item " + AppContext.s["host-url"] + this.itemApiPath + "/" + id);

        if(this.onFetchingItem)
            this.onFetchingItem();

        let url = AppContext.s["host-url"] + this.itemApiPath;
        if(id) url += "/" + id + query;

        this._cancelTokenSource = this._cancelToken.source();

        CoreApi.fetchItem(url, (item) => {
            console.log(item);
            
            if(this.onFetchedItem)
                this.onFetchedItem(item);
                    
            this.setContextTitle(item);

            this.setState({
                item: item
            });
        }, this._cancelTokenSource.token)
    }

    filtersQuery = () => {
        let query = "";

        for (var key in this.filters) {
            const value = this.filters[key];

            if(value) {
                if(query.length > 1)
                    query += "&";
                query += key + "=" + value;
            }
        }

        return query;
    }

    async fetchRecords(page = -1) {
        if(this.onBeforeFetchRecords)
            await this.onBeforeFetchRecords();

        if(!this.recordsApiPath)
            return;

        this.setState({
            records: null
        });

        let query = "?page="+ ((page >= 0) ? page : this.state.currentPage);

        if(this.filters) {
            const filtersQuery = this.filtersQuery();
            
            if(filtersQuery.length > 0)
                query += "&" + filtersQuery;
        }

        console.log("fetchRecords " + AppContext.s["host-url"] + this.recordsApiPath + query);

        Axios.get(AppContext.s["host-url"] + this.recordsApiPath + query)
            .then(response => {
                // If request is good...
                console.log(response);
                this.setState({
                    records: response.data.data ? response.data.data : response.data,
                    page: response.data.current_page,
                    lastPage: response.data.last_page
                });
            })
            .catch((error) => {
                console.log('error ' + error);
            });
    }
        
    create = async (values) => {
        //const values = this.state.item;
        console.log(values);

        this.setState({
            status: ModuleStatusEnum.saving
        });

        var config = { headers: { 'Content-Type': 'application/json' }, crossdomain: true };
        const url = AppContext.s["host-url"] + this.createApiPath;
        
        delete values["created_at"];
        delete values["updated_at"];
        
        if(this.insertDataAdapter)
            values = this.insertDataAdapter(values);

        console.log("CREATE", values);
        
        const response = await Axios.post(url, values, config)
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response);
                        return error.response;
                    }
                });

        this.fetchRecords();

        console.log(response);

        if(response.status === 201 && response.data.data.id) {
            this.setState({
                item: response.data.data
            });
        }

        this.setState({
            status: ModuleStatusEnum.rest
        });

        return response;
    }
    
    async update(values, updateImage = false, updateCover = false) {
        if(values && /*this.state.item !== null &&*/ this.enableEdit) {
            console.log("UPDATE", values);

            this.setState({
                status: ModuleStatusEnum.saving
            });
            
            delete values["created_at"];
            delete values["updated_at"];

            if(!updateImage)
                delete values["image"];
            if(!updateCover)
                delete values["cover"];

            // var config = { headers: { Authorization: Auth.bearer, 'Content-Type': 'application/json' }, crossdomain: true };
            const id = values.id ? values.id : this.state.item.id;
            const url = AppContext.s["host-url"] + this.updateApiPath + "/" +  id;
            
            if(this.updateAdapter && !updateImage && !updateCover)
                values = this.updateAdapter(values);

            const response = await CoreApi.updateItem(url, values);
        
            if(response && response.data && response.status === 202)
                this.setContextTitle(response.data.data);

            this.setState({
                status: ModuleStatusEnum.rest
            });

            console.log("response", response);

            return response.data.data;
        }

        return null;
    }

    delete = async () => {
        if(this.state.item !== null) {
            this.setState({
                status: ModuleStatusEnum.saving
            });

            const url = AppContext.s["host-url"] + this.deleteApiPath + "/" + this.state.item.id;
            await CoreApi.deleteItem(url);

            if(this.onDeleted)
                this.onDeleted();
            else        
                this.fetchRecords();
    
            this.setState({
                showModal: false,
                status: ModuleStatusEnum.rest,
                item: null
            });
        }
    }

    onChange = (values) => {
        if(this.onBeforeChange)
            this.onBeforeChange(values);

        const { item } = this.state;

        if(!item || !item.id)
            return;

        // Use a data adapter if it has been specified
        if(this.updateDataAdapter)
            values = this.updateDataAdapter(values);

        const t = this;

        // Validation
        if(t._updateTimeout !== null) {
            clearTimeout(t._updateTimeout);
            t._updateTimeout = null;
        }

        t._updateTimeout = setTimeout(function () {
            t.schema.isValid(values).then(function(valid) {
                if(valid) {
                    t.update(values);
                }
            });
        }, t._updateDelayMs);

        return {}; // This is required by Formik
    }

    onClose = () => {
        AppContext.history.push(this.info.path);
    //   console.log("close " + this.info.path, this.CancelTokenSource, this.state.filters);
      if(this.CancelTokenSource)
          this.CancelTokenSource.cancel('Operation canceled by the user.');
    }

    maskTemplate(m, initialValues) {
        // Not accept null values
        for(let key in initialValues) {
            if(initialValues[key] === null)
                initialValues[key] = "";
        }

        if(initialValues === undefined)
            initialValues = this.initialValues;

        return (
            <Formik validate={this.onChange} validationSchema={this.schema}
                        enableReinitialize={true} initialValues={initialValues}
                        onSubmit={(values, actions) => {
                            console.log(values['id'])
                            if(!values['id']) {
                                console.log("SUBMIT", values);
                                this.onCreate(values);
                            }
                    }}>
                {m}
            </Formik>
        );
    }

    overlay() {
        const message = "";

        return (
            <div style={this.state.status === ModuleStatusEnum.uploading || this.state.status === ModuleStatusEnum.overlay ? {display: "block"} : {display: "none"}}>
                <div className="fullscreen-overlay">
                    <div className="centered">
                        <p>{message}</p>

                        {AppContext.r["preloader"]}
                    </div>
                </div>
            </div>
        );
    }

    setModalFree = () => {
        this.setState({
            modalBusy: false,
            modalMessage: null
        });
    }

    onCloseModal = () => {
        this.setState({
            showModal: false,
        });
    }

    setModalTitle = (title) => {
        this.setState({
            modalTitle: title
        });
    }

    setModalFooter = (footer) => {
        this.setState({
            modalFooter: footer
        });
    }

    setModal = (title, view, footer = null) => {
        this.setState({
            modalTitle: title,
            modalView: view,
            modalFooter: footer
        });
    }

    confirm = (title, text, callback) => {
        const confirmModalFooter = (
            <Modal.Footer>
                <Button variant="secondary" onClick={this.onCloseModal}>
                    {AppContext.r["no"]}
                </Button>
                <Button variant="success" onClick={() => callback()}>
                    {AppContext.r["yes"]}
                </Button>
            </Modal.Footer>
        );

        this.setModal(title, text, confirmModalFooter);

        this.setState({
            showModal: true
        })
    }

    onDelete = () => {
        const deleteModalFooter = (
            <>
                <Button onClick={this.onCloseModal}>
                    {AppContext.r["cancel"]}
                </Button>
                
                <Button variant="contained" className="danger-button" onClick={this.delete}>
                    {AppContext.r["delete"]} <DeleteIcon />
                </Button>
            </>
        );

        this.setModal(AppContext.r["confirm-delete-heading"], AppContext.r["confirm-delete"], deleteModalFooter);

        this.setState({
            showModal: true
        })
    }
        
    onCreate = async (values) => {
        const response = await this.create(values);
        console.log(response);
        
        if(response.status === 201 && response.data.data && response.data.data.id)
            AppContext.history.push(this.info.path + "/" + response.data.data.id, response.data.data);
    }

    onInsert = () => {
        this.enableEdit = true;
        // this.resetForm();
        AppContext.history.push(this.info.path + "/insert");
    }

    onChangePage = async (page) => {
        this.setState({
            currentPage: page
        });

        await this.fetchRecords(page);
    }

    onRowClick = (o) => {
        console.log(o);
        if(this.enableView) {
            this.setState({
                item: o
            });
            //console.log(o);

            AppContext.history.push(this.info.path+'/'+o.id, o);
        }

        if(this.onRowClicked)
            this.onRowClicked(o);
    }

    async uploadImageFile(file, attributeName = 'image') {
        this.setState({
            status: ModuleStatusEnum.uploading
        });
  
        const url = AppContext.s["host-url"] + this.mediaTransferApiPath;
    
        const formData = new FormData();
        formData.append('file', file);
  
        const response = await CoreApi.uploadFile(url, formData);

        if(response && response.status === 200 && response.data) {
            // Associate media with item
            const item = {
                //cover: response.data.name,
                image_name: file.name
            };

            item[attributeName] = response.data.name;

            console.log(item);

            await this.update(item, attributeName === 'image', attributeName === 'cover');
        }

        this.setState({
            status: ModuleStatusEnum.rest // TODO: Maybe define an error state
        });
    }

    onImageFileChanged = (file, attributeName = 'image') => {
        console.log(file)
        this.uploadImageFile(file, attributeName);

        const item = { ...this.state.item };
        item[attributeName] = file.name;

        this.setState({
            item: item
        });
    }

    onDeleteImage = (attributeName = 'image') => {
        const values = { };
        values[attributeName] = "-1";

        this.update(values, attributeName === 'image', attributeName === 'cover');

        const item = { ...this.state.item };
        item[attributeName] = null;

        this.setState({
            item: item
        });
        
        this.formImageRef.current.resetImage();
    }

    render() {
        if (this.state.redirectTo) {
            return (
                <Redirect push to={{
                    pathname: this.state.redirectTo,
                    state: this.state.redirectState ? this.state.redirectState : { }
                }} />
            );
        }

        const { item } = this.state;

        if(item === null && this.state.records === null
           || this.state.status === ModuleStatusEnum.loading)
            return (
                <div className="module">
                    {AppContext.r["preloader"]}
                </div>);

        const modal = (
            <Modal show={this.state.showModal} onHide={this.onCloseModal} center>
                {this.state.modalTitle && 
                    <Modal.Header>
                        <Modal.Title>{this.state.modalTitle}</Modal.Title>
                    </Modal.Header> }

                <Modal.Body>
                    {this.state.modalView}
                </Modal.Body>

                {(this.state.modalBusy || this.state.modalMessage != null) && (
                    <div className="modal-overlay">
                        {this.state.modalBusy && AppContext.r["preloader"]}
                        <div className="centered">
                            {this.state.modalMessage}
                        </div>
                    </div>
                )}

                {this.state.modalFooter && 
                    <Modal.Footer>
                        {this.state.modalFooter}
                    </Modal.Footer> }
            </Modal>
        );

        if(item) {
            return (
                <div className="module item-container" ref={this.itemContainerRef}>
                    {this.mode === ModuleViewModeEnum.List && item &&
                        <div className="module-header">
                            {/* <h1>{this.PageInfo.itemTitle}</h1> */}
                            {this.closeButton}
                        </div>
                    }

                    { this.maskTemplate(this.mask, item) }
        
                    { modal }
                    <GlobalHotKeys keyMap={keyMap} handlers={this.handlers}/>
                </div>
                );
        }

        if(this.state.records) {
            // Pagination
            const items = [];

            if(this.showPagination && this.state.lastPage > 1) {
                let active = this.state.currentPage;
            
                for (let number = 1; number <= this.state.lastPage; number++) {
                    items.push(
                        <Pagination.Item key={number} active={number === active}
                            onClick={() => this.onChangePage(number)}>
                            {number}
                        </Pagination.Item>,
                    );
                }
            }
            
            return (
                <div className="module items-container">
                    <div className="module-header">
                        {this.enableInsert && this.insertButton}
                    { this.filtersForm && this.filtersForm() }
                    </div>

                    <JsonToTable ref={this.jsonTableRef} onRowClick={this.onRowClick}
                        onRowClassName={this.onRowClassName ? this.onRowClassName : null}
                        head={this.tableHead} body={this.state.records} filter={this.state.filters} />
                    { items.length > 0 ? <Pagination size="sm">{items}</Pagination> : "" }

                    { modal }
                    { this.overlay() }

                    <GlobalHotKeys keyMap={keyMap} handlers={this.handlers}/>
                </div>);
        }
    }
}