import React from 'react';

import Settings from '../../settings';

export default function loadResources (resources) {
    resources['hi'] = "Salve";
    resources['hello'] = "Ciao";
    resources['signup'] = "Registrati";
    resources['choose-nickname'] = "Scegli un nickname";
    resources['access-with-facebook'] = "Accedi con Facebook";
    resources['or-signup-via-email'] = "o registrati via e-mail";
    resources['or-signin-via-email'] = "o accedi via e-mail";
    resources['invalid-email'] = "L'email non è valida.";
    resources['invalid-name'] = "Il nome inserito non è valido.";
    resources['name-required'] = "Il nome è un campo obbligatorio.";
    resources['surname-required'] = "Il cognome è un campo obbligatorio.";
    resources['email-required'] = "L'email è un campo obbligatorio.";
    resources['password-min-length'] = "La password deve essere lunga almeno " + Settings["password-min-length"] + " caratteri.";
    resources['password-required'] = "La password è un campo obbligatorio.";
    resources['password-must-math'] = "Le password inserite non coincidono.";
    resources['phone-not-valid'] = "Il numero inserito non è valido.";
    resources['confirm-password-required'] = "Conferma la password.";
    resources['confirm-password'] = "Conferma la password";
    resources['login-title'] = "Accedi con il tuo Account";
    resources['insert-credentials'] = "Inserisci i tuoi username e password";
    resources['login'] = "Accedi";
    resources['logout'] = "Esci";
    resources["forgotten-password"] = "Password Dimenticata";
    resources["forgot-password"] = "Ho dimenticato la password";
    resources["forgot-password-desc"] = "Inserisci la tua email per reimpostare la password";
    resources["wrong-credentials"] = "Le credenziali immesse non sono valide. Controlla l'e-mail e la password e prova di nuovo ad accedere.";
    resources['account-created'] = <div><p>Fantastico. Il tuo account è stato creato con successo.</p><p>Benvenuto su <span>{Settings["project-name"]}</span>.</p></div>
    resources['account-not-created'] = <div><p>Si è verificato un problema nella creazione del tuo account. L'email che hai usato potrebbe già essere registrata a {Settings["project-name"]}. Prova ad effettuare il login o a specificare un'altra e-mail.</p></div>
    resources["something-wrong"] = "Qualcosa è andato storto. Se non riesci a risolvere, contattaci.";
    resources["server-not-working"] = "Il server non ha risposto correttamente alla tua richiesta. Riprova tra un po' o prova a contattare l'amministrazione.";
    resources["change-password"] = "Cambia Password";
    resources["delete-account"] = "Cancella Account";
    resources["delete-account-message"] = "Cancella permanentemente il tuo account " + Settings["project-name"] + " e l'informazione associata.";
    resources["delete-account-desc"] = (<div>
        <p>Questa operazione cancellerà <b>permanentemente</b> il tuo account e tutti i dati associati, anche riguardo gli eventi che hai ospitato.</p>
        <p>Per confermare che desideri procedere con questa operazione, digita la tua password e clicca su "{resources["delete-account"]}".</p>
        <p>Tutti i tuoi dati verranno rimossi dai nostri server ai sensi delle regolamentazioni GDPR e <b>non saranno ripristinabili</b>.</p>
    </div>);
    resources["old-password"] = "Password Corrente";
    resources["new-password"] = "Nuova Password";
    resources["new-password-confirmation"] = "Ripeti Nuova Password";
    resources["all-fields-required"] = "E' richiesto l'inserimento di tutti i campi.";
    resources["password-desc"] = "La nuova password deve essere costituita da almeno " + Settings["password-min-length"] + " caratteri e deve essere inserita 2 volte.";
    resources["wrong-password"] = "La password inserita non è valida.";
    resources["passwords-must-match"] = "Le password inserite non coincidono.";
    resources["sorry-you-leave"] = <div>
        <p><i className="far fa-frown"></i> Ci dispiace vederti andar via.</p>
        <p>Se dovessi decidere di riutilizzare il nostro servizio puoi sempre decidere di registrarti nuovamente.</p>
    </div>;
    resources["must-be-logged"] = "Devi essere loggato per accedere a questa sezione.";
    resources['you-are-not-logged-in'] = <div><p>Non hai effettuato l'accesso.</p><p>Accedi o registrati adesso.</p></div>;
    resources["my-account"] = "Il Tuo Account";
    resources["status"] = "Stato";
    resources['account-deleted'] = "Il tuo account è stato cancellato. Puoi sempre registrarti nuovamente se lo desideri.";
    resources["password-forgotten"] = "Hai dimenticato la password?";
    resources["request-reset-password"] = "Richiedi Reimpostazione Password";
    resources["reset-password"] = "Reimposta Password";
    resources["password-reset-requested"] = <div>
        <b className="primary-color">Controlla la posta!</b><br/>
        <p>Hai richiesto la reimpostazione della tua password. Se l'e-mail che hai inserito è valida riceverai le istruzioni successive via e-mail.</p>
    </div>;
    resources["unprocessable-reset"] = "Non è stato possibile reimpostare la password. Controlla di aver inserito correttamente la tua e-mail o richiedi nuovamente il link di reimpostazione password.";
    resources["password-resetted"] = "La tua password è stata reimpostata con successo.";
    resources["access-conditions"] = "Effettuando l'accesso ci autorizzi all'utilizzo dei cookies per migliorare l'esperienza di navigazione.";
    resources["signup-now"] = (<span>Se non hai un account, <a href="/signup">registrati</a> adesso.</span>);
}