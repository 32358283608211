/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";
import {useSelector} from "react-redux";

import ModulesMap from "../../../../../app/ModulesMap";
import AppContext from "../../../../../app/AppContext";

export function AsideMenuList({ layoutProps }) {
    const {user} = useSelector(state => state.auth);
    const location = useLocation();

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    const navLinks = [];
    
    if(user && user.roles && user.roles.length > 0 && user.roles[0]) {
        console.log(user);
        if(user.roles[0].modules != "") {
            const modules = JSON.parse(user.roles[0].modules);

            for (const i in modules) {
                const module = ModulesMap[modules[i].name];
        
                if(module && module.Info.show) {
                    if(modules[i].subMenus) {
                      const navSubLinks = [];
        
                        for (const j in modules[i].subMenus) {
                            const subModule = ModulesMap[modules[i].subMenus[j].name];
        
                            if(subModule && subModule.Info.show) {
                               const name = subModule.Info.nameResource ? AppContext.r[subModule.Info.nameResource] : subModule.Info.name;
        
                                navSubLinks.push(
                                    <li className={`menu-item ${getMenuItemActive(subModule.Info.path)}`}
                                        aria-haspopup="true" key={i+"_"+j}>
                                        <NavLink className="menu-link" to={subModule.Info.path}>
                                            <span className="menu-icon">
                                                <i className={subModule.Info.icon} />
                                            </span>
                                            <span className="menu-text">{name}</span>
                                        </NavLink>
                                    </li>);
                            }
                        }
        
                        const name = module.Info.nameResource ? AppContext.r[module.Info.nameResource] : module.Info.name;
        
                        navLinks.push(
                            <li className={`menu-item menu-item-submenu ${getMenuItemActive("/admin", true)}`}
                                aria-haspopup="true" data-menu-toggle="hover" key={i}>
                                <NavLink className="menu-link menu-toggle" to="/error">
                                    <span className="menu-icon">
                                        <i className={module.Info.icon} />
                                    </span>
                                    <span className="menu-text">{name}</span>
                                    <i className="menu-arrow"/>
                                </NavLink>
                            
                                <div className="menu-submenu">
                                    <i className="menu-arrow"/>
                                    <ul className="menu-subnav">
                                        <li className="menu-item  menu-item-parent" aria-haspopup="true">
                                            <span className="menu-link">
                                                <span className="menu-text">{module.Info.name}</span>
                                            </span>
                                        </li>
        
                                        {navSubLinks}
                                    </ul>
                                </div>
                            </li>);
                    } else {
                        const name = module.Info.nameResource ? AppContext.r[module.Info.nameResource] : module.Info.name;
        
                        navLinks.push(
                            <li className={`menu-item ${getMenuItemActive(module.Info.path, false)}`} 
                                aria-haspopup="true" key={i}>
                                <NavLink className="menu-link" to={module.Info.path}>
                                    <div className="menu-icon">
                                        <i className={module.Info.icon} />
                                    </div>
                                    <span className="menu-text">{name}</span>
                                </NavLink>
                            </li>
                        );
                    }
                }
            }
        }
    }

    return (
        <>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true">
                    <NavLink className="menu-link" to="/dashboard">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
                        </span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>

                {navLinks}
            </ul>
        </>
    );
}