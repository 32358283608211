import React from 'react';

import {ContentRoute} from "../../_metronic/layout";

import Module from '../modules/Module';
import AppContext from '../AppContext';

export default class Log extends Module {
    enableView = false;
    enableInsert = false;
    
    info = Log.Info;

    static Info = {
        "nameResource": "logs",
        "icon": "fa fa-tasks admin-menus-color",
        "path": "/logs",
        "show": true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Log match={props.match} location={props.location} history={props.history} />} />;
    }

    get apiPath() {
        return '/api/v1/logs';
    }
    
    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id"
            },{
                "Title": AppContext.r["user"],
                "Adapter": function(o) {
                    return o.user.email;
                }
            },{
                "Title": AppContext.r["date-time"],
                "Field": "created_at"
            },{
                "Title": AppContext.r["ip-address"],
                "Adapter": function(o) {
                    const ipInfo = JSON.parse(o.ip_info);
                    return (ipInfo) ? ipInfo.ip : "";
                }
            },{
                "Title": AppContext.r["country"],
                "Adapter": function(o) {
                    const ipInfo = JSON.parse(o.ip_info);
                    return (ipInfo) ? ipInfo.country : "";
                }
            }
        ];
    }
}
