import React from 'react';
import { Form as BForm } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { arrayRemove } from 'react-movable';
import { Formik, Form, Field } from 'formik';
import { LinearProgress } from '@material-ui/core';
import {  TextField } from 'formik-material-ui';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import * as Yup from 'yup';

import AppContext from 'app/AppContext';
import JsonToTable from 'framework/JsonToTable';
import {withModal} from 'framework/withModal';
import RemoteSelect from 'framework/RemoteSelect';

export class JsonTypesEnum {
    static get Text () { return "Text"; }
    static get Date () { return "Date"; }
    static get Number () { return "Number"; }
    static get Boolean () { return "Boolean"; }
}

class JsonField extends React.Component {
    state = {
        body: this.props.value ? JSON.parse(this.props.value) : []
    };

    jHead = [
        {
            "Title": AppContext.r["title"],
            "Field": "key"
        },{
            "Title": AppContext.r["type"],
            "Field": "type"
        },{
            "ClassName": "action-cell",
            "Adapter": (o, index) => {
                return (
                    <Button className="form-delete danger-link-button" onClick={() => this.onDelete(o, index)}>
                        {AppContext.r["delete"]} <DeleteIcon />
                    </Button>);
            }
        }
    ];

    typeOptions = [
        { "id": JsonTypesEnum.Text },
        { "id": JsonTypesEnum.Date },
        { "id": JsonTypesEnum.Number },
        { "id": JsonTypesEnum.Boolean },
    ]

    delete = (o, index) => {
        const body = arrayRemove(this.state.body, index);

        this.setState({
            body: body
        });

        this.props.modal.hide();

        if(this.props.onChange)
            this.props.onChange(body);
    }

    onDelete = (o, index) => {
        console.log(o, index);
        this.props.modal.confirm(AppContext.r["delete"], AppContext.r["confirm-delete"], () => this.delete(o, index));
    }

    insert = (values) => {
        console.log(values);

        const body = [ ...this.state.body, values ];

        this.setState({
            body: body
        });

        this.props.modal.hide();

        if(this.props.onChange)
            this.props.onChange(body);
    }

    onInsert = () => {
        const SignupSchema = Yup.object().shape({
            key: Yup.string()
                .min(2, AppContext.r["too-short"])
                .max(20, AppContext.r["too-long"])
                .required(AppContext.r["required"])
        });

        const initialValues = { key: "", type: JsonTypesEnum.Text };

        const mask = (
            <Formik initialValues={initialValues} validationSchema={SignupSchema} onSubmit={this.insert}
                    render={({submitForm, isSubmitting, touched, errors, values, handleChange}) => (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Form>
                        <BForm.Group>
                            <Field component={TextField} style={{width: "100%"}}
                                name="key" type="text" label={AppContext.r["title"]} />
                                {/* {errors.key && touched.key ? (<div>{errors.key}</div>) : null} */}
                        </BForm.Group>
                        
                        <BForm.Group>
                            <BForm.Label>{AppContext.r["type"]}</BForm.Label>
                            <RemoteSelect name="type" key={values.type} value={values.type} nullOption={false}
                                                onChange={handleChange} onChange2={(e, value) => { /*this.onChangeCategory(e, value, values)*/ }}
                                                options={this.typeOptions} fieldToMap={"id"} />
                        </BForm.Group>

                        {isSubmitting && <LinearProgress />}
 
                        <Box margin={1} className="submit-box">
                            <Button variant="contained" color="primary" className="color-white"
                                disabled={isSubmitting} onClick={submitForm}>
                                {AppContext.r["insert"]}
                            </Button>
                        </Box>
                    </Form>
                </MuiPickersUtilsProvider>
                )}
            />
        );

        this.props.modal.setView(AppContext.r["insert"], mask, true);
    }

    render() {
        return <JsonToTable head={this.jHead} body={this.state.body} onInsert={this.onInsert} />;
    }
}

export default withModal(JsonField);