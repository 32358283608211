import React from 'react';
import { Pagination, Button, Row, Col, Form, DropdownButton, Dropdown } from 'react-bootstrap';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from '../../modules/Module';
import JsonToTable from 'framework/JsonToTable';
import RemoteSelect from 'framework/RemoteSelect';
import CoreApi from 'api/Core';
import TriviaProApi from 'api/TriviaPro';

let yup = require('yup');

export default class Package extends Module {
    info = Package.Info;
    
    static Info = {
        nameResource: "packages",
        icon: "fas fa-folder",
        path: "/packages",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Package match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["packages"];
    }

    get apiPath() {
      return '/api/v1/packages';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["name"],
                "Field": "name"
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
        };
    }

    constructor(props) {
        super(props);

        this.jsonTableQuestionsRef = React.createRef();

        this.state['currentQuestionsPage'] = 1;
    }

    filtersForm() {
        return "";
    }

    onRowClicked = (o) => {
        this.fetchItem(o.id);
    }

    async beforeComponentDidMount() {
        this.languageOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/languages");
        Package.playlistOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/playlists"); // Resolve questions?
    }

    fetchQuestions = async (page = null) => {
        if(!page)
            page = this.state.currentQuestionsPage;
        
        const { item } = this.state;

        if(item) {
            console.log(this.state.item.id, "?page="+page+"&"+this.filtersQuery());
            const questions = await TriviaProApi.PackageQuestions(this.state.item.id, "?page="+page+"&"+this.filtersQuery());
    
            this.setState({
                questions: (questions && questions.data) ? questions.data : []
            });
        }
    }

    onChangeQuestionsPage = async (page) => {
        this.setState({
            currentQuestionsPage: page
        });

        this.fetchQuestions(page);
    }

    handleChangeCategory = async (e) => {
        console.log(this.filters, Object.keys(this.filters).length);

        this.setState({
            filters: this.filters,
            currentQuestionsPage: 1
        });

        await this.fetchQuestions(1);
    }

    onResetFilters = () => {
        this.filters = {
            category: null,
            currentQuestionsPage: 1
        }

        this.handleChangeCategory(null);
    }

    filters = {
        category: null,
    }
    
    questionsFiltersForm() {
        const { category } = this.state.filters;

        return (
            <div>
                <Form onChange={this.handleChangeCategory} className="filters-form">
                    <Form.Row>
                        <Col xs="6" md="4" lg="3">
                            <RemoteSelect name="categoryId" className="form-control-sm" 
                                options={this.state.categoryOptions} nullOption={true} key={this.state.filters.category} 
                                fieldToMap="category" value={this.state.filters.category}
                                onChange={(e, value) => { 
                                    if(value === "") value = null; this.filters["category"] = value ? value : null; 
                                }} />
                        </Col>
                        <Col xs="4" md="5" lg="4">
                            <Button variant="link" className="reset-filters-button" onClick={this.onResetFilters}
                                    style={(category) ? { display: "inline-block" } : { display: "none" }}><i className="fas fa-times"></i> Reset</Button>
                        </Col>
                    </Form.Row>
                </Form>
            </div>);
    }

    get mask () {
        const { questions } = this.state;

        let questionsView = AppContext.r["preloader"];

        if(questions) {
            // Pagination
            const items = [];

            if(questions.last_page > 1) {
                // const active = questions.current_page;
                const active = this.state.currentQuestionsPage;
            
                for (let number = 1; number <= questions.last_page; number++) {
                    items.push(
                        <Pagination.Item key={number} active={number === active}
                            onClick={() => this.onChangeQuestionsPage(number)}>
                            {number}
                        </Pagination.Item>,
                    );
                }
            }

            questionsView = (
                <div>
                    {this.questionsFiltersForm()}
    
                    <JsonToTable onRowClick={this.onQuestionRowClick} head={Package.jQuestionsHead} body={questions.data} 
                                 filter={{}} ref={this.jsonTableQuestionsRef} />

                    { items.length > 0 ? <Pagination size="sm">{items}</Pagination> : "" }
                </div>
            );
        }

        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <div className="package-form">
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Col md="12">
                            <Row>
                                <Form.Group as={Col} md="8">
                                    <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                    disabled={this.enableEdit === false}
                                                    value={values.name} onChange={handleChange}
                                                    isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                </Form.Group>
                                    
                                <Form.Group as={Col} md="4">
                                    <Form.Label>{AppContext.r["language"]}</Form.Label>
                                    <RemoteSelect name="language_id" key={values.language_id} value={values.language_id} onChange={handleChange}
                                                  disabled={this.enableEdit === false}
                                                  options={this.languageOptions} fieldToMap="name" />
                                </Form.Group>
                            </Row>

                            <Row style={this.enableEdit || !(this.state.item && this.state.questions) ? {display:"none"} : {display:"block"}}>
                                <Col>
                                    <p className="not-editable">{AppContext.r["collection-not-editable"]}</p>
                                </Col>
                            </Row>
                                
                            {/* Shown only on editing */}
                            <Form.Row style={this.displayOnEditStyle}>
                                <Form.Group as={Col}>
                                <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                                <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                                value={values.id} onChange={handleChange}
                                                isValid={touched.id && !errors.id} isInvalid={!!errors.id} />
                                </Form.Group>
                            </Form.Row>
                        </Col>

                        {this.saving}
                        
                        <Col>
                            {/* Shown only on editing */}
                            <Form.Row style={this.displayOnEditStyle}>
                                <div style={this.enableDelete ? {display: "block"} : {display: "none"}}>
                                    {this.deleteButton}
                                </div>
                            </Form.Row>
                            {/* Shown only on insert */}
                            <Form.Row style={this.displayOnInsertStyle}>
                                {this.createButton}
                            </Form.Row>
                        </Col>
                    </Form.Row>
                </Form>

                <div style={this.displayOnEditStyle}>
                    <hr />

                    <div className="module-header">
                        <h2>{AppContext.r["questions"]}</h2>

                        <Button variant="primary" className="rounded-btn form-insert" 
                            style={this.enableDelete ? {display: "block"} : {display: "none"}}
                            onClick={() => {
                                this.setState({redirectTo: "/questions/insert", redirectState: { package_id: this.state.item.id}});
                            }}>
                            <i className="fas fa-plus"></i>
                        </Button>
                    </div>

                    <div className="questions-view">
                        {questionsView}
                    </div>
                </div>
            </div>);
    }

    static indexToLetter(index) {
        switch(index) {
            case 0: return 'A';
            case 1: return 'B';
            case 2: return 'C';
            case 3: return 'D';
            case 4: return 'E';
            default: return '';
        }
    }

    static jQuestionsHead = [
        {
            "Title": AppContext.r["id"],
            "ClassName": "d-none d-sm-table-cell",
            "Field": "id"
        },
        {
            "Title": AppContext.r["type"],
            "Field": "type",
            "Adapter": function(o) {
                if(o.image_legacy || o.image) return <i className="far fa-image"></i>;
                if(o.video_url && o.video_url.length > 0) return <i className="fas fa-video"></i>;
                return <i className="fas fa-font"></i>;
            }
        },
        {
            "Title": AppContext.r["text"],
            "Field": "text"
        },
        {
            "Title": AppContext.r["category"],
            "Field": "category_id",
            "ClassName": "category-col",
            "Adapter": function(o) {
                const style={
                    backgroundColor: (o && o.category) ? o.category.color : "transparent"
                };
                const category = (o && o.category) ? o.category.category : "";
                return (<div><div className="category-dot" style={style}>&nbsp;</div>{category}</div>);
            }
        },
        {
            "Title": AppContext.r["exact"],
            "Adapter": function(o) {
                let exact_index = "";

                if(o && o.answers)
                    o.answers.forEach(function (a, i) {
                        if(a && a.is_correct) {
                            exact_index = i;
                        }
                        // console.log('%d: %s', i, value);
                    });
                        
                return Package.indexToLetter(exact_index);
            }
        },
        // {
        //     "Field": "published",
        //     "Adapter": function(o) {
        //         return (o.published === 1) ? <i className="fas fa-toggle-on"></i> : <i className="fas fa-toggle-off"></i>;
        //     }
        // },
        {
            "Adapter": function(o) {
                const playlistsMap = !Package.playlistOptions ? "" 
                    : Package.playlistOptions.map(p => <Dropdown.Item onClick={() => Package.addToPlaylist(p, o)}>{p.name}</Dropdown.Item>);

                return (
                    <DropdownButton id="dropdown-basic-button" size="sm" title={AppContext.r["add-to"]} onClick={(e) => e.stopPropagation()}>
                        {/* <Dropdown.Item href="#/action-1">{AppContext.r["new-playlist"]}</Dropdown.Item> */}
                        {/* <Dropdown.Divider /> */}
                        {playlistsMap}
                    </DropdownButton>
                );
            }
        }
    ];

    static async addToPlaylist(playlist, question) {
        console.log("Add to playlist", playlist, question);
        await TriviaProApi.AddToPlaylist(playlist.id, question.id);
    }

    onQuestionRowClick = (q) => {
        this.setState({redirectTo: "/questions/"+q.id});
    }

    onFetchingItem = () => {
        this.enableEdit = false;
        this.enableDelete = false;
    }

    onFetchedItem = async (item) => {
        if(item) {
            this.enableEdit = item.can_edit;
            this.enableDelete = item.can_edit;

            const categories = await TriviaProApi.StoreCategories(item.language_id);
            // const questions = await MenuParty.PackageQuestions(this.state.item.id);
            //console.log(this.state)

            this.setState({
                categoryOptions: categories,
                // questions: (questions && questions.data) ? questions.data : []
            });

            this.fetchQuestions();
        }
    }
}