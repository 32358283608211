import React from 'react';
import { Col, Form } from 'react-bootstrap';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import CoreApi from "api/Core";
import RemoteSelect from 'framework/RemoteSelect';
import _Store from "./_Store";
import _PriceListItem from "./_PriceListItem";
import QrtServiceApi from 'api/QrtService';

let yup = require('yup');

export default class Provider extends Module {
    info = Provider.Info;
    
    static Info = {
        nameResource: "providers",
        icon: "fas fa-user-md",
        path: "/providers",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Provider match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["provider"];
    }

    get apiPath() {
      return '/api/v1/providers';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none d-sm-table-cell",
            },
            {
                "Title": AppContext.r["name"],
                "Field": "name",
            },
            {
                "Adapter": (o) => {
                    const field = this.statusOptions[o.status_id];
                    return (field) ? field.title : "";
                }
            },
            {
                "Field": "published",
                "ClassName": "item-icons",
                "Adapter": (o) => {
                    // const icons = [];
                    // if(o.image) icons.push(<i key="image" className="far fa-image"></i>);
                    // return icons;
                    
                    return (
                        <div className="row-thumb">
                            {o.image && <img src={AppContext.s["host-url"] + this.apiPath + "/" + o.id + "/thumb"} alt="Thumb" /> }
                        </div>
                    );
                }
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
        };
    }
    
    async beforeComponentDidMount() {
        this.statusOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/providers/statuses", "id");
        
    }
    
    onFetchedItem = async (item) => {
        // Retrieve provider stores
        const storesResponse = await QrtServiceApi.Stores(item.id);
        console.log(storesResponse);
        if(storesResponse && storesResponse.data)
            this.setState({
                stores: storesResponse.data.data
            });
    }

    get mask () {
        this.formImageRatio = 1;

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["category"]}</Form.Label>

                                <RemoteSelect name="status_id" key={values.status_id} value={values.status_id} nullOption={true} fieldToMap="title"
                                                onChange={handleChange} onChange2={(e, value) => { setFieldValue("status_id", value)}}
                                                options={this.statusOptions} />
                            </Form.Group>
                        </Form.Row>        
                        
                        <Form.Row>
                            <Form.Group as={Col} xs="9">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange} />
                            </Form.Group>
                        </Form.Row>

                        <h3>{AppContext.r["store"]}</h3>

                        <_Store providerId={this.state.item.id} />
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>

                {this.state.stores && 
                    <Form.Row>
                        <Col>
                            <h3>{AppContext.r["price-list-items"]}</h3>
                        
                            <_PriceListItem storeId={this.state.stores[0].id} />
                        </Col>
                    </Form.Row> }
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}