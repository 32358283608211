import React from "react";
// import {
//   Dashboard
// } from "../../_metronic/_partials";

import AppContext from 'app/AppContext';
import ProjectIdsEnum from 'app/settings/ProjectIdsEnum';

import DashboardMenuPro from "../dashboards/MenuPro/Dashboard";
import DashboardQuartarone from "../dashboards/Quartarone/Dashboard";

export function DashboardPage() {
    if(AppContext.s["project-id"] === ProjectIdsEnum.UderlyPro)
        return <DashboardMenuPro />;
    if(AppContext.s["project-id"] === ProjectIdsEnum.QrtService)
        return <DashboardQuartarone />;
    return "No valid dashboard found";
}
