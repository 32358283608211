import Axios from 'axios';
import Cookie from 'react-cookies';

import AppContext from '../app/AppContext';

export default class Auth {
    static async fetchAsync (url, fieldToMap) {
        const res = await Axios.get(url, { headers: { Authorization: AppContext.Auth.bearer } });
        const data = (res.data.data !== undefined) ? res.data.data : res.data;

        if(fieldToMap !== undefined && fieldToMap.length > 0) {
            const mappedData = [];
            for (const x of data) {
                mappedData[x[fieldToMap]] = x;
            }
            return mappedData;
        }

        return data;
    };

    static user = null;

    static c = {
        "token_type": "Bearer",
        "expires_in": -1,
        "access_token": "",
        "refresh_token": ""
      };

    static get bearer () { return 'Bearer '.concat(Auth.c["access_token"]); }

    static fetchUser() {
        // const authStr = 'Bearer '.concat(Auth.c["access_token"]);
        // var config = { headers: { Authorization: authStr, 'Content-Type': 'application/json' } };

        const response = Axios.get(AppContext.s["api-url"] + '/user');
        console.log(response);
        return response;
        // .catch(function (error) {
        //     if(error.response)
        //         console.log(error.response.status);
        // });
        // TODO: "data.data" shouldn't be a thing
        //return (userResponse !== undefined && userResponse.status === 200) ? userResponse.data.data : null;
    }

    static async changePassword(password, newPassword) {
        // const authStr = 'Bearer '.concat(Auth.c["access_token"]);
        // var config = { headers: { Authorization: authStr, 'Content-Type': 'application/json' } };

        const response = await Axios.put(AppContext.s["api-url"] + '/changepassword', {
            old_password: password,
            new_password: newPassword
        }).catch(function (error) {
            return error.response;
        });

        return response;
    }

    static async deleteAccount(password) {
        const authStr = 'Bearer '.concat(Auth.c["access_token"]);
        var config = { headers: { Authorization: authStr, 'Content-Type': 'application/json' } };

        const response = await Axios.put(AppContext.s["api-url"] + '/deleteaccount', {
            password: password
        }, config).catch(function (error) {
            return error.response;
        });

        return response;
    }

    static logout() {
        Auth.user = null;
        // Cookie.remove('refresh-token');
        Cookie.remove('auth');
    }
    
    static async loginSync(username, password) {
        console.log("login sync => URL: " + AppContext.s["api-url"] + '/login', {
            "grant_type": "password",
            "client_id": AppContext.s["client-id"],
            "client_secret": AppContext.s["client-secret"],
            "username": username,
            "password": password
        });

        const response = Axios.post(AppContext.s["api-url"] + '/login', {
            "grant_type": "password",
            "client_id": AppContext.s["client-id"],
            "client_secret": AppContext.s["client-secret"],
            "username": username,
            "password": password
        });

        return response;
    }
    
    static async login(username, password) {
        console.log(AppContext.s["api-url"] + '/login');

        const response = await Axios.post(AppContext.s["api-url"] + '/login', {
            "grant_type": "password",
            "client_id": AppContext.s["client-id"],
            "client_secret": AppContext.s["client-secret"],
            "username": username,
            "password": password
        }).catch(function (error) {
               console.log(error);
               if (error.response) {
            //   console.log(error.response.status);
            //   console.log(error.response.headers);
                return error.response;
            }
        });
        console.log(response);
        
        this.cacheTokenFromResponse(response);

        return response;
    }

    static cacheTokenFromResponse(response) {
        if(response && response.status === 200) {
            // Save access token configuration
            this.c = response.data;

            const cache = {
                access_token: response.data.access_token,
                expires_in: response.data.expires_in,
                refresh_token: response.data.refresh_token,
            };

            Cookie.save('auth', cache, { path: '/' });
            this.user = this.c.user;
        }
    }

    static async login2(username, password) {
        // Get Access Token /oauth/token
        const response = await Axios.post(AppContext.s["host-url"] + '/oauth/token', {
            "grant_type": "password",
            "client_id": AppContext.s["client-id"],
            "client_secret": AppContext.s["client-secret"],
            "username": username,
            "password": password
        }).catch(function (error) {
               console.log(error);
               if (error.response) {
            //   console.log(error.response.status);
            //   console.log(error.response.headers);
                return error.response;
            }
          });
          
        if(response !== undefined && response.status === 200) {
            // Save access token configuration
            this.c = response.data;

            Cookie.save('auth', response.data, { path: '/' });

            // Retrieve User
            //console.log("Retrieve user");
            this.user = await Auth.fetchUser();
            //console.log(this.user);
        }

        return response;
    }

    static async refresh(refreshToken) {
        // Refresh Access Token /oauth/token
        const response = await Axios.post(AppContext.s["host-url"] + '/oauth/token', {
            "grant_type": "refresh_token",
            "refresh_token": refreshToken,
            "client_id": AppContext.s["client-id"],
            "client_secret": AppContext.s["client-secret"],
            "scope": ""
        }).catch(function (error) {
            if (error.response) {
                console.log(error.response.status);
                return error.response;
            }
        });

        if(response !== undefined) {
            // console.log(response.data);
            // Save access token configuration
            this.c = response.data;

            // Retrieve User
            this.user = await Auth.fetchUser();
        }

        return response;
    }

    static async autologin() {
        const cachedAuth = Cookie.load('auth');

        if (cachedAuth !== undefined) {
            Auth.c = cachedAuth;
            Auth.user = await Auth.fetchUser();
        }
    }
}