import React from 'react';
import { Col, Form } from 'react-bootstrap';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import Module from "app/modules/Module";
import PlaylistQuestions from './_PlaylistQuestions';

let yup = require('yup');

class Playlist extends Module {
    info = Playlist.Info;
    
    static Info = {
        nameResource: "playlists",
        icon: "fas fa-stream",
        path: "/playlists",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Playlist match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["playlists"];
    }

    get apiPath() {
        return '/api/v1/playlists';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none d-sm-table-cell",
            },
            {
                "Title": AppContext.r["name"],
                "Field": "name"
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
        };
    }

    constructor(props) {
        super(props);

        this.jsonTableQuestionsRef = React.createRef();
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    updateDataAdapter = (values) => {
        delete values["created_at"];
        delete values["updated_at"];
        return values;
    }

    async beforeComponentDidMount() {
        this.languageOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/languages");
    }

    get mask () {
        const questions = this.state.item ? this.state.item.questions : null;

        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <div className="package-form">
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Col md="12">
                            <Form.Row>
                                <Form.Group as={Col} xs="10" md="12">
                                    <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                    disabled={this.enableEdit === false}
                                                    value={values.name} onChange={handleChange}
                                                    isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                                </Form.Group>
                                    
                                {/* <Form.Group as={Col} xs="2" md="1">
                                    <Form.Label>{AppContext.r["public"]}</Form.Label>
                                    <ToggleButton className="published-button" toggled={values.public} on={AppContext.r["public"]} off={AppContext.r["private"]} key={values.public}
                                                    onToggle={
                                                        (value) => { 
                                                            const item = {...values}
                                                            item.public = value;
                                                            this.setState({item: item});
                                                            this.onChange(item);
                                                        }} />
                                </Form.Group> */}
                            </Form.Row>
                                        
                            <Form.Row style={(this.state.item === undefined || this.state.item.id === undefined) ? { display: "none" } : { display: "flex"}}>
                                <Form.Group as={Col} xs="6">
                                    <Form.Label>{AppContext.r["created-at"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["placed-at"]} name="created_at" disabled
                                                value={values.created_at} onChange={handleChange} />
                                </Form.Group>

                                <Form.Group as={Col} xs="6">
                                    <Form.Label>{AppContext.r["updated-at"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["updated-at"]} name="updated_at" disabled
                                                value={values.updated_at} onChange={handleChange} />
                                </Form.Group>
                            </Form.Row>

                            {/* <Row style={this.enableEdit || !(this.state.item && this.state.item.questions) ? {display:"none"} : {display:"block"}}>
                                <Col>
                                    <p className="not-editable">{AppContext.r["collection-not-editable"]}</p>
                                </Col>
                            </Row> */}
                                
                            {/* Shown only on editing */}
                            <Form.Row style={this.displayOnEditStyle}>
                                <Form.Group as={Col}>
                                <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                                <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                                value={values.id} onChange={handleChange}
                                                isValid={touched.id && !errors.id} isInvalid={!!errors.id} />
                                </Form.Group>
                            </Form.Row>
                        </Col>

                        {this.saving}
                        
                        <Col>
                            {/* Shown only on editing */}
                            <Form.Row style={this.displayOnEditStyle}>
                                <div style={this.enableDelete ? {display: "block"} : {display: "none"}}>
                                    {this.deleteButton}
                                </div>
                            </Form.Row>
                            {/* Shown only on insert */}
                            <Form.Row style={this.displayOnInsertStyle}>
                                {this.createButton}
                            </Form.Row>
                        </Col>
                    </Form.Row>
                </Form>

                <div style={this.displayOnEditStyle}>
                    <hr />

                    <div className="module-header">
                        <h2>{AppContext.r["questions"]}</h2>

                        {/* <Button variant="primary" className="rounded-btn form-insert" 
                            style={this.enableDelete ? {display: "block"} : {display: "none"}}
                            onClick={() => {
                                this.setState({redirect: "/questions/insert", redirectState: { package_id: this.state.item.id}});
                            }}>
                            <i className="fas fa-plus"></i>
                        </Button> */}
                    </div>

                    <div className="questions-view">
                        {questions && questions.length > 0 ? <h3>{AppContext.r["drag-and-drop"]}</h3> : ""}

                    { (this.state.item && questions) && <PlaylistQuestions playlist={this.state.item} /> }
                    </div>
                </div>
            </div>);
    }

    // render() {
    //     if (this.state.redirect) {
    //       return <Redirect push to={{
    //                 pathname: this.state.redirect,
    //                 state: this.state.redirectState ? this.state.redirectState : { }
    //             }} />;
    //     }

    //     return this.listModeRender(AppContext.r["playlist"], AppContext.r["playlists"]);
    // }
}

export default Playlist;