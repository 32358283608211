export default function loadClientResources (resources) {
    resources["product-code"] = "Product Code";
    resources["package-quantity"] = "Package Quantity";
    resources["package-size"] = "Package Size";
    resources["packaging"] = "Packaging";
    resources["placed-at"] = "Placed At";
    resources["requested-date"] = "Requested Date";
    resources["note"] = "Note";
    resources["status"] = "Status";
    resources["order-id-is"] = "This order ID is";
    resources["user"] = "User";
    resources["shop-item-enoteca-category"] = "Enoteca's Category";
    resources["shop-item-enoteca-categories"] = "Enoteca's Categories";
    resources["shop-item-enoteca-brand"] = "Enoteca's Brand";
    resources["shop-item-enoteca-brands"] = "Enoteca's Brands";
    resources["filter-not-available"] = "Filter Not Available";
    resources["clear-filter-not-available"] = "Clear Filter Not Available";
    resources["warehouse"] = "Warehouse";
    resources["warehouses"] = "Warehouses";
    //resources["order-type"] = [];
    //resources["order-type"][1] = "Order";
    resources["internal-order"] = "Internal Order";
    resources["internal-orders"] = "Internal Orders";
    resources["placed-by"] = "Placed By";
    resources["percent-volume"] = "% Alcohol";
}