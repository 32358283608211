export default function loadClientResources (resources) {
    resources["categories"] = "Categorie";
    resources["category"] = "Categoria";

    resources["vehicle"] = "Vehicle";
    resources["vehicles"] = "Vehicles";
    resources["plate"] = "Plate";
    resources["volume"] = "Volume";
    resources["cities"] = "Cities";
    resources["divisions"] = "Divisions";
    resources["human-resources"] = "Human Resources";
    resources["items"] = "Items";
    resources["jobs"] = "Jobs";
    resources["teams"] = "Teams";
    resources["vehicles"] = "Vehicles";
    resources["warehouses"] = "Warehouses";

    resources["customer"] = "Cliente";
    resources["customers"] = "Clienti";
    resources["plan"] = "Piano";

    resources["recipient"] = "Recipient";
    resources["recipients"] = "Recipients";
    resources["tables"] = "Tables";
    resources["service-categories"] = "Service Categories";
    resources["service-category"] = "Service Category";
    resources["customer"] = "Customer";
    resources["customers"] = "Customers";
    resources["plan"] = "Plan";
    resources["registry"] = "Registry";
    resources["registries"] = "Registries";
    resources["company-name"] = "Company Name";
    resources["tax-number"] = "Tax Number";
    resources["sdi-number"] = "SDI Number";
    resources["pec-email"] = "PEC";
    resources["business-type"] = "Business Type";
    resources["business-types"] = "Business Types";
    resources["registry-type"] = "Registry Type";
    resources["registry-types"] = "Registry Types";
    resources["registry-contacts"] = "Contacts";
    resources["registry-contact"] = "Contact";
    resources["anomaly"] = "Anomaly";
    resources["anomalies"] = "Anomalies";
    resources["contact-name"] = "Contact Name";
    resources["new-contact"] = "New Contact";
    resources["edit-contact"] = "Edit Contact";
    resources["delete-contact"] = "Delete Contact";
    resources["social-type"] = "Social Type";
    resources["social-types"] = "Social Types";
    resources["order-type"] = "Order Type";
    resources["order-types"] = "Order Types";
    resources["order-status"] = "Order Status";
    resources["order-statuses"] = "Order Statuses";
    resources["provider"] = "Provider";
    resources["providers"] = "Providers";
    resources["provider-status"] = "Provider Status";
    resources["provider-statuses"] = "Provider Statuses";
    resources["service"] = "Service";
    resources["services"] = "Services";
    resources["price-list-items"] = "Services List";
    resources["price-list-item"] = "Service";
}