import React from "react";
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage, Form as FormikForm } from 'formik';

import AppContext from '../AppContext';
import AuthApi from "../../api/Auth";
import Module, { ModuleStatusEnum } from "../modules/Module";
import {ModuleViewModeEnum} from "../modules/Module";
import FormImage from "../../framework/FormImage";

let yup = require('yup');

export default class UserProfile extends Module {
    mode = ModuleViewModeEnum.Custom;
    
    get apiPath() {
        return '/api/v1/users';
    }
    
    get itemApiPath() {
        return '/api/v1/user';
    }

    state = {
        ...this.state,
        password: "",
        newPassword: "",
        newPassword2: "",
    };

    get title() {
        return AppContext.r["my-account"];
    }

    async beforeComponentDidMount() {
        this.setState({
            status: ModuleStatusEnum.loading
        });

        const r = await this.fetchItem();
        if(r && r.data)
            AuthApi.user = r.data.data;

        this.setState({
            status: ModuleStatusEnum.rest,
            item: AuthApi.user,
        })
    }
    
    get schema () {
        return yup.object({
            id: yup.number().integer().required(),
            name: yup.string(),
            surname: yup.string(),
            country: yup.string(),
            timezone: yup.number(),
            phone: yup.string(),
            email: yup.string().email().required(),
        });
    }

    updateDataAdapter(values) {
        return {
            id: values.id,
            email: values.email,
            name: values.name,
            surname: values.surname,
            mobile_phone: values.mobile_phone
        };
    }

    onDeleteImage = () => {
        this.update({image: "-1"}, true);
        this.formImageRef.current.resetImage();
    }

    changePassword = async () => {
        console.log("CHANGE PASSWORD", this.state);

        this.setState({
            modalBusy: true
        });

        // Change Password Request
        const response = await AuthApi.changePassword(this.state.password, this.state.newPassword);
        console.log(response);

        let message = null;

        if(response.data === "ok" && response.status === 200)
            this.setState({
                showChangePassword: false
            });
        
        if(response.status === 401) { // Authorization Failed
            message = <div>
                <p><i className="far fa-frown"></i> {AppContext.r["wrong-password"]}</p>
                <Button onClick={this.setModalFree}>{AppContext.r['close']}</Button>
            </div>;
        }

        if(response.status === 400 || response.status === 500) // Bad Request or Internal Server Error
            message = response.data;

        this.setState({
            modalBusy: false,
            modalMessage: message
        });
    }

    changePasswordModalView = () => {
        const validationSchema = yup.object().shape({
            password: yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required']),
            new_password: yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required']),
            password_confirmation: yup.string()
                .oneOf([yup.ref('new_password'), null], AppContext.r['passwords-must-match'])
        });

        const t = this;

        return (
            <Modal.Body>
                <p>{AppContext.r["all-fields-required"]}</p>
                <p>{AppContext.r["password-desc"]}</p>

                <Formik
                    initialValues={{
                        password: '',
                        new_password: "",
                        password_confirmation: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={fields => {
                        console.log(fields);

                        t.changePassword(fields.password, fields.new_password);
                    }}
                    render={({ errors, status, touched }) => (
                        <FormikForm>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="new_password">{AppContext.r["new-password"]}</label>
                                <Field name="new_password" type="password" className={'form-control' + (errors.new_password && touched.new_password ? ' is-invalid' : '')} />
                                <ErrorMessage name="new_password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password_confirmation">{AppContext.r["new-password-confirmation"]}</label>
                                <Field name="password_confirmation" type="password" className={'form-control' + (errors.password_confirmation && touched.password_confirmation ? ' is-invalid' : '')} />
                                <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <button type="submit" className="btn btn-primary mr-2">{AppContext.r["change-password"]}</button>
                            </div>
                        </FormikForm>
                    )}
                />
            </Modal.Body>
        );
    }

    onChangePassword = () => {
        const view = this.changePasswordModalView();

        this.setModal(<><i className="fas fa-key"></i> {AppContext.r["change-password"]}</>, view)

        this.setState({
            showModal: true
        });
    }

    deleteAccount = async (password, newPassword) => {
        this.setState({
            modalBusy: true
        });

        const response = await AuthApi.deleteAccount(password);
        // console.log(response);
    
        let message = "";

        if(response) {
            if (response.data === "ok" && response.status === 200) {
                this.setModalFree();

                message = 
                    <div>
                        {AppContext.r["sorry-you-leave"]}
                        <Button onClick={() => {
                            this.setState({
                                redirectTo: "/logout",
                            });
                        }}>{AppContext.r['close']}</Button>
                    </div>;

                if(this.props.onAccountDeleted)
                    this.props.onAccountDeleted(response);
            } else {
                // Authorization Failed

                if(response.status === 401) {
                    message = <div>
                        <p><i className="far fa-frown"></i> {AppContext.r["wrong-password"]}</p>
                        <Button onClick={this.setModalFree}>{AppContext.r['close']}</Button>
                    </div>;
                }

                if(response.status === 400 || response.status === 500) // Bad Request or Internal Server Error
                    message = response.data;
            }
        } else {
            message = AppContext.r['server-not-working'];
        }

        this.setState({
            modalBusy: false,
            modalMessage: message
        });
    }

    deleteAccountModalView = () => {
        const validationSchema = yup.object().shape({
            password: yup.string()
                .min(AppContext.s["password-min-length"], AppContext.r['password-min-length'])
                //.matches(/[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/i, 'invalid Password')
                .required(AppContext.r['password-required'])
        });

        const t = this;

        return (
            <Modal.Body className="delete-account">
                <p>{AppContext.r["delete-account-desc"]}</p>

                <Formik
                    initialValues={{
                        password: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={fields => {
                        console.log(fields)

                        t.deleteAccount(fields.password);
                    }}
                    render={({ errors, status, touched }) => (
                        <FormikForm>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>

                            <div className="form-group">
                                <Button type="submit" variant="danger" className="btn btn-primary mr-2">{AppContext.r["delete-account"]}</Button>
                            </div>
                        </FormikForm>
                    )}
                />
            </Modal.Body>
        );
    }

    onDeleteAccount = () => {
        const view = this.deleteAccountModalView();

        this.setModal(<><i className="fas fa-user-alt-slash"></i> {AppContext.r["delete-account"]}</>, view)

        this.setState({
            showModal: true,
            modalBusy: false,
            modalMessage: null
        });
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form className="myaccount-form" onSubmit={handleSubmit}>
                <Form.Row >
                    <Col md="8">
                        <Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["name"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                        value={values.name} onChange={handleChange}
                                        isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["surname"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["surname"]} name="surname"
                                        value={values.surname} onChange={handleChange}
                                        isValid={touched.surname && !errors.surname} isInvalid={!!errors.surname} />
                        </Form.Group>
                        </Row>

                        <Row>
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["mobile-phone"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["mobile-phone"]} name="mobile_phone"
                                        value={values.mobile_phone} onChange={handleChange}
                                        isValid={touched.mobile_phone && !errors.mobile_phone} isInvalid={!!errors.mobile_phone} />
                        </Form.Group>

                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["email"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["email"]} name="email" disabled
                                        value={values.email} onChange={handleChange}
                                        isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                        </Form.Group>
                        </Row>
                        
                        <Form.Row>
                            <Form.Group as={Col}>
                                <p className="your-id-is-label">{AppContext.r["your-id-is"]} </p>
                                <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="your-id-is-control"
                                            value={values.id} onChange={handleChange}
                                            isValid={touched.id && !errors.id} isInvalid={!!errors.id} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                                <Button variant="link" onClick={this.onChangePassword}>
                                    <i className="fas fa-key"></i> {AppContext.r["change-password"]}
                                </Button>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Button variant="link danger" onClick={this.onDeleteAccount}>
                                    <i className="fas fa-user-times"></i> {AppContext.r["delete-account"]}
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </Col>

                    <Col md="4">
                        <FormImage type="avatar" ref={this.formImageRef}
                            imageUrl={AppContext.s["host-url"] + this.imageDeliveryApiPath}
                            onDelete={this.onDeleteImage}
                            onImageFileChanged={this.onImageFileChanged} />
                    </Col>
                </Form.Row>

                {this.saving}
            </Form>);
    }
}