import React from 'react'
import { Button } from 'react-bootstrap'

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import TriviaProApi from 'api/TriviaPro';
import Module from "app/modules/Module";

let yup = require('yup');

export default class Game extends Module {
    info = Game.Info;
    
    static Info = {
        nameResource: "games",
        icon: "fas fa-dice",
        path: "/games",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Game match={props.match} location={props.location} history={props.history} />} />;
    }

    enableView = false;
    enableInsert = false;
    
    get apiPath() {
      return '/api/v1/games';
    }

    get title () {
        return AppContext.r["games"];
    }

    get schema () {
        return yup.object({
            type: yup.string().required()
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id"
          },{
            "Title": AppContext.r["event-id"],
            "Field": "event_id"
          },{
            "Title": AppContext.r["started"],
            "Field": "start_timestamp"
          },{
            "Title": AppContext.r["stopped"],
            "Field": "stop_timestamp"
          },{
            "Title": AppContext.r["duration"],
            "Field": "duration"
          },{
            "Title": AppContext.r["max-players-count"],
            "Field": "max_players_count"
          },{
            "Title": AppContext.r["paused"],
            "Field": "paused",
            "Adapter": function(o) {
                return (o.is_paused === 1) ? <i className="fas fa-circle"></i> : <i className="far fa-circle"></i>;
            }
          },{
            "Title": AppContext.r["status"],
            "Field": "game_status",
            "Adapter": function(o) {
                let status = "";

                if (o.expired === true) status = "EXPIRED";
                else if (o.stopped === true) status = "STOPPED";
                else status = "RUNNING";

                status += " ("+o.game_status+")";

                return status;
            }
          },{
                "Adapter": (o) => {
                    return (<>
                        <Button variant="link" onClick={() => { this.setMaxPlayers(o, 100) }}>
                            <i className="fas fa-mobile-alt"></i> 100 players</Button>
                        <Button variant="link" className="danger" onClick={() => { this.deleteGame(o) }}>
                            <i className="fas fa-trash-alt"></i> {AppContext.r['delete']}</Button>
                    </>);
                }
            }
        ];
    }

    delete = async () => {
        console.log(this.state.gameToDelete);
        const eventId = this.state.gameToDelete.event_id;

        await TriviaProApi.DeleteGame(eventId);

        this.fetchRecords();
        this.onCloseModal();
    }

    deleteGame = async (o) => {
        this.onDelete();

        this.setState({
            gameToDelete: o
        });
    }

    setMaxPlayers = async (o) => {
        console.log("setMaxPlayers", o);

        await TriviaProApi.SetMaxPlayers(o.event_id, 100);

        this.onCloseModal();
    }
}