import React from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from '@material-ui/core/Select';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import Module from "app/modules/Module";
import RemoteSelect from 'framework/RemoteSelect';
import ToggleButton from 'framework/ToggleButton';

let yup = require('yup');

export default class ShopItem extends Module {
    info = ShopItem.Info;
    
    static Info = {
        nameResource: "shop-items",
        icon: "fas fa-utensils",
        path: "/shop/items",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopItem match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["shop-items"];
    }

    get apiPath() {
        return '/api/v1/shop/items';
    }
    
    get recordsApiPath() {
        return '/api/v1/shop/null/items';
    }
    
    get createApiPath() {
        return '/api/v1/shop/null/items';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required(),
            price: yup.number(),
            percent_discount: yup.number(),
            description: yup.string(),
            ingredients: yup.string(),
        });
    }

    get tableHead () {
        const t = this;

        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
        },{
            "Title": AppContext.r["name"],
            "Field": "name"
          },{
            "Title": AppContext.r["price"],
            "Field": "price"
          },{
            "Title": AppContext.r["percent-discount"],
            "Field": "percent_discount"
          },{
            "Title": AppContext.r["category"],
            "Field": "category_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.categoryOptions[o.category_id];
                return (field) ? field.name : "";
            }
          },{
            "Title": AppContext.r["shop-item-sub-category"],
            "Field": "sub_category_id",
            "ClassName": "d-none d-sm-table-cell",
            "Adapter": function(o) {
                const field = t.allCategoryOptions[o.sub_category_id];
                return (field) ? field.name : "";
            }
          },{
            "Field": "published",
            "ClassName": "item-icons",
            "Adapter": function(o) {
                const icons = [];

                if(o.image_legacy || o.image) icons.push(<i key="image" className="far fa-image"></i>);
                //icons.push( (o.published === 1) ? <i className="fas fa-toggle-on"></i> : <i className="fas fa-toggle-off"></i>);
                icons.push( (o.highlighted === 1) ? <i key="star" className="fas fa-star"></i> : "");

                return icons;
            }
          },{
            "Title": AppContext.r["published"],
            "Field": "published",
            "Adapter": function(o) {
                return <ToggleButton key={o.published} onToggleAsync={async () => await t.onTogglePublished(o)}
                    on={AppContext.r["yes"]} off={AppContext.r["no"]} toggled={o.published} />;
            }
          }];
    }

    onTogglePublished = async (o) => {
        console.log(o);

        const item = {
            id: o.id,
            published: !o.published
        };

        await this.update(item);
    }

    get initialValues() {
        return {
            product_code: "",
            name: "",
            description: "",
            ingredients: "",
            category_id: null,
            sub_category_id: null,
            // availability_id: null,
            published: true,
            // highlighted: false,
            price: 0,
            percent_discount: 0,
            shop_id: this.shopId,
            vegan: false,
            vegetarian: false,
            gluten_free: false
        };
    }
    
    async beforeComponentDidMount() {
        // this.availabilityOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shopitemavailabilities", "id");
        this.categoryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shop/null/categories", "id");
        this.allCategoryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shop/null/categories?all=1", "id");
    }

    handleChange = (e) => {
        console.log(this.filters);

        const filters = {...this.filters};

        this.jsonTableRef.current.setState({
            filterObj: filters
        })

        this.setState({
            filters: filters
        })
    }

    onResetFilters = () => {
        this.filters = {
            availability_id: null,
            category_id: null
        }

        this.handleChange(null);
    }

    filters = {
        availability_id: null,
        category_id: null
    }

    filtersForm = () => {
        const { availability_id, category_id } = this.state.filters;

        return (<Form onChange={this.handleChange} className="filters-form">
            <Form.Row>
                <Col xs="6" md="4" lg="3">
                    <Form.Label>{AppContext.r["category"]}</Form.Label>
                    <RemoteSelect name="categoryId" className="form-control-sm" options={this.categoryOptions} nullOption={true} key={this.state.filters.category_id} value={this.state.filters.category_id}
                            onChange={(e, value) => { if(value === "") value = null; this.filters["category_id"] = value ? parseInt(value, 10) : null; }} />
                </Col>
                <Col xs="8" md="5" lg="4">
                    {/*<ToggleButton on={AppContext.r["clear-filter-not-available"]} off={AppContext.r["filter-not-available"]} key={this.state.filters.availability_id == 2} toggled={this.state.filters.availability_id == 2}
                        onToggle={() => { this.filters.availability_id = this.filters.availability_id === 2 ? null : 2; this.handleChange(null); }} />*/}
        
                    <Button variant="link" className="reset-filters-button" onClick={this.onResetFilters}
                            style={(availability_id != null || category_id != null) ? { display: "inline-block" } : { display: "none" }}><i className="fas fa-times"></i> Reset</Button>
                </Col>
            </Form.Row>
        </Form>);
    }

    loadSubCategories = async (category_id) => {
        const subCategoryOptions = category_id ? await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shop/null/categories?parentId="+category_id, "id") : [];
        this.setState({ subCategoryOptions: subCategoryOptions });
    }
    
    onChangeCategory = async (e, value, values) => {
        var item = {...values}
        item.category_id = value;
        this.setState({ item: item });
        this.loadSubCategories(value);
    }

    onFetchedItem = async (item) => {
        if(item && item.category_id) {
            this.loadSubCategories(item.category_id);
        }
    }

    handleRichTextChange = (value) => {
        if(this.state.item !== null) {
            this.state.item["description"] = value;
            this.onChange(this.state.item);
        }
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Row>
                            <Form.Group as={Col} md="10">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>

                            <Form.Group as={Col} xs="2">
                                <Form.Label>{AppContext.r["published"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.published} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.published}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.published = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>
                        </Row>

                        <Row>
                            {/*<Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["availability"]}</Form.Label>

                                <RemoteSelect name="availability_id" key={values.availability_id} value={values.availability_id} 
                                                onChange={handleChange} nullOption={true}
                                                options={this.availabilityOptions} />
                                                </Form.Group>*/}

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["shop-item-category"]}</Form.Label>

                                <RemoteSelect name="category_id" key={values.category_id} value={values.category_id} nullOption={true}
                                                onChange={handleChange} onChange2={(e, value) => { values.sub_category_id = null; this.onChangeCategory(e, value, values)}}
                                                options={this.categoryOptions} />
                            </Form.Group>
                            
                            <Form.Group as={Col} md="6"
                                style={(this.state.item && this.state.item.category_id && this.state.subCategoryOptions && this.state.subCategoryOptions.length > 0) ? {display:"block"} : {display:"none"}}>
                                <Form.Label>{AppContext.r["shop-item-sub-category"]}</Form.Label>

                                <RemoteSelect name="sub_category_id" key={values.category_id+values.sub_category_id} value={values.sub_category_id}
                                                onChange={handleChange} nullOption={true} fieldToMap="name"
                                                options={this.state.subCategoryOptions} />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group as={Col} xs="3" sm="2">
                                <Form.Label>{AppContext.r["price"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["price"]} name="price"
                                                value={values.price} onChange={handleChange}
                                                isValid={touched.price && !errors.price} isInvalid={!!errors.price} />
                            </Form.Group>

                            <Form.Group as={Col} xs="3" sm="2">
                                <Form.Label>{AppContext.r["percent-discount"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["percent-discount"]} name="percent_discount"
                                                value={values.percent_discount} onChange={handleChange}
                                                isValid={touched.percent_discount && !errors.percent_discount} isInvalid={!!errors.percent_discount} />
                            </Form.Group>
                        </Row>

                        <Row>
                            {/* <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control as="textarea" rows="3" maxLength="255" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange}
                                                isValid={touched.description && !errors.description} isInvalid={!!errors.description} />
                            </Form.Group> */}
                            
                            <Form.Group as={Col} md="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <ReactQuill placeholder={AppContext.r["description"]} name="description"
                                            value={values.description} onChange={this.handleRichTextChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="12">
                                <Form.Label><h4>{AppContext.r["ingredients"]}</h4></Form.Label>
                                <p className="note">{AppContext.r["no-special-characters-only-comma"]}</p>
                                <Form.Control as="textarea" rows="3" maxLength="500" placeholder={AppContext.r["ingredients"]} name="ingredients"
                                                value={values.ingredients} onChange={
                                                    (value) => { 
                                                        console.log(value.target.value);
                                                        var item = {...values}
                                                        const ingredients = value.target.value.replace(/[^\w\s,'èàù\-òç']/gi, '');
                                                        //const ingredients = value.target.value.replace(/[^\w\s\,\'\è\à\ù\-\ò\ç']/gi, '');

                                                        item.ingredients = ingredients;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }}
                                                isValid={touched.ingredients && !errors.ingredients} isInvalid={!!errors.ingredients} />
                            </Form.Group>
                        </Row>

                       <Row>
                            <Form.Group as={Col} xs="3" sm="2">
                                <Form.Label>{AppContext.r["vegetarian"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.vegetarian} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.vegetarian}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.vegetarian = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>

                            <Form.Group as={Col} xs="3" sm="2">
                                <Form.Label>{AppContext.r["vegan"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.vegan} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.vegan}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        if(value === true) item.vegetarian = true;
                                                        item.vegan = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>

                            <Form.Group as={Col} xs="3" sm="2">
                                <Form.Label>{AppContext.r["gluten-free"]}</Form.Label>
                                <ToggleButton className="published-button" toggled={values.gluten_free} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.gluten_free}
                                                onToggle={
                                                    (value) => { 
                                                        var item = {...values}
                                                        item.gluten_free = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                            </Form.Group>
                        </Row>
                            
                        {/* Shown only on editing */}
                        <Form.Row style={this.displayOnEditStyle}>
                            <Form.Group as={Col}>
                            <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                            <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                            value={values.id} onChange={handleChange}
                                            isValid={touched.id && !errors.id} isInvalid={!!errors.id} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>

                {/* <Form.Control type="text"  name="shop_id" value={this.shopId}></Form.Control> */}

                {this.formFooter}
            </Form>);
    }
}