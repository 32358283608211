export default function loadClientResources (resources) {
    resources["shop"] = "Negozio";
    resources["shops"] = "Negozi";
    resources["shop-item-categories"] = "Categorie";
    resources["shop-item-category"] = "Categoria";
    resources["shop-item-sub-category"] = "Sottocategoria";
    resources["shop-item-sub-categories"] = "Sottocategorie";
    resources["highlighted"] = "In Evidenza";
    resources["percent-discount"] = "Sconto %";
    resources["placed-at"] = "Piazzato Il";
    resources["requested-date"] = "Data Richiesta";
    resources["order"] = "Ordine";
    resources["orders"] = "Ordini";
    resources["order-details"] = "Dettagli Ordine";
    resources["brand"] = "Brand";
    resources["shop-item-brand"] = "Marca";
    resources["shop-item-brands"] = "Marche";
    resources["customer"] = "Cliente";
    resources["customers"] = "Clienti";
    resources["pending-orders"] = "Nuovi Ordini";
    resources["no-pending-orders"] = "Non hai nuovi ordini.";
    resources["payment"] = "Pagamento";
    resources["order-to-table"] = "Ordine al Tavolo";
    resources["delivery"] = "Delivery";
    resources["takeaway"] = "Take Away";
    resources["opening-hours"] = "Orari di Apertura";
    resources["discount"] = "Sconto";
    resources["order-id-is"] = "ID ordine";
    resources["customer-notes"] = "Note Cliente";
}