import React from "react";

import {SubheaderContext} from "../../_metronic/layout";

import AppContext from '../AppContext';

export default class MyPage extends React.Component {
    static contextType = SubheaderContext;

    componentDidMount() {
        this.context.setTitle("Uderly Portal");
    }

    render() {
        return (
            <div className="myaccount-footer">
                <p>{AppContext.r["lara-cms"]}</p>
                    <img src="/assets/images/logo.png" alt="Uderly Ltd" />
                    <p>© 2020 Uderly Limited. All rights reserved.</p>
                    <p>Company registration No. 12293155. Registered in England and Wales.</p>
                    <p>VAT No. 338 8325 75. Registered office: 520, Birchwood Boulevard, Birchwood, Warrington, WA3 7QX, UNITED KINGDOM.</p>
                {/* <p>Prices exclude VAT unless otherwise stated.</p>
                <p>Privacy notice | Terms of service | EU Cookie Law</p> */}
            </div>);
    }
}