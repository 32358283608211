import React from 'react'

import { ContentRoute } from "../../_metronic/layout";

import Module from "../modules/Module";

export default class User extends Module {
    enableView = false;
    enableInsert = false;

    static Info = {
        "nameResource": "users",
        "icon": "fas fa-users admin-menus-color",
        "path": "/users",
        "show": true
    };

    static get route() {
        return <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                component={(props) => <User match={props.match} location={props.location} history={props.history} />} />;
    }

    get apiPath() {
        return '/api/v1/users';
    }

    get tableHead () {
        return [{
            "Title": "Id",
            "Field": "id"
        },{
            "Title": "e-Mail",
            "Field": "email"
        },{
            "Title": "Name",
            "Field": "name"
        },{
            "Title": "Surname",
            "Field": "surname"
        },{
            "Title": "Username",
            "Field": "username"
        },{
            "Title": "Mobile",
            "Field": "mobile_phone"
        },{
            "Title": "Created At",
            "Field": "created_at"
        },{
            "Title": "Role",
            "Field": "roles",
            "Adapter": function(o) {
                if(o.roles && o.roles.length > 0)
                return o.roles[0].title;
            }
        }];
    }
}
