import React from 'react';

import ProjectIdsEnum from '../settings/ProjectIdsEnum';
import Settings from '../settings';

import resourcesECommerce from "./e-commerce/resources";
import itResourcesECommerce from "./e-commerce/resources-it";
import resourcesAuthentication from "./authentication/resources";
import itResourcesAuthentication from "./authentication/resources-it";

import resourcesQuartarone from "./quartarone/resources";
import itResourcesQuartarone from "./quartarone/resources-it";

import resourcesTriviaPro from "./triviapro/resources";
import itResourcesTriviaPro from "./triviapro/resources-it";

import resourcesRafala from "./rafala/resources";
import itResourcesRafala from "./rafala/resources-it";

import resourcesFoodBond from "./menupro/resources";
import itResourcesFoodBond from "./menupro/resources-it";

import itResources from './resources-it';

const resources = [];

resources['preloader'] = (
    <div className="animated-preloader">
      <div className="outer">&nbsp;</div>
      <div className="inner">&nbsp;</div>
    </div>);

resources["method-not-implemented"] = "Method not implemented";
resources["property-not-implemented"] = "Property not implemented";

function enResources() {
    resources['hi'] = "Hi";
    resources['view-user'] = "View user";
    resources['user-profile'] = "User Profile";
    resources['welcome-message'] = "Welcome to " + Settings['project-name'] + " Portal";
    resources['welcome-message-desc'] = "";
    resources['days'] = "Days";
    resources['hours'] = "Hours";
    resources['minutes'] = "Minutes";
    resources['seconds'] = "Seconds";
    resources["awesome"] = "Awesome";
    resources["public"] = "Public";
    resources["private"] = "Private";
    resources["language"] = "Language";
    resources["my-account"] = "My Account";
    resources["my-account-desc"] = "";
    resources["hello"] = "Hello";
    resources["insert"] = "Insert";
    resources["update"] = "Update";
    resources["edit"] = "Edit";
    resources["remove"] = "Remove";
    resources["id"] = "Id";
    resources["title"] = "Title";
    resources["value"] = "Value";
    resources["name"] = "Name";
    resources["surname"] = "Surname";
    resources["email"] = "e-Mail";
    resources["phone"] = "Phone";
    resources["mobile-phone"] = "Mobile Phone";
    resources["published"] = "Published";
    resources["your-id-is"] = "Your ID is ";
    resources["item-id-is"] = "This item ID is ";
    resources["password"] = "Password";
    resources["created-at"] = "Created At";
    resources["updated-at"] = "Updated At";
    resources["size"] = "Size";
    resources["access-to-portal"] = "Access to " + Settings["project-name"] + " Portal";
    resources["description"] = "Description";
    resources["note"] = "Note";
    resources["quantity"] = "Quantity";
    resources["price"] = "Price";
    resources["availability"] = "Availability";
    resources["category"] = "Category";
    resources["date-time"] = "Date & Time";
    resources["time"] = "Time";
    resources["ip-address"] = "IP Address";
    resources["delete"] = "Delete";
    resources["create"] = "Create";
    resources["cancel"] = "Cancel";
    resources["yes"] = "Yes";
    resources["no"] = "No";
    resources["submit"] = "Submit";
    resources["no-items-in-the-list"] = "There are no items to display.";
    resources["no-special-characters-only-comma"] = "Special characters are not allowed, only blank spaces, dashes and commas.";
    resources["from"] = "From";
    resources["to"] = "To";
    resources["save"] = "Save";
    resources["datetime"] = "Date & Time";
    resources["timezone"] = "Timezone";

    resources["home"] = "Home";
    resources["user"] = "User";
    resources["users"] = "Users";
    resources["role"] = "Role";
    resources["roles"] = "Roles";
    resources["permission"] = "Permission";
    resources["permissions"] = "Permissions";
    resources["admin"] = "Admin";
    resources["assets"] = "Assets";
    resources["logs"] = "Logs";

    resources["store"] = "Store";
    resources["stores"] = "Stores";
    resources["shop"] = "Shop";
    resources["locale"] = "Locale";
    resources["shop-items"] = "Shop Items";
    resources["shop-item"] = "Shop Item";
    resources["enoteca-item"] = "Enoteca Item";
    resources["enoteca-items"] = "Enoteca Items";

    resources["saving"] = "Saving...";
    resources["close"] = "Close";
    resources["create-item-first"] = "Create the item before uploading the image.";
    resources["confirm-delete"] = "Are you sure you want to delete this item?";
    resources["confirm-delete-heading"] = "Delete this item";

    resources["locations"] = "Locations";
    resources["location"] = "Location";
    resources["address"] = "Address";
    resources["post-code"] = "Post Code";
    resources["city"] = "City";
    resources["county"] = "County";
    resources["country"] = "Country";

    resources["assets"] = "Assets";
    resources["asset"] = "Asset";
    resources["version"] = "Version";

    resources["asset-types"] = "Asset Types";
    resources["asset-type"] = "Asset Type";
    resources["type"] = "Type";
    resources["required"] = "Required";

    resources["lara-cms"] = "This is Lara CMS v0.9.0";

    resources["week-days-short"] = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
    resources["enable-edit"] = "Enable Edit";
    resources["disable-edit"] = "Disable Edit";
    resources["modules"] = "Modules";
    resources["module"] = "Module";
    resources["too-short"] = "Too short";
    resources["too-long"] = "Too long";
    resources["params"] = "Params";
}

export function loadResources() {
    if(Settings["language"] === "it") {
        itResources(resources);
        itResourcesAuthentication(resources);

        if(Settings["project-id"] === ProjectIdsEnum.QrtService) {
            itResourcesECommerce(resources);
            itResourcesQuartarone(resources);
        }
        if(Settings["project-id"] === ProjectIdsEnum.Rafala) {
            itResourcesECommerce(resources);
            itResourcesRafala(resources);
        }
        if(Settings["project-id"] === ProjectIdsEnum.UderlyPro) {
            itResourcesECommerce(resources);
            itResourcesTriviaPro(resources);
            itResourcesFoodBond(resources);
        }
    } else {
        enResources();
        resourcesAuthentication(resources);
        
        if(Settings["project-id"] === ProjectIdsEnum.QrtService) {
            resourcesECommerce(resources);
            resourcesQuartarone(resources);
        }
        if(Settings["project-id"] === ProjectIdsEnum.Rafala) {
            resourcesECommerce(resources);
            resourcesRafala(resources);
        }
        if(Settings["project-id"] === ProjectIdsEnum.UderlyPro) {
            resourcesECommerce(resources);
            resourcesTriviaPro(resources);
            resourcesFoodBond(resources);
        }
    }
}

// loadResources();

export default resources;