import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Container } from 'react-bootstrap';
// import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import AppContext from 'app/AppContext';
import CoreApi from "api/Core";
import MenuProApi from "api/MenuPro";
import FormImage from 'framework/FormImage';
import { asWidget } from 'framework/asWidget.js';
import ActiveWeek from 'framework/ActiveWeek';
import ActionButton from 'framework/ActionButton';
import { ContactSupportOutlined } from '@material-ui/icons';

// var QRCode = require('qrcode.react');
var HtmlToReactParser = require('html-to-react').Parser;

class StoreWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            restaurant: this.props.restaurant,
            enableEdit: false,
            openingTimes: null, // Storeable Value
            enableTakeaway: false // Storeable Value
        };
    }

    async componentDidMount() {
        if(document.getElementById("Description")) {
            const htmlToReactParser = new HtmlToReactParser();
            const reactElement = htmlToReactParser.parse(this.state.restaurant.description);

            ReactDOM.render(
                reactElement,
                document.getElementById('Description')
            );
        }
        
        if(this.state.restaurant.opening_times)
            this.setState({
                openingTimes: JSON.parse(this.state.restaurant.opening_times)
            });

        console.log(this.state.restaurant);
    }

    restaurantPage(includeHttp = false) {
        return ((includeHttp) ? "https://" : "") + this.state.restaurant.subdomain + "." + AppContext.s["access-domain"];
    }

    onUpdate = async () => {
        this.props.setOverlayMessage(AppContext.r["preloader"]);

        const values = {
            opening_times: JSON.stringify(this.openingTimes)
        };

        await CoreApi.updateItem(AppContext.s["api-url"] + '/shops/'+ this.state.restaurant.id, values);

        this.props.setOverlayMessage(null);
    }

    stripeOnboard = async () => {
        const r = await MenuProApi.OnBoard(this.state.restaurant.id);
        console.log(r);

        if(r && r.status === 201) {
            // AppContext.history.replace(r.data.account_links.url);
            window.location = r.data.account_links.url;
        }
    }

    stripeViewAccount = async () => {
        const r = await MenuProApi.LoginLink(this.state.restaurant.id);
        console.log(r);

        if(r && r.status === 200 && r.data.login_link) {
            // AppContext.history.replace(r.data.account_links.url);
            window.location = r.data.login_link.url;
        }
    }

    render() {
        const { restaurant } = this.state;

        const view = !restaurant ? AppContext.r["preloader"] : (
            <div className="restaurant-view">
                <h2>{restaurant.name}</h2>

                <FormImage type="avatar" viewOnly rounded height="128px" maxWidth="128px" 
                    imageUrl={AppContext.s["api-url"] + '/shops/'+ this.state.restaurant.id + '/image'} />

                <ActionButton small className="edit-store-button" icon="fas fa-cog" selected={this.state.enableEdit} 
                    tooltip={!this.state.enableEdit ? AppContext.r["enable-edit"] : AppContext.r["disable-edit"]}
                    action={() => this.setState({enableEdit: !this.state.enableEdit})} />

                { this.state.enableEdit && 
                    <div>
                        {/* <FormControlLabel
                            control={<Switch checked={restaurant.enable_takeaway} onChanged={this.} name="checkedEnableTakeaway" />}
                            label="Primary" /> */}
                            <h3>{AppContext.r["opening-hours"]}</h3>

                        {<ActiveWeek values={this.state.openingTimes} onChanged={(s) => this.openingTimes = s } />}

                        <Button className="update-store-button" onClick={this.onUpdate}>{AppContext.r["save"]}</Button>
                    </div>
                }

                <div className="connect-with-stripe">
                    <h3>Payments</h3>

                    {!restaurant.stripe_id ?
                        <>
                            <p>Connect with Stripe to receive payments to your account.</p>
                            <p>It will redirect you to the Stripe Uderly affiliates form.</p>

                            <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                action={this.stripeOnboard}>Connect Stripe</ActionButton>
                        </> : 
                        <>
                            <p>You have successfully connected your account with Stripe.</p>
                            {/* <Button variant="link" onClick={}>View Stripe Account</Button> */}
                            
                            {restaurant.stripe_account_type == "express" ?
                                <ActionButton className="connect-with-stripe-button" icon="fab fa-stripe-s"
                                    action={this.stripeViewAccount}>View Stripe Account</ActionButton> :
                                    <p>You can manage your payments directly from your <a href="https://dashboard.stripe.com/" target="_blank">Stripe's dashboard</a>.<br />If you wish to change or remove the payment account, please get in touch with us.</p> }
                        </>
                    }
                </div>
            </div>
        );

        // const view = !restaurant ? AppContext.r["preloader"] : 
        //     <div className="restaurant-view">
        //         <h2>{this.state.restaurant.name}</h2>
        //         <FormImage type="avatar" viewOnly rounded height="128px" maxWidth="128px" imageUrl={AppContext.s["api-url"] + '/shops/'+ this.state.restaurant.id + '/image'} />
                
        //         {restaurant.description && restaurant.description.length > 0 ? (
        //             <Accordion>
        //                 <Card>
        //                     <Card.Header>
        //                         <Accordion.Toggle as={Button} variant="link" eventKey="0">
        //                             {AppContext.r['description']}
        //                         </Accordion.Toggle>
        //                     </Card.Header>
        //                     <Accordion.Collapse eventKey="0">
        //                         <Card.Body><p id="Description"></p></Card.Body>
        //                     </Accordion.Collapse>
        //                 </Card>
        //             </Accordion>) : "" }

        //         <hr />

        //         <Row>
        //             <Col md={8} className="align-middle">
        //                 <p>{AppContext.r["access-url-desc"]} <a target="_blank" rel="noopener noreferrer" href={this.restaurantPage(true)}>{this.restaurantPage()}</a></p>
        //                 <span>{AppContext.r["qr-desc"]}</span>
        //             </Col>
        //             <Col md={4}>
        //                 <QRCode className="qr-image" value={this.restaurantPage(true)} size={512} />
        //             </Col>
        //         </Row>
        //     </div>;

        // const bigScreenSupportView = restaurant && restaurant.support_big_screen ? (
        //     <div className="support-big-screen-view">
        //         <Row>
        //             <Col md={4} className="align-middle">
        //                 <img className="big-screen" src="/assets/images/menuparty/big-screen.png" alt="Big Screen" />
        //             </Col>
        //             <Col md={8}>
        //                 <h2>{AppContext.r["awesome"]}!</h2>
        //                 <p>{AppContext.r["support-big-screen"]}</p>
        //                 <p><a target="_blank" rel="noopener noreferrer" href={this.restaurantPage(true)+"/tv"}>{this.restaurantPage()+"/tv"}</a></p>
        //             </Col>
        //         </Row>
        //     </div>
        // ) : "";
        
        return (
            <div>
                <h1 className="your-restaurant-title">{AppContext.r["your-shop"]}</h1>
                            
                <Container>
                    {view}
                    {/* {bigScreenSupportView} */}
                </Container>
            </div>);
    }
}

export default asWidget(StoreWidget);