import React from 'react';
import { Form, Col } from 'react-bootstrap';
import Axios from 'axios';

import AppContext from 'app/AppContext';
import {withModal} from 'framework/withModal';
import CoreApi from 'api/Core';
import QuickModule from 'framework/QuickModule';
import RemoteSelect from '../../../framework/RemoteSelect';

let yup = require('yup');

AppContext.yupAddPhoneTest(yup);

class _Store extends React.Component {
    get tableHead () {
        return [
            // {
            //     "Title": AppContext.r["name"],
            //     "Field": "name",
            // },
            {
                "Title": AppContext.r["city"],
                "Field": "city",
            },
            {
                "Title": AppContext.r["email"],
                "Field": "email",
            },
            {
                "Title": AppContext.r["phone"],
                "Field": "phone",
            }
        ];
    }

    get schema () {
        return yup.object({
            // name: yup.string().required(),
            phone: yup.string().phone().nullable(),
            email: yup.string().email().nullable(),
        });
    }

    async componentDidMount() {
        // Fetch timezones
        const res = await Axios.get(AppContext.s["api-url"] + "/timezones");
        if(res && res.data)
            this.timezoneOptions = res.data.map(x => ({ "id": x, "name": ((x > 0) ? "+" : "") + x }))
        // Fetch countries
        this.countryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/countries", "id");
        // Fetch locales
        this.localeOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/locales", "id");
    }

    view = ({ handleSubmit, handleChange, handleReset, values, touched, errors }) => (
        <>
            {/* <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["name"]}*</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                    value={values.name} onChange={handleChange}
                                    isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                </Form.Group>
                
                <Form.Group as={Col} md="4" xs="6">
                    <Form.Label>{AppContext.r["locale"]}</Form.Label>

                    <RemoteSelect name="locale_id" key={values.locale_id} value={values.locale_id} onChange={handleChange}
                                options={this.localeOptions} fieldToMap="locale" disabled />
                </Form.Group>

                <Form.Group as={Col} md="2" xs="6">
                    <Form.Label>{AppContext.r["timezone"]}</Form.Label>

                    <RemoteSelect name="timezone" key={values.timezone} value={values.timezone} onChange={handleChange}
                                options={this.timezoneOptions} fieldToMap="name" disabled />
                </Form.Group>
            </Form.Row> */}

            <Form.Row>
                <Form.Group as={Col} xs="12">
                    <Form.Label>{AppContext.r["address"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["address"]} name="address1"
                                    value={values.address1} onChange={handleChange}
                                    isValid={touched.address1 && !errors.address1} isInvalid={!!errors.address1} />
                </Form.Group>

                <Form.Group as={Col} xs="12">
                    <Form.Control type="text" placeholder={AppContext.r["address"]} name="address2"
                                    value={values.address2} onChange={handleChange} />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} md="3">
                    <Form.Label>{AppContext.r["post-code"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["post-code"]} name="post_code"
                                    value={values.post_code} onChange={handleChange} />
                </Form.Group>

                <Form.Group as={Col} md="5">
                    <Form.Label>{AppContext.r["city"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["city"]} name="city"
                                    value={values.city} onChange={handleChange}/>
                </Form.Group>
                
                <Form.Group as={Col} md="4">
                    <Form.Label>{AppContext.r["country"]}</Form.Label>

                    <RemoteSelect name="country_id" key={values.country_id} value={values.country_id} onChange={handleChange}
                                options={this.countryOptions} disabled />
                </Form.Group>
            </Form.Row>

            {/*<Form.Row>
                <Form.Group as={Col} xs="12">
                    <Form.Label>{AppContext.r["description"]}</Form.Label>
                    <ReactQuill placeholder={AppContext.r["description"]} name="description"
                                value={values.description} 
                                onChange={e => this.handleRichTextChange(e, setFieldValue)} />
                </Form.Group>
            </Form.Row> */}

            <Form.Row>
                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["email"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["email"]} name="email"
                                value={values.email} onChange={handleChange}
                                isValid={touched.email && !errors.email} isInvalid={!!errors.email} />
                </Form.Group>

                <Form.Group as={Col} md="6">
                    <Form.Label>{AppContext.r["phone"]}</Form.Label>
                    <Form.Control type="text" placeholder={AppContext.r["phone"]} name="phone"
                                    value={values.phone} onChange={handleChange}
                                    isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone} />
                </Form.Group>
            </Form.Row>

            {/* Shown only on editing */}
            {(values.id) &&
                <Form.Row>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>}
        </>);

    render() {
        return <QuickModule 
            apiPath={'/providers/'+ this.props.providerId + '/stores'} 
            updateApiPath={'providers/stores'}
            tableHead={this.tableHead} schema={this.schema} view={this.view} 
            disableInsert disableDelete/>;
    }
}

export default withModal(_Store);