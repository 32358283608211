// FormImage v1.0

import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import Axios from 'axios';

import AppContext from '../app/AppContext';

export class FormImageStatus {
    static get rest() { return 0; }
    static get fetching() { return 1; }
    static get uploading() { return 2; }
}

export default class FormImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null,
            imageUrl: (this.props.imageUrl !== undefined) ? this.props.imageUrl + "?timestamp=" + new Date().getTime() : "",
            status: FormImageStatus.rest,
            ratio: (this.props.ratio !== undefined) ? this.props.ratio : 1,
            width: 0
        };

        this.fileInputRef = React.createRef();
        this.formImageRef = React.createRef();
    }

    onResize = () => {
        if (this.formImageRef.current !== null)
            this.setState({
                width: this.formImageRef.current.clientWidth
            });
    }

    async fetchBase64Image(url) {
        return Axios.get(url, { responseType: 'arraybuffer' })
            .then((response) => {
                let image = btoa(
                    new Uint8Array(response.data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );

                if (image !== null && image.length > 0)
                    return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;

                return null;
            })
            .catch((error) => {
                console.log(error);
                if(error.response)
                console.log(error.response);
                return null;
            });
    }

    fetchImage = async () => {
        if (this.props.imageUrl !== "") {
            this.setState({
                status: FormImageStatus.fetching,
                image: null
            });

            const imageBase64 = await this.fetchBase64Image(this.props.imageUrl);
            
            this.setState({
                imageUrl: this.props.imageUrl,
                image: imageBase64,
                status: FormImageStatus.rest
            });
        }
    }

    async resetImage() {
        this.setState({
            image: null
        });
    }

    async componentDidMount() {
        window.addEventListener("resize", this.onResize);

        this.onResize();

        if (this.state.image === null && this.state.imageUrl !== null) {
            await this.fetchImage();
        }
    }

    componentDidUpdate = async (prevProps) => {
        if (this.props.imageUrl !== prevProps.imageUrl) {
            await this.fetchImage();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    openFileDialog = () => {
        if (this.props.disabled || this.props.viewOnly) return;

        this.fileInputRef.current.click();
    }

    onDragOver = (event) => {
        event.preventDefault();

        if (event.dataTransfer != null && event.dataTransfer.files != null)
            console.log(event.dataTransfer, event.dataTransfer.files);
    }

    onDragLeave = (event) => {
        event.preventDefault();
    }

    onDrop = (event) => {
        event.preventDefault();
    }

    onImageFileChanged = (e) => {
        const t = this;
        
        if(this.props.onImageFileChanged !== undefined)
            this.props.onImageFileChanged(e[0]);

        if(e[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                t.setState({
                    image: e.target.result
                })
            };

            reader.readAsDataURL(e[0]);
        }
    }

    onDelete = (e) => {
        if (this.props.onDelete !== undefined)
            this.props.onDelete();
    }

    render() {
        const preloader = (this.state.status !== FormImageStatus.rest) ? AppContext.r['preloader'] : "";
        const formImageHeight = (this.props.height) ? parseInt(this.props.height) : this.state.width / this.state.ratio;
        const iconStyle = {
            display: (this.state.image === null || this.state.image === "") ? "block" : "none",
            fontSize: Math.min(100, formImageHeight / 1.6)
        }

        const icon = (this.state.image === null || this.state.image === "") ?
            (this.props.type === "avatar") ?
                <i style={iconStyle} className="fas fa-user-circle placeholder"></i> :
                <i style={iconStyle} className="far fa-image placeholder"></i> : "";

        const style = {
            height: formImageHeight,
            backgroundImage: `url(${this.state.image})`,
            maxWidth: (this.props.maxWidth) ? this.props.maxWidth : "auto"
        }

        let containerClass = "";
        if (this.state.status !== FormImageStatus.rest) containerClass += " loading";
        if (this.props.disabled) containerClass += " disabled";
        if (this.props.viewOnly) containerClass += " view-only";
        if (this.props.rounded) containerClass += " rounded";

        const displayIfImageIsSetStyle = (this.state.image != null) ? {} : { display: "none" };

        const browseStyle = {
           // marginTop: formImageHeight / 2
        }

        const className = (this.props.className ? this.props.className : "") + " form-image";

        return (
            <div className={containerClass}>
                <div ref={this.formImageRef} className={className} style={style}
                    onClick={this.openFileDialog}>
                    {icon}
                    {preloader}
                    <p className="disabled-message">{this.props.disabledMessage}</p>

                    <div className="browse-avatar" style={browseStyle}>
                        <i className="fas fa-circle"
                            style={{fontSize: Math.min(100, formImageHeight / 2), color: "white"}}></i>
                        <i className="fas fa-plus-circle"
                            style={{fontSize: Math.min(100, formImageHeight / 2)}}></i>
                    </div>

                    <input type="file" ref={this.fileInputRef} className="FileInput"
                        accept="image/gif, image/jpeg, image/png"
                        onChange={(e) => { this.onImageFileChanged(e.target.files); }} />
                </div>

                <div className="form-image-options">
                    <Button style={displayIfImageIsSetStyle} variant="link" onClick={this.onDelete}>{AppContext.r["delete"]} <i className="far fa-trash-alt delete"></i></Button>
                </div>
            </div>);
    }
}