import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import Axios from 'axios';

import {ContentRoute} from "../../../_metronic/layout";

import AppContext from '../../AppContext';
import CoreApi from '../../../api/Core';
import MenuProApi from '../../../api/MenuPro';
import Module, { ModuleStatusEnum, ModuleViewModeEnum} from "../../modules/Module";
import FormImage from "../../../framework/FormImage";
import RemoteSelect from '../../../framework/RemoteSelect';
import ToggleButton from '../../../framework/ToggleButton';

import { Route } from "react-router-dom";

export default class ShopUser extends Module {
    enableInsert = false;
    showPagination = true;

    info = ShopUser.Info;

    static Info = {
        nameResource: "users-check-ins",
        icon: "fas fa-users",
        path: "/shop/users",
        show: true
    };

    typesOptions = [
        { 
            "id": "login",
            "name": "login",
        },
        { 
            "id": "check-in",
            "name": "check-in",
        },
    ];

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopUser match={props.match} location={props.location} history={props.history} />} />;
    }
    
    get tableHead () {
        return [
            {
                "Title": AppContext.r["name"],
                "Adapter": function(o) {
                    return o.user.name + " " + o.user.surname;
                }
            },{
                "Title": AppContext.r["user"],
                "Adapter": function(o) {
                    return o.user.email;
                }
            },{
                "Title": AppContext.r["datetime"],
                // "Title": AppContext.r["first-access-datetime"],
                "Field": "created_at"
            },{
            //     "Title": AppContext.r["ip-address"],
            //     "Adapter": function(o) {
            //         const ipInfo = JSON.parse(o.ip_info);
            //         return (ipInfo) ? ipInfo.ip : "";
            //     }
            // },{
                "Title": AppContext.r["country"],
                // "Title": AppContext.r["first-access-country"],
                "Adapter": function(o) {
                    const ipInfo = JSON.parse(o.ip_info);
                    return (ipInfo) ? ipInfo.country : "";
                }
            },{
                "Title": AppContext.r["type"],
                "Field": "type"
            }
        ];
    }

    constructor(props) {
        super(props);

        this.state['report'] = [];
    }

    async onBeforeFetchRecords() {
        const myShops = await MenuProApi.MyShops();

        if(myShops && myShops.data.data && myShops.data.data.length > 0) {
            this.shopId = myShops.data.data[0].id;
            this.recordsApiPath = '/api/v1/shop/'+this.shopId+'/users';

            console.log(this._recordsApiPath);
        }
    }
    
    handleChangeType = async (e) => {
        console.log(this.filters, Object.keys(this.filters).length);

        this.setState({
            filters: this.filters,
            currentPage: 1,
            report: []
        });

        await this.fetchRecords();
    }

    onResetFilters = () => {
        this.filters = {
            type: null,
        }

        this.handleChangeType(null);
    }

    filters = {
        type: null,
    }

    onGenerateReport = async (daysspan) => {
        const report = { ...this.state.report };
        report[daysspan] = -1;

        this.setState({
            report: report
        });

        let query = "?daysspan=" + daysspan + "&perPage=9999999";

        if(this.filters) {
            const filtersQuery = this.filtersQuery();
            
            if(filtersQuery.length > 0)
                query += "&" + filtersQuery;
        }

        const url = AppContext.s["host-url"] + this.recordsApiPath + query;

        console.log(url);

        const response = await Axios.get(url)
            .catch((error) => {
                console.log('error ' + error);
            });

        console.log(response);

        const data = (response && response.data && response.data.data) ?
            response.data.data.map(x => { 
                return [x.user.name, x.user.surname, x.user.email, x.created_at]
            }) : [];

        console.log(data);

        report[daysspan] = data;

        this.setState({
            report: report
        });
    }

    reportAction = (days) => {
        const name = "Report " + days + " " + AppContext.r['days'];

        let view = "";
        
        if(this.state.report[days] === -1)
            view = AppContext.r["preloader"];
        else if(!this.state.report[days])
            view = (
                <Button variant="link" onClick={() => this.onGenerateReport(days)}>
                    <i className="fas fa-file-csv"></i> {AppContext.r['generate'] + " " + name}
                </Button>);
        else {
            view = (
                <div>
                    <p className="ready-for-download"><i className="fas fa-arrow-down"></i> {AppContext.r["ready-for-download"]}</p>

                    <CSVLink data={this.state.report[days]} filename={name + ".csv"}>
                        <i className="fas fa-file-csv"></i> {name}
                    </CSVLink>
                </div>);
        }

        return <div className="download-report">
            {view}
        </div>;
    }

    filtersForm = () => {
        const { type } = this.state.filters;

        return (
            <div>
                <Form onChange={this.handleChangeType} className="filters-form">
                    <Form.Row>
                        <Col xs="6" md="4" lg="3">
                            <RemoteSelect name="categoryId" className="form-control-sm" options={this.typesOptions} nullOption={true} key={this.state.filters.type} value={this.state.filters.type}
                                    onChange={(e, value) => { if(value === "") value = null; this.filters["type"] = value ? value : null; }} />
                        </Col>
                        <Col xs="4" md="5" lg="4">
                            <Button variant="link" className="reset-filters-button" onClick={this.onResetFilters}
                                    style={(type != null) ? { display: "inline-block" } : { display: "none" }}><i className="fas fa-times"></i> Reset</Button>
                        </Col>
                    </Form.Row>
                </Form>

                {this.reportAction(7)}
                {this.reportAction(30)}
                {this.reportAction(60)}
                {this.reportAction(90)}
            </div>);
    }

    // render() {
    //     return this.listModeRender("", AppContext.r["users-check-ins"], "shop-users-list", true, true);
    // }
}