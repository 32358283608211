import ProjectIdsEnum from './settings/ProjectIdsEnum';

import settingsUderlyPro from "./settings/settings-uderlypro";
import settingsQuartarone from "./settings/settings-quartarone";
import settingsQrtService from "./settings/settings-qrtservice";
import settingsIqSpace from "./settings/settings-iqspace";

const selectedProject = ProjectIdsEnum.QrtService; // Change this value to select the client's configuration

const settings = [];

settings['provider'] = {
    name: 'Uderly',
    website: 'www.uderly.com',
    websiteUrl: 'https://www.uderly.com',
    contactFormUrl: 'https://www.uderly.com/#contact',
};
settings["project-id"] = selectedProject;
settings["project-name"] = "Uderly";
settings["language"] = "en";
settings["logo-dark"] = "/assets/images/logo.png";
settings["logo-light"] = "/assets/images/logo.png";
settings["mini-logo-dark"] = "/assets/images/mini-logo.png";
settings["mini-logo-light"] = "/assets/images/mini-logo.png";
settings["login-aside-background-path"] = "/assets/images/login-aside.jpg";
settings["fb-app-id"] = '1889454704637203';
settings["password-min-length"] = 8;

settings["client-id"] = "2";
settings["client-secret"] = "0dIONiMg3HBDq6AID9dkFXxu8gNeWBQ6XLfxQtdu";
settings["api-url"] = "https://api.uderly.com/api/v1";

// Uncomment for targetting local environment
// settings["website"] = "homestead.test";
// settings["host-url"] = "http://" + settings["website"];
if(selectedProject === ProjectIdsEnum.UderlyPro)
    settingsUderlyPro(settings);
if(selectedProject === ProjectIdsEnum.Quartarone)
    settingsQuartarone(settings);
if(selectedProject === ProjectIdsEnum.QrtService)
    settingsQrtService(settings);
if(selectedProject === ProjectIdsEnum.IqSpace)
    settingsIqSpace(settings);

settings["host-url"] = "https://" + settings["website"];

settings['phone-regex'] = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/; // TODEL !?

export default settings;
