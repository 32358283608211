export default function loadClientResources (resources) {
    resources["shop"] = "Shop";
    resources["shops"] = "Shops";
    resources["shop-item-categories"] = "Categories";
    resources["shop-item-category"] = "Category";
    resources["shop-item-sub-category"] = "Sub-Category";
    resources["shop-item-sub-categories"] = "Sub-Categories";
    resources["highlighted"] = "Highlighted";
    resources["percent-discount"] = "% Discount";
    resources["placed-at"] = "Placed At";
    resources["requested-date"] = "Requested Date";
    resources["order"] = "Order";
    resources["orders"] = "Orders";
    resources["order-details"] = "Order Details";
    resources["brand"] = "Brand";
    resources["shop-item-brand"] = "Brand";
    resources["shop-item-brands"] = "Brands";
    resources["customer"] = "Customer";
    resources["customers"] = "Customers";
    resources["pending-orders"] = "Pending Orders";
    resources["no-pending-orders"] = "There are no pending orders.";
    resources["payment"] = "Payment";
    resources["order-to-table"] = "Order To Table";
    resources["delivery"] = "Delivery";
    resources["takeaway"] = "Take Away";
    resources["opening-hours"] = "Opening Hours";
    resources["discount"] = "Discount";
    resources["order-id-is"] = "This order ID is";
    resources["customer-notes"] = "Customer Notes";
}