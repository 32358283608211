import React from "react";
import { Row, Col, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';

import {ContentRoute} from "../../../_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from '../../../api/Core';
import QrtServiceApi from '../../../api/QrtService';
import Module from "../../modules/Module";
import RemoteSelect from '../../../framework/RemoteSelect';

import 'react-quill/dist/quill.snow.css';

let yup = require('yup');

export default class PriceListItem extends Module {
    info = PriceListItem.Info;

    static Info = {
        nameResource: "price-list-items",
        icon: "fas fa-bars",
        path: "/items",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <PriceListItem match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["price-list-item"];
    }
    
    get apiPath() {
        // return '/api/v1/providers/'+this.provider.id+'/pricelistitems';
        return '/api/v1/pricelistitems';
    }

    get createApiPath() {
        return '/api/v1/providers/stores/pricelistitems';
    }

    get itemApiPath() {
        return '/api/v1/providers/stores/pricelistitems';
    }
    
    get updateApiPath() {
        return '/api/v1/providers/stores/pricelistitems';
    }
    
    get deleteApiPath() {
        return '/api/v1/providers/stores/pricelistitems';
    }

    get schema () {
        return yup.object({
            name: yup.string().required(),
            description: yup.string(),
            price: yup.number().required(),
            discount: yup.number(),
            service_id: yup.number(),
            store_id: yup.number(),
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
            },{
                "Title": AppContext.r["store"],
                "Adapter": (item) => {
                    return item.store.name;
                }
            },{
                "Title": AppContext.r["title"],
                "Field": "name"
            },{
                "Title": AppContext.r["category"],
                "Adapter": (item) => {
                    return item.service.category.name;
                }
            },{
                "Title": AppContext.r["service"],
                "Adapter": (item) => {
                    return item.service.name;
                }
            },{
                "Title": AppContext.r["price"],
                "Field": "price",
            },{
                "Title": AppContext.r["discount"],
                "Field": "discount",
            }
        ];
    }

    _initialValues = {
        name: "",
        description: "",
        price: 0,
        discount: 0,
        service_id: null,
        store_id: null,
    };

    get initialValues() {
        return this._initialValues;
    }
    
    handleChange = (e) => {
        const filters = {...this.filters};

        this.jsonTableRef.current.setState({
            filterObj: filters
        })

        this.setState({
            filters: filters
        })
    }

    onResetFilters = () => {
        this.filters = {
            store_id: null,
            // category_id: null,
        }
        this.handleChange(null);
    }

    filters = {
        store_id: null,
        // category_id: null,
    }

    filtersForm = () => {
        const { store_id } = this.state.filters;

        return (<Form onChange={this.handleChange} className="filters-form">
            <Form.Row>
                <Col xs="12" sm="6">
                    {/* <Form.Label>{AppContext.r["store"]}</Form.Label> */}
                    <Form.Label>{AppContext.r["provider"]}</Form.Label>

                    <RemoteSelect name="store_id" className="form-control-sm" options={this.storeOptions} nullOption={true} key={this.state.filters.store_id} value={this.state.filters.store_id}
                        onChange={(e, value) => { if(value === "") value = null; this.filters["store_id"] = value ? parseInt(value, 10) : null; }} />
                </Col>

                {/* <Col xs="12" sm="6">
                    <Form.Label>{AppContext.r["category"]}</Form.Label>

                    <RemoteSelect name="category_id" className="form-control-sm" options={this.categoryOptions} nullOption={true} key={this.state.filters.category_id} value={this.state.filters.category_id}
                        onChange={(e, value) => { if(value === "") value = null; this.filters["category_id"] = value ? parseInt(value, 10) : null; }} />
                </Col> */}
            </Form.Row>
        </Form>);
    }

    async beforeComponentDidMount() {
        const response = await QrtServiceApi.MyProviders();

        if(response && response.data && response.data.data.length > 0)
            this.provider = response.data.data[0];

        const storesResponse = await QrtServiceApi.Stores(); // this.provider.id
        this.stores = storesResponse.data.data;
        this.storeOptions = RemoteSelect.dataAdapter(this.stores);

        const categoriesResponse = await QrtServiceApi.Categories();
        this.categories = categoriesResponse.data.data;
        this.categoryOptions = RemoteSelect.dataAdapter(this.categories);

        this._initialValues['store_id'] = this.stores[0].id;

        this.onChangeCategory(null, this.categories[0].id);
    }

    onFetchedItem = (item) => {
        console.log(item);

        if(item && item.service) {
            this.setState({
                category_id: item.service.category_id,
            });

            this.onChangeCategory(null, item.service.category_id);
        }
    }

    handleRichTextChange = (value, setFieldValue) => {
        setFieldValue('description', value)

        if(this.state.item) {
            const item = { ...this.state.item };
            item["description"] = value;

            this.onChange(item);
        }
    }

    onChangeCategory = async (e, categoryId, setFieldValue) => {
        const servicesResponse = await QrtServiceApi.Services(categoryId)

        if(!servicesResponse || !servicesResponse.data || servicesResponse.data.data.length == 0)
        {
            // No services have been found
            this.setState({
                servicesOptions: null,
                service_id: null
            });

            return;
        }

        console.log(servicesResponse);
        const services = servicesResponse.data.data;

        const servicesOptions = RemoteSelect.dataAdapter(services);

        if(setFieldValue)
            setFieldValue("service_id", services[0].id);

        //const item = { ...this.state.item };
        //item.service_id = services[0].id;
        if(!this.state.item || !this.state.item.service_id)
            this._initialValues['service_id'] = services[0].id;

        this.setState({
            servicesOptions: servicesOptions,
            service_id: services[0].id,
            //item: item
        });
    }

    get mask () {
        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="12">
                        <Row>
                            <Form.Group as={Col} md="6" xs="6">
                                <Form.Label>{AppContext.r["store"]}</Form.Label>

                                <RemoteSelect name="store_id" key={values.locale_id} value={values.store_id} onChange={handleChange}
                                            options={this.storeOptions} fieldToMap="name" />
                            </Form.Group>
                            
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["title"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["title"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>
                            
                            <Form.Group as={Col} md="4" xs="6">
                                <Form.Label>{AppContext.r["category"]}</Form.Label>

                                <RemoteSelect name="category_id" key={this.state.category_id} value={this.state.category_id} onChange={handleChange}
                                              onChange2={(e, value) => this.onChangeCategory(e, value, setFieldValue)} options={this.categoryOptions} fieldToMap="name" />
                            </Form.Group>

                            {this.state.servicesOptions && 
                                <Form.Group as={Col} md="4" xs="6">
                                    <Form.Label>{AppContext.r["service"]}</Form.Label>

                                    <RemoteSelect name="service_id" key={values.service_id} value={values.service_id} onChange={handleChange}
                                                  onChange2={(e, value) => setFieldValue("service_id", value)} options={this.categoryOptions}
                                                  options={this.state.servicesOptions} fieldToMap="name" />
                                </Form.Group>}

                            <Form.Group as={Col} xs="3" sm="2">
                                <Form.Label>{AppContext.r["price"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["price"]} name="price"
                                                value={values.price} onChange={handleChange}
                                                isValid={touched.price && !errors.price} isInvalid={!!errors.price} />
                            </Form.Group>

                            <Form.Group as={Col} xs="3" sm="2">
                                <Form.Label>{AppContext.r["discount"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["discount"]} name="discount"
                                                value={values.discount} onChange={handleChange}
                                                isValid={touched.discount && !errors.discount} isInvalid={!!errors.discount} />
                            </Form.Group>
                        </Row>

                        <Form.Row>
                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <ReactQuill placeholder={AppContext.r["description"]} name="description"
                                            value={values.description} 
                                            onChange={e => this.handleRichTextChange(e, setFieldValue)} />
                            </Form.Group>
                        </Form.Row>

{/* 
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Cover</Form.Label>
                                <FormImage ref={this.coverFormImageRef} ratio={3}
                                    disabled={this.state.item === undefined || this.state.item.id === undefined}
                                    disabledMessage={AppContext.r["create-item-first"]}
                                    viewOnly={!this.enableEdit}
                                    imageUrl={AppContext.s["host-url"] + this.coverDeliveryApiPath} 
                                    onDelete={() => {
                                        this.update({ cover: "-1" }, false, true);
                                        this.coverFormImageRef.current.resetImage();
                                    }}
                                    onImageFileChanged={(file) => this.onImageFileChanged(file, 'cover')} />
                            </Form.Group>
                        </Row> */}
                    </Col>
                    
                    {/* <Col md="4">
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Logo</Form.Label>
                                {this.formImage}
                            </Form.Group>
                        </Row>
                    </Col> */}
                    
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>
                
                {this.formFooter}
            </Form>);
    }
}