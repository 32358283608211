import React from 'react';
import { Button } from 'react-bootstrap';
import { arrayMove } from 'react-movable';

import AppContext from 'app/AppContext';
import TriviaProApi from 'api/TriviaPro';
import ActionButton from 'framework/ActionButton';
import JsonToTable from 'framework/JsonToTable';
import {withModal} from 'framework/withModal';
import Package from './Package.js';
import { arrayRemove } from 'react-movable';

class PlaylistQuestions extends React.Component {
    constructor(props) {
        super(props);

        this.jsonTableQuestionsRef = React.createRef();

        this.state = {
            playlist: props.playlist
        }

        const t = this;

        if(props.allowPlayback) 
            this.jQuestionsHead = [ 
                {
                    "Title": "",
                    "Adapter": function(o, index) {
                        return (
                            <ActionButton className="play-question" small tooltip={AppContext.r["play-question-tooltip"]}
                                action={async () => await t.playQuestion(o)} icon="fas fa-play" />);
                    }
                },
                ...Package.jQuestionsHead ];
        else
            this.jQuestionsHead = [ 
                ...Package.jQuestionsHead,
            ];

        // Remove "Add to playlist"
        this.jQuestionsHead.pop();

        // Add "Remove from playlist"
        this.jQuestionsHead.push({
            "Adapter": function(o, index) {
                return (
                    <Button variant="link" className="danger delete-player" onClick={() => { t.removeFromPlaylistConfirm(o, index) }}>
                        <i className="fas fa-trash-alt"></i> {AppContext.r['remove']}
                    </Button>);
            }
        });
    }

    playQuestion = async (question) => {
        console.log(this.props.eventId, this.state.playlist, question);
        await TriviaProApi.Forward(this.props.eventId, question.id, this.state.playlist.id);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.playlist !== this.props.playlist) {
            this.setState({
                playlist: nextProps.playlist
            });
        }
    }
    
    removeFromPlaylist = async (o, index) => {
        console.log(this.state.item, o, index);
        
        await TriviaProApi.RemoveFromPlaylist(this.state.playlist.id, o.id);
       
        
        const {playlist} = this.state;
        this.state.playlist.questions = arrayRemove(playlist.questions, index);

        this.setState({
            playlist: playlist
        });
        // this.jsonTableQuestionsRef.current.removeIndex(index);

        // Reload Playlist
        //this.fetchItem(this.state.playlist.id);

        this.props.hideModal();

        if(this.props.onRemovedFromPlaylist)
            this.props.onRemovedFromPlaylist(o, index);
    }

    removeFromPlaylistConfirm = (o, index) => {
        this.props.confirm(AppContext.r["remove-question"], AppContext.r["remove-question-confirm"], 
            () => this.removeFromPlaylist(o, index));
        // this.props.confirm(AppContext.r["remove-question"], AppContext.r["remove-question-confirm"], () => this.removeFromPlaylist(o, index));
    }
    
    onReorder = async (items, oldIndex, newIndex) => {
        // console.log("Reorder", items[oldIndex], newIndex);

        const q0 = items[oldIndex]; // Question to move
        const q1 = items[newIndex]; // Question to be next

        if(q0 && q1) {
            await TriviaProApi.Reorder(this.state.playlist.id, q0.id, q1.id);
                
            const playlist = this.state.playlist;
            playlist.questions = arrayMove(playlist.questions, oldIndex, newIndex);

            this.setState({
                playlist: playlist
            });

            if(this.props.onReordered)
                this.props.onReordered(playlist);
        }
    }

    onRowClassName(o) {
        return o.selected ? "playing" : "";
    }

    render() {
        const { playlist } = this.state;

        return playlist && playlist.questions ? (
            (<JsonToTable head={this.jQuestionsHead} body={playlist.questions} 
                    onReorder={this.onReorder} sortable filter={{}}
                    onRowClassName ={this.onRowClassName}
                    ref={this.jsonTableQuestionsRef} />)
        ) : AppContext.r["preloader"];
    }
}

export default withModal(PlaylistQuestions);