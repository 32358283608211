import Axios from 'axios';

import AppContext from 'app/AppContext';

export class MenuPartyRoles {
    static get Quizmaster () { return 8; }
}

export default class MenuPro {
    static async MyShops() {
        return await Axios.get(AppContext.s["api-url"] + "/myshops")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async UsersGraph(shopId) {
        return await Axios.get(AppContext.s["api-url"] + "/shop/" + shopId + "/users/graph")
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async OnBoard(shopId) {
        return await Axios.post(AppContext.s["api-url"] + "/stripe/onboard", { shopId: shopId })
            .catch((error) => {
                console.log('error ' + error);
            });
    }

    static async LoginLink(shopId) {
        return await Axios.post(AppContext.s["api-url"] + "/stripe/loginlink", { shopId: shopId })
            .catch((error) => {
                console.log('error ' + error);
            });
    }
}