import React from 'react';
import { Col, Form } from 'react-bootstrap';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import Module from "app/modules/Module";
import CoreApi from "api/Core";
import RemoteSelect from 'framework/RemoteSelect';
import JsonField, { JsonTypesEnum } from 'framework/JsonField';

let yup = require('yup');

export default class Service extends Module {
    info = Service.Info;
    
    static Info = {
        nameResource: "services",
        icon: "fas fa-user-md",
        path: "/services",
        show: true
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Service match={props.match} location={props.location} history={props.history} />} />;
    }

    get title () {
        return AppContext.r["service"];
    }

    get apiPath() {
      return '/api/v1/services';
    }
    
    get schema () {
        return yup.object({
            name: yup.string().required()
        });
    }

    get tableHead () {
        return [
            {
                "Title": AppContext.r["id"],
                "Field": "id",
                "ClassName": "d-none d-sm-table-cell",
            },
            {
                "Title": AppContext.r["name"],
                "Field": "name",
            },{
                "Title": AppContext.r["category"],
                "Field": "category_id",
                "ClassName": "d-none d-sm-table-cell",
                "Adapter": (o) => {
                    const field = this.categoryOptions[o.category_id];
                    return (field) ? field.name : "";
                }
            }
        ];
    }

    get initialValues() {
        return {
            name: "",
            description: "",
            category_id: null,
        };
    }
    
    async beforeComponentDidMount() {
        this.categoryOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/categories", "id");
        console.log(this.categoryOptions);
    }
    
    onChangeCategory = async (e, value, values) => {
        var item = {...values}
        item.category_id = value;
        this.setState({ item: item });
    }

    get mask () {
        this.formImageRatio = 1;

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
                <Form.Row>
                    <Col md="8">
                        <Form.Row>
                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["name"]}*</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["name"]} name="name"
                                                value={values.name} onChange={handleChange}
                                                isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                            </Form.Group>

                            <Form.Group as={Col} md="6">
                                <Form.Label>{AppContext.r["category"]}</Form.Label>

                                <RemoteSelect name="category_id" key={values.category_id} value={values.category_id} nullOption={true}
                                                onChange={handleChange} onChange2={(e, value) => { this.onChangeCategory(e, value, values)}}
                                                options={this.categoryOptions} />
                            </Form.Group>
                        
                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["description"]}</Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["description"]} name="description"
                                                value={values.description} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group as={Col} xs="12">
                                <Form.Label>{AppContext.r["params"]}</Form.Label>
                                <JsonField value={this.state.item.json_params} onChange={
                                    (value) => { setFieldValue("json_params", JSON.stringify(value))}} />
                            </Form.Group>
                        </Form.Row>
                    </Col>
                    
                    <Col md="4">
                        {this.formImage}
                    </Col>
                </Form.Row>
                
                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}
            </Form>);
    }
}