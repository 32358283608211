import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { List, arrayMove, arrayRemove } from 'react-movable';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import AppContext from '../app/AppContext';

export default class JsonToTable extends Component  {
    constructor(props) {
        super(props);

        this.items = this.props.body;
        
        this.state = {
            loading: false,
            items: null
        }

        this.tDragStart = 0;
        
        this.head = this.props.head;

        // Build (immutable) table head
        let key = 0;
        this.thead = (this.head) ? this.head.map((v) => {
            const className = v.ClassName ? v.ClassName : "";
            return <th key={key++} className={className}>{v.Title}</th>;
        }) : "";
    }

    componentDidMount() {
        this.filterList();
    } 

    componentWillReceiveProps(nextProps) {
        if(this.props.body !== nextProps.body)
            this.items = nextProps.body;
        
        this.filterList(nextProps);
    }

    filterList = (props = this.props) => {
        if(this.items)
            if(this.items.length)
                this.setState({
                    items: this.items.filter(item => this.complyFilters(item, props.filter))
                });
            else
                this.setState({
                    items: this.items // Do not use client-side filters
                });
    }

    complyFilters = (item, filters) => {
        let ret = true;
        
        if(filters)
            for (const [key, value] of Object.entries(filters)) {
                if(item[key] !== value && value != null) {
                    ret = false;
                    break;
                }
            }

        return ret;
    }

    removeIndex = (index) => {
        console.log("REMOVE ", index, this.state.items)
        this.setState(prevState => ({
            items: arrayRemove(prevState.items, index)
        }));
    }

    render() {
        if(!this.state.items)
            return AppContext.r["preloader"];

        let insertButton = "";

        if(this.props.onInsert)
            insertButton = (
                <Button variant="contained" color="primary" className="form-create"
                    onClick={this.props.onInsert}>
                    {AppContext.r["insert"]} <AddIcon />
                </Button>);

        if(this.state.items.length === 0)
            return (
                <>
                    {insertButton}

                    <div className="no-items">
                        <i className="far fa-folder-open"></i>
                        <p>{AppContext.r["no-items-in-the-list"]}</p>
                    </div>
                </>);

        const tbody = [];

        let index = 0;

        for(let key in this.state.items) {
            const o = this.state.items[key];
            const tds = [];

            for(var i in this.head) {
                let value = "";
                
                if(this.head[i]["Adapter"])
                    value = this.head[i]["Adapter"](o, index);
                else
                    value = o[this.head[i].Field];

                const className = this.head[i].ClassName ? this.head[i].ClassName : "";

                tds.push(<td key={"head-"+i} className={className}>{value}</td>);
            }

            index++;
         
            if(!this.props.sortable)
               tbody.push(
                    <tr key={o.id} className={(this.props.onRowClassName ? this.props.onRowClassName(o) : "")}
                        onClick={() => { if (this.props.onRowClick) this.props.onRowClick(o); }}>
                        {tds}
                    </tr>
                );
            else
                tbody.push( {jsx: tds, item: o});
        };
        
        const c = this.props.onRowClick ? "editable" : "" +  this.props.className ? this.props.className : "";

        if(!this.props.sortable) {
            return (
                <>
                    <Table responsive borderless hover size="sm" className={c}>
                        <thead>
                            <tr>{this.thead}</tr>
                        </thead>

                        <tbody>
                            { tbody }
                        </tbody>
                    </Table>
                    
                    {insertButton}
                </>);
        }

        // Sortable list
        return (
            <List values={tbody} 
                beforeDrag={(params) => {
                    this.selectedIndex = params["index"];
                }}
                onChange={({ oldIndex, newIndex }) => {
                        if(this.props.onReorder)
                            this.props.onReorder(this.state.items, oldIndex, newIndex);
                            
                        this.setState(prevState => ({
                            items: arrayMove(prevState.items, oldIndex, newIndex)
                        }));
                    }
                }
                renderList={({ children, props }) => (
                    <Table responsive borderless hover size="sm" className={"sortable " + c}>
                        <thead><tr>{this.thead}</tr></thead>
                        <tbody {...props}>{children}</tbody>
                    </Table>
                )}
                renderItem={({ value, props, isDragged }) => {
                    // "value" contains the JSX to render and the object item
                    const item = value.item;
                    value = value.jsx;

                    const row = (
                        <tr {...props} 
                            className={(this.props.onRowClassName ? this.props.onRowClassName(item) : "")}
                            onMouseUp={(e) => {
                                if(e.button === 0 && (Date.now() - this.tDragStart) < 200) {
                                    if (this.props.onRowClick)
                                        this.props.onRowClick(this.state.items[this.selectedIndex]);
                                }
                            }}
                            onMouseDown={(e) => { 
                                this.tDragStart = Date.now();
                            }}>

                            {value}
                        </tr>);

                        return isDragged ? (
                            <Table style={{ ...props.style, borderSpacing: 0 }}>
                                <tbody>{row}</tbody>
                            </Table>
                            ) : row;
                    }
                }
            />);
    }
}