import React from 'react';
import { Form, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AppContext from 'app/AppContext';
import {withModal} from 'framework/withModal';
import QrtServiceApi from 'api/QrtService';
import QuickModule from 'framework/QuickModule';
import RemoteSelect from '../../../framework/RemoteSelect';

let yup = require('yup');

AppContext.yupAddPhoneTest(yup);

class _PriceListItem extends React.Component {
    state = {
        servicesOptions: null,
        service_id: null,
        item: null
    };

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id",
            "ClassName": "d-none",
            },
            // {
            //     "Title": AppContext.r["store"],
            //     "Adapter": (item) => {
            //         return item.store.name;
            //     }
            // },
            {
                "Title": AppContext.r["title"],
                "Field": "name"
            },{
                "Title": AppContext.r["category"],
                "Adapter": (item) => {
                    return item.service.category.name;
                }
            },{
                "Title": AppContext.r["service"],
                "Adapter": (item) => {
                    return item.service.name;
                }
            },{
                "Title": AppContext.r["price"],
                "Field": "price",
            },{
                "Title": AppContext.r["discount"],
                "Field": "discount",
            }
        ];
    }

    get schema () {
        return yup.object({
            //name: yup.string().required(),
            //description: yup.string(),
            price: yup.number().required(),
            discount: yup.number(),
            service_id: yup.number(),
            store_id: yup.number(),
        });
    }

    get initialValues () {
        return {
            description: "",
            price: 0,
            discount: 0
        };
    }

    async componentDidMount() {
        // const response = await QrtServiceApi.MyProviders();

        // if(response && response.data && response.data.data.length > 0)
        //     this.provider = response.data.data[0];

        // const storesResponse = await QrtServiceApi.Stores(); // this.provider.id
        // this.stores = storesResponse.data.data;
        // this.storeOptions = RemoteSelect.dataAdapter(this.stores);

        const categoriesResponse = await QrtServiceApi.Categories();
        this.categories = categoriesResponse.data.data;
        this.categoryOptions = RemoteSelect.dataAdapter(this.categories);
        console.log(this.categoryOptions);

        //this._initialValues['store_id'] = this.stores[0].id;

        this.onChangeCategory(null, this.categories[0].id);
    }

    onChangeCategory = async (e, categoryId, setFieldValue) => {
        // const servicesResponse = await QrtServiceApi.Services(categoryId)

        // if(!servicesResponse || !servicesResponse.data || servicesResponse.data.data.length == 0)
        // {
        //     // No services have been found
        //     this.setState({
        //         servicesOptions: null,
        //         service_id: null
        //     });

        //     return;
        // }

        // console.log(servicesResponse);
        // const services = servicesResponse.data.data;

        // const servicesOptions = RemoteSelect.dataAdapter(services);
        const services = await this.fetchServices(categoryId);

        if(setFieldValue) {
            console.log("setFieldValue ", services);
            setFieldValue("service_id", services.length > 0 ? services[0].id : null);
        }

        // //const item = { ...this.state.item };
        // //item.service_id = services[0].id;
        // if(!this.state.item || !this.state.item.service_id)
        //     this._initialValues['service_id'] = services[0].id;

        // this.setState({
        //     servicesOptions: servicesOptions,
        //     service_id: services[0].id,
        //     //item: item
        // });
    }

    fetchServices = async (categoryId) => {
        const servicesResponse = await QrtServiceApi.Services(categoryId)
        console.log(servicesResponse, servicesResponse.data.data.length);

        if(!servicesResponse || !servicesResponse.data || servicesResponse.data.data.length === 0)
        {
            // No services have been found
            this.setState({
                servicesOptions: null,
                service_id: null
            });

            return servicesResponse.data.data;
        }

        const services = servicesResponse.data.data;

        const servicesOptions = RemoteSelect.dataAdapter(services);

        this.setState({
            servicesOptions: servicesOptions,
            service_id: services[0].id,
        });

        return services;
    }

    onEdit = async (item) => {
        item['category_id'] = item.service.category_id;

        const servicesResponse = await QrtServiceApi.Services(item['category_id']);
        const services = servicesResponse.data.data;
        const servicesOptions = RemoteSelect.dataAdapter(services);
        //console.log(item);

        this.setState({
            servicesOptions: servicesOptions,
            category_id: item['category_id'],
        });

        console.log(item['category_id'], servicesOptions)

        //this.onChangeCategory(null, item['category_id']);

        return item;
    }

    view = ({ handleSubmit, handleChange, handleReset, values, touched, errors, setFieldValue }) => (
        <>
            <Form.Row>
                <Col md="12">
                    <Form.Row>
                        {/*<Form.Group as={Col} md="6" xs="6">
                            <Form.Label>{AppContext.r["store"]}</Form.Label>
 
                            <RemoteSelect name="store_id" key={values.locale_id} value={values.store_id} onChange={handleChange}
                                        options={this.storeOptions} fieldToMap="name" />
                        </Form.Group> */}
                        
                        <Form.Group as={Col} md="6">
                            <Form.Label>{AppContext.r["title"]}*</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["title"]} name="name"
                                            value={values.name} onChange={handleChange}
                                            isValid={touched.name && !errors.name} isInvalid={!!errors.name} />
                        </Form.Group>
                    </Form.Row>
                        
                    <Form.Row>
                        <Form.Group as={Col} md="4" xs="6">
                            <Form.Label>{AppContext.r["category"]}</Form.Label>

                            <RemoteSelect name="category_id" key={this.state.category_id} value={this.state.category_id} onChange={handleChange}
                                            onChange2={(e, value) => this.onChangeCategory(e, value, setFieldValue)} options={this.categoryOptions} fieldToMap="name" />
                        </Form.Group>

                        {this.state.servicesOptions && 
                            <Form.Group as={Col} md="4" xs="6">
                                <Form.Label>{AppContext.r["service"]}</Form.Label>

                                <RemoteSelect name="service_id" key={values.service_id} value={values.service_id} onChange={handleChange}
                                                onChange2={(e, value) => setFieldValue("service_id", value)} options={this.categoryOptions}
                                                options={this.state.servicesOptions} fieldToMap="name" />
                            </Form.Group>}
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} xs="3" sm="2">
                            <Form.Label>{AppContext.r["price"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["price"]} name="price"
                                            value={values.price} onChange={handleChange}
                                            isValid={touched.price && !errors.price} isInvalid={!!errors.price} />
                        </Form.Group>

                        <Form.Group as={Col} xs="3" sm="2">
                            <Form.Label>{AppContext.r["discount"]}</Form.Label>
                            <Form.Control type="text" placeholder={AppContext.r["discount"]} name="discount"
                                            value={values.discount} onChange={handleChange}
                                            isValid={touched.discount && !errors.discount} isInvalid={!!errors.discount} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} xs="12">
                            <Form.Label>{AppContext.r["description"]}</Form.Label>
                            <ReactQuill placeholder={AppContext.r["description"]} name="description"
                                        value={values.description} 
                                        onChange={e => this.handleRichTextChange(e, setFieldValue)} />
                        </Form.Group>
                    </Form.Row>
                </Col>
            </Form.Row>

            {/* Shown only on editing */}
            {(values.id) &&
                <Form.Row>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>}
        </>);

    render() {
        return <QuickModule initialValues={this.initialValues}
            apiPath={'/providers/stores/'+ this.props.storeId + '/pricelistitems'}
            createApiPath={'providers/stores/'+ this.props.storeId + '/pricelistitems'}
            updateApiPath={'providers/stores/pricelistitems'}
            tableHead={this.tableHead} schema={this.schema} view={this.view}
            onEdit={this.onEdit}
            disableDelete />;
    }
}

export default withModal(_PriceListItem);