//import React from 'react';
import Settings from '../settings';

export default function loadLocale(resources) {
    resources['hi'] = "Salve";
    resources['view-user'] = "Visualizza utente";
    resources['user-profile'] = "Il tuo Profilo";
    resources['welcome-message'] = "Benvenuti al portale " + Settings['project-name'];
    resources['welcome-message-desc'] = "";
    resources['days'] = "Giorni";
    resources['hours'] = "Ore";
    resources['minutes'] = "Minuti";
    resources['seconds'] = "Secondi";
    resources["awesome"] = "Fantastico";
    resources["public"] = "Pubblico";
    resources["private"] = "Privato";
    resources["language"] = "Lingua";
    resources["my-account"] = "Il Mio Account";
    resources["my-account-desc"] = "";
    resources["insert"] = "Inserisci";
    resources["update"] = "Aggiorna";
    resources["edit"] = "Modifica";
    resources["remove"] = "Rimuovi";
    resources["hello"] = "Salve";
    resources["id"] = "Id";
    resources["title"] = "Titolo";
    resources["value"] = "Valore";
    resources["name"] = "Nome";
    resources["surname"] = "Cognome";
    resources["email"] = "e-Mail";
    resources["phone"] = "Telefono";
    resources["mobile-phone"] = "Mobile";
    resources["published"] = "Pubblico";
    resources["your-id-is"] = "Il tuo Id è ";
    resources["item-id-is"] = "L'ID di questa scheda è ";
    resources["password"] = "Password";
    resources["created-at"] = "Creato";
    resources["updated-at"] = "Aggiornato";
    resources["size"] = "Dimensione";
    resources["access-to-portal"] = "Accedi al Portale " + Settings["project-name"];
    resources["description"] = "Descrizione";
    resources["note"] = "Note";
    resources["quantity"] = "Quantità";
    resources["price"] = "Prezzo";
    resources["availability"] = "Disponibilità";
    resources["category"] = "Categoria";
    resources["date-time"] = "Data e Ora";
    resources["time"] = "Ora";
    resources["ip-address"] = "Indirizzo IP";
    resources["delete"] = "Elimina";
    resources["create"] = "Crea";
    resources["cancel"] = "Annulla";
    resources["yes"] = "Sì";
    resources["no"] = "No";
    resources["submit"] = "Invia";
    resources["no-items-in-the-list"] = "Non ci sono elementi da visualizzare.";
    resources["no-special-characters-only-comma"] = "Caratteri speciali non consentiti, solo spazi, trattini e virgole.";
    resources["from"] = "Da";
    resources["to"] = "A";
    resources["save"] = "Salva";
    resources["datetime"] = "Data & Ora";
    resources["timezone"] = "Fuso Orario";

    resources["home"] = "Home";
    resources["user"] = "Utente";
    resources["users"] = "Utenti";
    resources["role"] = "Ruolo";
    resources["roles"] = "Ruoli";
    resources["permission"] = "Permission";
    resources["permissions"] = "Permissions";
    resources["admin"] = "Admin";
    resources["assets"] = "Assets";
    resources["logs"] = "Logs";

    resources["store"] = "Centro";
    resources["stores"] = "Centri";
    resources["shop"] = "Negozio";
    resources["locale"] = "Ambiente";
    resources["shop-items"] = "Shop Items";
    resources["shop-item"] = "Shop Item";
    resources["enoteca-item"] = "Enoteca Item";
    resources["enoteca-items"] = "Enoteca Items";
        
    resources["saving"] = "Salvataggio...";
    resources["close"] = "Chiudi";
    resources["create-item-first"] = "Crea la scheda prima di trasferire l'immagine.";
    resources["confirm-delete"] = "Sei sicuro di volere eliminare questa scheda?";
    resources["confirm-delete-heading"] = "Elimina questa scheda";

    resources["locations"] = "Locali";
    resources["location"] = "Locale";
    resources["address"] = "Indirizzo";
    resources["post-code"] = "CAP";
    resources["city"] = "Città";
    resources["county"] = "Contea";
    resources["country"] = "Nazione";

    resources["assets"] = "Assets";
    resources["asset"] = "Asset";
    resources["version"] = "Versione";

    resources["asset-types"] = "Asset Types";
    resources["asset-type"] = "Asset Type";
    resources["type"] = "Tipo";
    resources["required"] = "Obbligatorio";

    resources["lara-cms"] = "Lara CMS v0.9.0";

    resources["week-days-short"] = ["Lun","Mar","Mer","Gio","Ven","Sab","Dom"];
    resources["enable-edit"] = "Abilita Modifiche";
    resources["disable-edit"] = "Disabilita Modifiche";
    resources["modules"] = "Moduli";
    resources["module"] = "Modulo";
    resources["too-short"] = "Troppo breve";
    resources["too-long"] = "Troppo lungo";
    resources["params"] = "Parametri";
}
