import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Field, FieldArray } from 'formik';

import {ContentRoute} from "_metronic/layout";

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import TriviaProApi from 'api/TriviaPro';
import Module from "app/modules/Module";
import ProSlider from 'framework/ProSlider';
import RemoteSelect from 'framework/RemoteSelect';
import ToggleButton from 'framework/ToggleButton';
import Package from './Package';

let yup = require('yup');

export default class Question extends Module {
    enableInsert = false;
    enableDelete = false;
    enableEdit = true;

    info = Question.Info;
    
    static Info = {
        nameResource: "questions",
        icon: "fas fa-utensils",
        path: "/questions",
        show: false
    };

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <Question match={props.match} location={props.location} history={props.history} />} />;
    }

    get apiPath() {
      return '/api/v1/questions';
    }

    get schema () {
        return yup.object({
            text: yup.string().required(),
            category_id: yup.number().required()
            // answers: yup.array().of(
            //     yup.object({
            //         text: yup.string().min(1)
            //     })
            // ).min(1),
        });
    }

    get initialValues() {
        return {
            text: "",
            duration_ms: 20000,
            image_duration_ms: 7000,
            fixed_score: 0,
            category_id: this.state.categoryOptions[1].id,
            package_id: this.package_id,
            can_edit: true,
            answers: [{text: "", is_correct: 1},{text: "", is_correct: 0},{text: "", is_correct: 0},{text: "", is_correct: 0}]
        };
    }

    async beforeComponentDidMount() {
        this.package_id = null;

        // It retrieves the selected package
        if(this.props.location.state && this.props.location.state.package_id) {
            this.package_id = this.props.location.state.package_id;
        } else if (this.state.item) {
            this.package_id = this.state.item.package_id;
        }

        this.packageOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/packages?onlyEditable=1", "id");

        if(!this.package_id && this.packageOptions && this.packageOptions.length > 0)
            this.package_id = this.packageOptions[Object.keys(this.packageOptions)[0]].id;

        // Get categories according to the selected package language
        if(this.package_id)
           await this.loadCategories(this.package_id);
    }

    loadCategories = async (packageId) => {
        const p = this.packageOptions[packageId];
        const categories = await TriviaProApi.StoreCategories(p.language_id);

        this.setState({
            categoryOptions: await TriviaProApi.StoreCategories(p.language_id)
        });
    }
    
    onChangePackage = async (e, value, values) => {
        await this.loadCategories(value);
    }

    onFetchedItem = async (item) => {
        if(item) {
            const p = this.packageOptions[item.package_id];
            // console.log(p, item);

            this.setState({
                categoryOptions: await TriviaProApi.StoreCategories(p.language_id)//await CoreApi.fetchAsync(AppContext.s["api-url"] + "/categories?languageId="+p.language_id, "id")
            });
        }
    }

    updateDataAdapter(values) {
        return this.dataAdapter(values);
    }

    insertDataAdapter(values) {
        console.log(values)
        return this.dataAdapter(values);
    }

    dataAdapter(values) {
        const r = {
            text: values.text,
            duration_ms: parseInt(values.duration_ms, 10),
            image_duration_ms: parseInt(values.image_duration_ms, 10),
            note: values.note,
            properties: values.properties,
            video_url: values.video_url,
            fixed_score: values.fixed_score,
            published: values.published,
            category_id: values.category_id,
            answers: values.answers,
        };

        if(this.enableEdit)
            r['package_id'] = values.package_id;

        console.log(r);
        return r;
    }

    onChangeFilters = (values) => {
    }

    filtersForm() {
        return "";
    }

    indexToLetter(index) {
        switch(index) {
            case 0: return 'A';
            case 1: return 'B';
            case 2: return 'C';
            case 3: return 'D';
            case 4: return 'E';
            default: return '';
        }
    }

    get mask () {
        this.formImageRatio = 4/3;

        this.enableDelete = this.state.item.can_edit;
        this.enableEdit = this.state.item.can_edit;

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => {
            return(
                <Form className="question-form" onSubmit={handleSubmit}>
                    <Form.Row>
                        <Col md="8">
                            <Form.Row>
                                <Form.Group as={Col} md="6">
                                    <Form.Label>{AppContext.r["package"]}</Form.Label>

                                    <RemoteSelect name="package_id" key={values.package_id} value={values.package_id}
                                                  onChange={handleChange} onChange2={(e, value) => { this.onChangePackage(e, value, values)}}
                                                  disabled={!this.state.item.can_edit}
                                                  nullOption={false} options={this.packageOptions} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} xs="10">
                                    <Form.Label>{AppContext.r["text"]}*</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["text"]} name="text" maxLength="150"
                                                disabled={!this.state.item.can_edit}
                                                value={values.text} onChange={handleChange}
                                                    isValid={touched.text && !errors.text} isInvalid={!!errors.text} />
                                </Form.Group>
{/* 
                                <Form.Group as={Col} xs="2">
                                    <Form.Label>{AppContext.r["published"]}</Form.Label>
                                    <ToggleButton className="published-button" toggled={values.published} on={AppContext.r["yes"]} off={AppContext.r["no"]} key={values.published}
                                                disabled={!this.state.item.can_edit}
                                                onToggle={
                                                    (value) => {
                                                        var item = {...values}
                                                        item.published = value;
                                                        this.setState({item: item});
                                                        this.onChange(item);
                                                    }} />
                                </Form.Group> */}
                            </Form.Row>

                            <Form.Row>
                                <h2>{AppContext.r["answers"]}</h2>
                            </Form.Row>

                            <Form.Row>
                                <FieldArray name="answers"
                                    render={arrayHelpers => (
                                        <div className="answers">
                                            {values.answers && values.answers.length > 0 && this.state.item && this.state.item.answers ? (
                                                values.answers.map((v, index) => (
                                                <div key={index}>
                                                    <div className={"letter " + this.indexToLetter(index)}>{this.indexToLetter(index)}</div>
                                                    <Field type="text"
                                                            name={`answers[${index}].text`}
                                                            disabled={!this.state.item.can_edit}
                                                            maxLength="100" />

                                                    <label className="checkmark-container">
                                                        <input type="checkbox"
                                                            name={`answers[${index}].is_correct`}
                                                            checked={this.state.item.answers[index].is_correct === 1}
                                                            disabled={!this.state.item.can_edit}
                                                            onChange={e => { //e.target.checked
                                                                console.log(values);

                                                                if(values.answers[index].text.length > 0) {
                                                                    for(var x in values.answers)
                                                                        values.answers[x].is_correct = 0;
                                                                    values.answers[index].is_correct = 1;
                                                                    this.setState({ item: values });
                                                                } else {
                                                                    alert("Questa risposta è vuota.")
                                                                }

                                                                setFieldValue(`answers[${index}].is_correct`, 1);
                                                            }} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            ))
                                            ) : ""}
                                            <div>
                                            </div>

                                            { typeof errors.answers === 'string' ? <div>{errors.answers}</div> : null }
                                        </div>
                                    )} />
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} md="4">
                                    <Form.Label>{AppContext.r["category"]}</Form.Label>

                                    <RemoteSelect name="category_id" key={values.category_id} value={values.category_id} onChange={handleChange}
                                                    disabled={!this.state.item.can_edit} nullOption={false}
                                                    fieldToMap="category" options={this.state.categoryOptions} />
                                </Form.Group>

                                <Form.Group as={Col} md="4">
                                    <Form.Label>{AppContext.r["duration"]}</Form.Label>
                                    <ProSlider value={values.duration_ms} adapterDividend={1000} minValue="5" maxValue="100" symbol="s"
                                                disabled={!this.state.item.can_edit}
                                                onChange={(value) => setFieldValue("duration_ms", value)} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} className="fixed-score-group">
                                    <Form.Label>{AppContext.r["fixed-score"]}</Form.Label><br />
                                    <Form.Label as={Col}>{AppContext.r["fixed-score-desc"]}</Form.Label><br />
                                    <ProSlider value={values.fixed_score} step={250} 
                                                minValue={0} maxValue={10000} symbol=""
                                                disabled={!this.state.item.can_edit}
                                                onChange={(value) => setFieldValue("fixed_score", value)} />
                                    
                                        {!values.fixed_score && 
                                            <p className="default">{AppContext.r["fixed-score-default"]}</p>}
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>{AppContext.r["note"]}</Form.Label>
                                    <Form.Control type="text" placeholder={AppContext.r["note"]} name="note"
                                                    disabled={!this.state.item.can_edit}
                                                    value={values.note} onChange={handleChange} />
                                </Form.Group>
                            </Form.Row>
                        </Col>

                        <Col md="4">
                            {this.formImage}

                            {values.image && 
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{AppContext.r["image-duration"]}</Form.Label>
                                        <ProSlider value={values.image_duration_ms} adapterDividend={1000} minValue="3" maxValue="30" symbol="s"
                                                    disabled={!this.state.item.can_edit}
                                                    onChange={(value) => setFieldValue("image_duration_ms", value)} />
                                    </Form.Group>
                                </Form.Row>
                            }

                            <Form.Row>
                                <Form.Label as={Col}>{AppContext.r["video-url"]}<br /><small>{AppContext.r["video-support"]}</small></Form.Label>
                                <Form.Control type="text" placeholder={AppContext.r["video-url"]} name="video_url"
                                                disabled={!this.state.item.can_edit || values.image}
                                                value={values.video_url} onChange={handleChange} />

                                {values.image && AppContext.r["video-url-disabled-desc"]}
                            </Form.Row>
                        </Col>
                    </Form.Row>

                    {/* Shown only on editing */}
                    <Form.Row style={this.displayOnEditStyle}>
                        <Form.Group as={Col}>
                            <p className="item-id-is-label">{AppContext.r["item-id-is"]} </p>
                            <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                            value={values.id} />
                        </Form.Group>
                    </Form.Row>

                    {this.formFooter}
                </Form>)
            };
    }

    onDeleted = () => {
        console.log("deleted", this.state);
        this.setState({
            redirectTo: Package.Info.path + "/" + this.state.item.package_id
        });
    }

    onClose = () => {
    //   AppContext.history.goBack();
        this.setState({
            redirectTo: Package.Info.path + "/" + this.state.item.package_id
        });
    }
}