import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import AppContext from 'app/AppContext';
import CoreApi from 'api/Core';
import JsonToTable from 'framework/JsonToTable';
import {withModal} from 'framework/withModal';

class QuickModule extends React.Component {
    state = {
        records: null
    }

    _apiPath = null;
    get apiPath() {
        if(!this._apiPath)
            throw new Error(AppContext.r["property-not-implemented"]);
         return this._apiPath;
    }

    _tableHead = null;
    get tableHead() {
        if(!this._tableHead)
            throw new Error(AppContext.r["property-not-implemented"]);
        return this._tableHead;
    }

    constructor(props) {
        super (props);

        this._apiPath = props.apiPath;
        this.updateApiPath = props.updateApiPath ? props.updateApiPath : this._apiPath;
        this.createApiPath = props.createApiPath ? props.createApiPath : this._apiPath;

        // this._view = props.view;
        this._schema = props.schema;
        this._tableHead = props.tableHead;
        this.tableHead.push({
            "Adapter": (o) => {
                const t = this;

                return (
                    <div className="record-actions">
                        {!this.props.disableDelete &&
                            <Button variant="link" className="danger responsive-icon-button"
                                onClick={() => t.onDelete(o)}>
                                <span>{AppContext.r["delete"]}</span> <DeleteIcon />
                            </Button> }

                        {!this.props.disableEdit &&
                            <Button variant="link" className="warning responsive-icon-button"
                                onClick={() => t.onEdit(o)}>
                                <span>{AppContext.r["edit"]}</span> <EditIcon />
                            </Button> }
                    </div>);
            }
        });

        this.initialValues = this.props.initialValues ? this.props.initialValues : {};
    }

    async componentDidMount() {
        await this.fetchRecords();
    }

    fetchRecords = async () => {
        console.log(AppContext.s["api-url"] + this.apiPath);
        this.setState({
            records: await CoreApi.fetchAsync(AppContext.s["api-url"] + this.apiPath)
        });
    }

    generateView = () => {
        const view = (
            <Formik
                enableReinitialize={true}
                initialValues={this.selectedItem}
                validationSchema={this._schema}
                onSubmit={async (values) => {
                    console.log(values);
                    if(values.id)
                        this.update(values);
                    else
                        this.insert(values);
                }}>

                {({ handleSubmit, handleReset, handleChange, values, touched, errors, setFieldValue }) => (
                    <Form className="form form-label-right"
                        onSubmit={handleSubmit} onReset={handleReset}>

                        {this.props.view({
                            handleSubmit: handleSubmit,
                            handleChange: handleChange,
                            setFieldValue: setFieldValue,
                            values: values,
                            touched: touched,
                            errors: errors
                        })}

                        <div className="modal-footer">
                            <Button variant="secondary" onClick={this.props.modal.hide}>
                                {AppContext.r["cancel"]}
                            </Button>
                            
                            <Button type="submit" variant="danger">
                                {AppContext.r["save"]}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        );

        return view;
    }
    
    onEdit = async (o) => {
        // console.log(o);
        this.selectedItem = o;

        if(this.props.onEdit) {
            const r = await this.props.onEdit(o);
            if(r) this.selectedItem = r;
        }

        this.props.modal.setView(AppContext.r["edit"], this.generateView(), true, "wide", null);
    }

    onInserted = async () => {
        this.props.modal.hide();
        await this.fetchRecords();
    }

    onInsert = () => {
        this.selectedItem = this.initialValues;

        this.props.modal.setView(AppContext.r["new"], this.generateView(), true, "wide", null);
    }

    insert = async (values) => {
        this.props.modal.setBusy();
        console.log("INSERT", values);

        const item = {
            ...values,
            registry_id: this.props.registryId
        }

        console.log(item);
        const response = await CoreApi.createItem(AppContext.s["api-url"] + "/" + this.createApiPath, item);
        console.log(response);
        this.props.modal.setBusy(false);

        if(response && response.status === 201)
            await this.onInserted();
        else
            this.props.modal.setMessage("error");
    }

    update = async (o) => {
        this.props.modal.setBusy();
        console.log("UPDATE", o);

        await CoreApi.updateItem(AppContext.s["api-url"] + "/" + this.updateApiPath + "/" + o.id, o);
        await this.fetchRecords();
        
        this.props.modal.hide();
    }

    delete = async (o) => {
        this.props.modal.setBusy();
        console.log("DELETE");

        await CoreApi.deleteItem(AppContext.s["api-url"] + this.apiPath + "/" + o.id);
        await this.fetchRecords();
        
        this.props.modal.hide();
    }

    onDelete = (o) => {
        console.log(o);
        this.props.modal.confirm(AppContext.r["confirm-delete-heading"], AppContext.r["confirm-delete"], () => this.delete(o))
    }

    render() {
        const { records } = this.state;

        return !records ? AppContext.r["preloader"] : (
            <JsonToTable head={this.tableHead} body={records} onInsert={this.props.disableInsert ? null : this.onInsert} />
        );
    }
}

export default withModal(QuickModule);