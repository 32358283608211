import React from 'react'
import { Col, Form } from 'react-bootstrap'
import { Field, FieldArray } from 'formik';
import Axios from 'axios';

import {ContentRoute} from "../../../_metronic/layout";

import AppContext from '../../AppContext';
import CoreApi from '../../../api/Core';
import Module, { ModuleViewModeEnum} from "../../modules/Module";
import RemoteSelect from '../../../framework/RemoteSelect';

import MenuProApi from '../../../api/MenuPro';

let yup = require('yup');

export default class ShopOrder extends Module {
    info = ShopOrder.Info;

    enableInsert = false;
    enableDelete = false;

    static Info = {
        nameResource: "orders",
        icon: "fas fa-sort-amount-down",
        path: "/orders",
        show: true
    };

    state = {
        ...this.state,
        totalAmount: 0
    }

    static get route() {
        return  <ContentRoute key={this.Info.path} path={this.Info.path + "/:id?"} 
                    component={(props) => <ShopOrder match={props.match} location={props.location} history={props.history} />} />;
    }
    
    get title () {
        return AppContext.r["order"];
    }
    
    onBeforeFetchRecords = async () => {
        const myShops = await MenuProApi.MyShops();
        console.log(myShops);
        if(myShops && myShops.data.data && myShops.data.data.length > 0) {
            this.setState({
                shop: myShops.data.data[0]
            });

            this.shopId = myShops.data.data[0].id;

            console.log(this._recordsApiPath);
        }
    }

    onFetchedItem = async (item) => {
        if(!this.state.shop)
            await this.onBeforeFetchRecords();

        if(item) {
            this.setState({
                totalAmount: this.getTotalAmount(item)
            })
        }
    }

    set shopId (value) { this._shopId = value; }
    get shopId () { 
        return this._shopId;
    }

    get apiPath() {
        return '/api/v1/shop/'+this.shopId+'/orders';
    }

    get recordsApiPath() {
        return '/api/v1/shop/'+this.shopId+'/orders';
    }

    get itemApiPath() {
        return '/api/v1/shop/orders';
    }

    get updateApiPath() {
        return '/api/v1/shop/orders';
    }

    get schema () {
        return yup.object({
            note: yup.string()
        });
    }

    get tableHead () {
        return [{
            "Title": AppContext.r["id"],
            "Field": "id"
          },{
            "Title": AppContext.r["type"],
            "Adapter": function(o) {
                return AppContext.r["order-type"][o.shop_order_type_id];
            }
          },{
            "Title": AppContext.r["name"],
            "Field": "name",
            "Adapter": function(o) {
                return (o.user === null) ? "" : o.user.name + " " + ((o.user.surname !== null) ? o.user.surname : "");
            }
          },{
            "Title": AppContext.r["placed-at"],
            "Field": "created_at"
          },{
            "Title": AppContext.r["requested-date"],
            "Field": "requested_date",
            "Adapter": function(o) {
                return (o.requested_date === null) ? "" : o.requested_date.substring(0,10);
            }
          },{
            "Title": AppContext.r["note"],
            "Field": "note"
          },{
            "Title": AppContext.r["status"],
            "Field": "status",
            "Adapter": function(o) {
                return (o.status === null) ? "" : o.status.title;
            }
          }
        ];
    }

    get initialValues() {
        return {
            created_at: "",
            requested_date: "",
            note: "",
            status: null,
        };
    }

    updateDataAdapter = (values) => {
        delete values["requested_date"];
        return values;
    }

    // static updateInterval = 3000; // ms
    // interval = null;

    async beforeComponentDidMount() {
        const response = await Axios.get(AppContext.s["api-url"] + "/shops")
            .catch((error) => {
                console.log('error ' + error);
            });

        if(response.data.data.length > 0)
            this.shopId = response.data.data[0].id;

        // Fetch statutes
        this.orderStatusOptions = await CoreApi.fetchAsync(AppContext.s["api-url"] + "/shoporders/statuses", "id");
        // Use internal resource dictionary to apply the right names
        for(var o of this.orderStatusOptions)
            if(o) {
                o.title = AppContext.r["order_types"][o.id];
            }

        //this.categoryOptions = await this.fetchAsync(AppContext.s["api-url"] + "/shop/"+this.shopId+"/categories", "id");

        // if(!this.interval)
        //     this.interval = setInterval(() => {
        //         if(window.location.pathname === "/orders" && this.state.item === null)
        //             this.fetchRecords();
        //     }, ShopOrder.updateInterval);
    }
    
    // componentWillUnmount() {
        // if(this.interval)
        //     clearInterval(this.interval);
        // this.interval = null;
    // }

    // onChangeFilters = (values) => {
    // }

    // filtersForm() {
    //     return "";
    // }

    extras(extras) {
        if(extras) {
            //console.log(extras);
            const m = extras.map(x => <span className="extra" key={x.id}>{x.name}</span>)
    
            return (
                <div className="extras">
                    { m }
                </div>);
        }
        
        return "";
    }

    formatAddress(c) {
        let a = c.address1;
        if(c.address2 && c.address2.length > 0) a += ", " + c.address2;
        a += " - " + c.post_code;
        a += " - " + c.city;
        return a;
    }

    getTotalAmount = (item = null) => {
        if(!item) item = this.state.item;
        let totalAmount = 0;

        for(let v of item.items)
            totalAmount += parseFloat(v.pivot.price);

        return totalAmount.toFixed(2);
    }

    get mask () {
        console.log(this.state.item);

        const u = this.state.item.user;
        const name = (<p>{u.name + " " + u.surname + " (User ID: " + u.id + ")"}</p>);
        const email = u.email.length > 0 ? (<span className="mail"><i className="far fa-envelope"></i> <a href={"mailto:"+u.email}>{u.email}</a></span>) : "";
        const mobile = (u.mobile_phone && u.mobile_phone.length > 0) ? (<span><i className="fas fa-phone"></i> {u.mobile_phone}</span>) : "";
        //const note = this.state.item && this.state.item.note && this.state.item.note.length > 0 ? (<p className="note">{this.state.item.note}</p>) : "";
        const {note} = this.state.item;
        const orderType = this.state.item ? AppContext.r["order-type"][this.state.item.type.id] : "";

        const { contact } = this.state.item;
        
        const contactView = (!contact) ? "" : (
            <div className="order-contact">
                {/* <h3>{contact.alias}</h3>
                {this.formatAddress(contact)}
                <hr /> */}
            </div>
        );

        return ({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className="order" autoComplete="off">
                <Form.Row>
                    <Form.Group as={Col} md="6">
                        <Form.Label>{AppContext.r["status"]}</Form.Label>

                        <RemoteSelect name="shop_order_status_id" key={values.shop_order_status_id} value={values.shop_order_status_id} onChange={handleChange}
                                        nullOption={false} options={this.orderStatusOptions} fieldToMap="title" />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col} xs="6">
                        <Form.Label>{AppContext.r["placed-at"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["placed-at"]} name="created_at" disabled
                                    value={values.created_at} onChange={handleChange} />
                    </Form.Group>

                    <Form.Group as={Col} xs="6">
                        <Form.Label>{AppContext.r["requested-date"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["requested-date"]} name="requested_date" disabled
                                    value={values.requested_date} onChange={handleChange} /> {/*.substring(0,16)*/}
                    </Form.Group>
                </Form.Row>
{/* 
                <Form.Row>
                    <Form.Group as={Col} xs="12">
                        <Form.Label>{AppContext.r["note"]}</Form.Label>
                        <Form.Control type="text" placeholder={AppContext.r["note"]} name="note" disabled
                                      value={values.note} onChange={handleChange} />
                    </Form.Group>
                </Form.Row> */}
                
                <Form.Row>
                    <Form.Group as={Col} xs="12" className="order-user">
                        <h2>{AppContext.r["user"]}</h2>
                        {name}
                        {email}
                        {mobile}
                    </Form.Group>

                    <Form.Group as={Col} xs="12" className="order-contact">
                        {contactView}
                    </Form.Group>
                </Form.Row>
                
                <Form.Row>
                    <h2>{AppContext.r["order-details"]}</h2>

                    <h3 className="type">{orderType}</h3>

                    {/* * Items * */}

                    <Form.Group as={Col} xs="12">
                        <FieldArray name="items"
                            render={arrayHelpers => (
                                <div className="items">
                                    {/* {console.log(values)} */}
                                    {values.items && values.items.length > 0 ? (
                                        values.items.map((v, index) => (
                                            <div key={index} className="list-item">
                                                <span className="quantity">{values.items[index].pivot.quantity}</span>
                                                <span className="x"> x </span>
                                                <span className="name">{values.items[index].name}</span>

                                                {this.extras(values.items[index].pivot.extra_objects)}

                                                <div className="price">
                                                    <label htmlFor={`items.${index}.pivot.price`}>{AppContext.r['price']}</label>
                                                    
                                                    <Field
                                                        name={`items.${index}.pivot.price`}
                                                        placeholder="0" readonly /* REMOVE readonly for edit */
                                                        type="text" disabled
                                                        onChange={e => {
                                                            e.preventDefault();

                                                            // Uncomment to restore item price edit

                                                            // const { value } = e.target;
                                                            // const regex = /^(0|0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                                            // if (regex.test(value.toString())) {
                                                            //     setFieldValue(`items.${index}.pivot.price`, value);

                                                            //     v.pivot.price = value;

                                                            //     this.setState({
                                                            //         totalAmount: this.getTotalAmount(values)
                                                            //     });
                                                            // }
                                                        }} />
                                                    {/* {errors.items && errors.items[index] && errors.items[index].pivot.price && touched.items && touched.items[index].pivot.price && (
                                                        <div className="field-error">
                                                            {errors.items[index].pivot.price}
                                                        </div>
                                                    )} */}
                                                </div>

                                                <span className="note">{values.items[index].pivot.note}</span>

                                                <hr />
                                            </div>
                                        ))
                                    ) : ("none") }
                                    <div>
                                    </div>

                                    { typeof errors.items === 'string' ? <div>{errors.items}</div> : null }
                                </div>
                            )}
                        />

                        {this.state.shop && 
                            <p className="total">{this.state.shop.locale.currency_symbol + " " + this.state.totalAmount}</p> }
                    </Form.Group>
                    
                    {(note && note.length > 0) && 
                        <>
                            <h4>Customer Notes</h4>
                            <p className="note">{note}</p>
                        </> }
                </Form.Row>

                {/* Shown only on editing */}
                <Form.Row style={this.displayOnEditStyle}>
                    <Form.Group as={Col}>
                        <p className="item-id-is-label">{AppContext.r["order-id-is"]} </p>
                        <Form.Control type="text" placeholder={AppContext.r["id"]} name="id" disabled className="item-id-is-control"
                                        value={values.id} />
                    </Form.Group>
                </Form.Row>

                {this.formFooter}  
            </Form>
        );
    }
}
