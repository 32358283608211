import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from "react-redux";

import AppContext from 'app/AppContext';
import MenuParty from 'api/MenuPro';
// import ShopOrderWidget from './ShopOrderWidget';
import QuizWidget from './QuizWidget.js';
import StoreWidget from './StoreWidget';
import MenuPartyUserAccessesWidget from './MenuPartyUserAccessesWidget';

const mapStateToProps = state => ({
    auth: state.auth
});

class Dashboard extends React.Component {
    state = {
        restaurant: null,
    };

    async componentDidMount() {
        console.log(this.props);
        const response = await MenuParty.MyShops();

        if(response && response.data.data && response.data.data.length > 0) {
            // console.log(response);
            AppContext.s['shop-id'] = response.data.data.id;

            this.setState({
                restaurant: response.data.data[0]
            });
        }

        // const mapDispatchToProps = () => {
        //     return {
        //         increment, decrement
        //     };
        // };
    }

    onWidgetStatusChanged(status) {
        //console.log(status);
    }

    render() {
        const { restaurant } = this.state;
        const { user } = this.props.auth;

        if(!restaurant || !user)
            return AppContext.r["preloader"];

        const widgets = user.roles[0].id === 8 ? [] : [
            {
                "name": "YourShop",
                "status": { "collapsed": false },
                "xl": 6
            },
            {
                "name": "UserAccesses",
                "status": { "collapsed": false },
                "xl": 6
            },
        ];

        if(user.roles[0].id === 8)
            widgets.push({
                "name": "Quiz",
                "status": { "collapsed": false },
                "xl": 12
            });

        if(user.roles[0].id !== 2 && user.roles[0].id <= 4)
            widgets.push({
                "name": "ShopOrders",
                "status": { "collapsed": false },
                "xl": 6
            });

        let i = 0;
        const map = widgets.map(w => (
            <Col xl={w.xl} sm={12} key={i++}>
                {w.name === "YourShop" && 
                    <StoreWidget className="your-restaurant" restaurant={restaurant} collapsed={w.status.collapsed}
                        onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
                {w.name === "UserAccesses" && 
                    <MenuPartyUserAccessesWidget className="graph" restaurant={restaurant} collapsed={w.status.collapsed}
                        onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
                {w.name === "Quiz" && 
                    <QuizWidget className="quiz" collapsed={w.status.collapsed}
                        onWidgetStatusChanged={this.onWidgetStatusChanged}/>}
            </Col>)
        );
        
        return (
            <div className="home">
                <Row>
                    {map}
                </Row>
            </div>);
    }
}

export default connect(mapStateToProps, null)(Dashboard);