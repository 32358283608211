import React from 'react';
import { Redirect } from 'react-router';

export function asWidget(OriginalComponent) {
    return class extends React.Component {
        state = {
            overlayMessage: null,
            redirectTo: null,
            collapsed: (this.props.collapsed) ? this.props.collapsed : false
        }

        collapse = () => {

        }
        
        setOverlayMessage = (message) => {
            this.setState({
                overlayMessage: message
            });
        }

        overlay = () => {
            return this.state.overlayMessage && (
                <div className='widget-overlay'>
                    <p className='message'>
                        {this.state.overlayMessage}
                    </p>
                </div>);
        }
            
        imageExists(image_url){
            var http = new XMLHttpRequest();
        
            http.open('GET', image_url, false);
            http.send();
        
            return http.status === 200;
        }

        toggleWidgetSize = () => {
            const collapsed = !this.state.collapsed;

            this.setState({
                collapsed: collapsed
            });

            if(this.props.onWidgetStatusChanged)
                this.props.onWidgetStatusChanged({
                    collapsed: collapsed
                })
        }

        setRedirectTo = (path, data) => {
            this.setState({
                redirectTo: path
            });
        }

        render() {
            if (this.state.redirectTo) {
                return <Redirect push to={this.state.redirectTo} />;
            }

            const newProps = {
                setOverlayMessage: this.setOverlayMessage,
                setRedirectTo: this.setRedirectTo,
            }

            const c = "widget " 
                + (this.state.collapsed ? " collapsed " : "") 
                + (this.props.className ? this.props.className : "");

            // Return original component with additional props
            return (
                <div className={c}>
                    <OriginalComponent {...this.props} {...newProps} />
                    
                    <div className="sizer" onClick={this.toggleWidgetSize}>
                        {this.state.collapsed ? 
                            <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}
                    </div>
                    
                    {this.overlay()}
                </div>);
        }
    }
}