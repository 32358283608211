import Axios from 'axios';

import AppContext from '../app/AppContext';

export default class Core {
    static errorLog(error) {
        if (error.response) {
            // Request made and server responded
            console.log(error.response);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
    }
    
    static async fetchAsync (url, fieldToMap) {
        const res = await Axios.get(url);
        const data = (res.data.data !== undefined) ? res.data.data : res.data;

        if(fieldToMap !== undefined && fieldToMap.length > 0) {
            const mappedData = [];
            for (const x of data) {
                mappedData[x[fieldToMap]] = x;
            }
            return mappedData;
        }

        console.log(url, res);
        return data;
    };
    
    static async fetchItem(url, callback, cancelToken = null) {
        console.log("Fetch " + url)
        const config = {
            // cancelToken: this.CancelTokenSource.token,
            //headers: { Authorization: AppContext.Auth.bearer }
        };

        if(cancelToken)
            config.cancelToken = cancelToken;

        Axios.get(url, config)
            .then(response => {
                if(callback)
                    callback(response.data.data);
            })
            .catch((error) => {
                if (Axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log('error ' + error);
                }
            });
    }

    static async createItem(url, values) {
        console.log("POST", values);

        return await Axios.post(url, values)
                    .catch(function (error) {
                        console.log(error);
                        if (error.response) {
                            console.log(error.response.status);
                            return error.response;
                        }
                    });
    }

    static async updateItem(url, values) {
        console.log("UPDATE", values);

        return await Axios.put(url, values)
                    .catch(function (error) {
                        console.log(error);
                        if (error.response) {
                            console.log(error.response.status);
                            return error.response;
                        }
                    });
    }

    static async deleteItem(url) {
        console.log("DELETE", url);

        await Axios.delete(url)
                  .catch(function (error) {
                      if (error.response) {
                          console.log(error.response.status);
                          return error.response;
                      }
                  });
    }

    static async uploadFile(url, formData) {
        const config = {
            headers: {
            // Authorization: Auth.bearer,
            'Accept': '*/*',
            //'Content-Length': file.size,
            'Content-Type': "multipart/form-data"
            }
        };
            
        return await Axios.post(url, formData, config)
            .catch((error) => {
                console.log(error);
                console.log(error.response);

                return error.response;
            });
    }
    
    static async Permissions() {
        return await Axios.get(AppContext.s["api-url"] + "/permissions")
            .catch((error) => {
                console.log('error ' + error);
                this.errorlog(error);
            });
    }
}